import { FC } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
} from "@mui/material";
import { useStyles } from "./ResetPasswordDialog.styles";
import { useSnackbar } from "notistack";
import { initialInputData } from "./ResetPasswordDialog.inputs";
import { useForm, validateForm } from "../../../../utils";
import {
  IUpdatePersonPasswordData,
  IUpdatePersonPasswordVars,
  UPDATE_PERSON_PASSWORD,
} from "../../../../apollo/mutations";
import { useMutation } from "@apollo/client";
import { LoadingBackdrop } from "../../..";
import { useGlobalStyles } from "../../../../styles";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
};

type TProps = DialogProps & TDialogProps;

export const ResetPasswordDialog: FC<TProps> = (props) => {
  const { onClose, ...rest } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, resetFields } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [updatePersonMutation, { loading }] = useMutation<
    IUpdatePersonPasswordData,
    IUpdatePersonPasswordVars
  >(UPDATE_PERSON_PASSWORD, {
    onCompleted: () => {
      enqueueSnackbar("Password changed!", {
        variant: "success",
      });
      handleClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleUpdatePassword = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm<keyof typeof initialInputData>(
      fields,
      inputFields
    );
    if (result.formValid) {
      if (
        inputFields.newPassword.values.value ===
        inputFields.repeatNewPassword.values.value
      ) {
        updatePersonMutation({
          variables: {
            data: {
              password: inputFields.currentPassword.values.value,
              passwordReset: inputFields.newPassword.values.value,
            },
          },
        });
      } else {
        enqueueSnackbar("Password does not match!", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  return (
    <Dialog onClose={handleClose} {...rest}>
      <DialogTitle>Change password</DialogTitle>

      <DialogContent className={classes.content}>
        <TextField
          {...inputFields.currentPassword.inputProps}
          InputProps={{
            classes: {
              input: globalClasses.passwordField,
            },
          }}
          fullWidth
          autoFocus
          margin="normal"
        />
        <TextField
          {...inputFields.newPassword.inputProps}
          className={globalClasses.flex45}
          // InputProps={{
          //   classes: {
          //     input: globalClasses.passwordField,
          //   },
          // }}
          autoComplete="new-password"
          margin="normal"
        />
        <TextField
          {...inputFields.repeatNewPassword.inputProps}
          className={globalClasses.flex45}
          InputProps={{
            classes: {
              input: globalClasses.passwordField,
            },
          }}
          margin="normal"
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePassword}
        >
          Update
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loading} />
    </Dialog>
  );
};
