import { gql } from "@apollo/client";
import { ICountry } from "../../../queries";

export interface IUpdateAgency {
  id: string;
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: ICountry;
}

export interface IUpdateAgencyData {
  updateAgency: IUpdateAgency;
}

export interface IUpdateAgencyVars {
  id: number;
  data: {
    name: string | undefined;
    street: string | undefined;
    zipCode: string | undefined;
    city: string | undefined;
    countryId: number | undefined;
  };
}
export const UPDATE_AGENCY = gql`
  mutation updateAgency($id: ID!, $data: AgencyUpdateInput!) {
    updateAgency(id: $id, data: $data) {
      id
      name
      street
      zipCode
      city
      country {
        id
        name
      }
    }
  }
`;
