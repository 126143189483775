import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  email: {
    values: { value: "" },
    rule: "email",
    inputProps: {
      label: "Email",
      required: true,
      type: "email",
    },
  },
});
