import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(4),
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: "#999",
  },
}));
