import { createTypedDynamicFormInputs } from "../../../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  nickname: {
    values: { value: "" },
    inputProps: {
      label: "Nickname",
      required: true,
    },
  },
  role: {
    values: { value: "" },
    inputProps: {
      label: "Role",
      required: true,
    },
  },
  agency: {
    values: { value: "" },
    inputProps: {
      label: "Agency",
    },
  },
  email: {
    values: { value: "" },
    inputProps: {
      label: "Email",
      required: true,
    },
    rule: "email",
  },
});
