import { FC, KeyboardEvent, useLayoutEffect, useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./JoinScreen.styles";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  LockOutlined as LockOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
} from "@mui/icons-material";
import { useForm, validateForm } from "../../../utils";

import { initialInputData } from "./JoinScreen.inputs";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CHECK_PERSON_INVITE,
  CREATE_PERSON,
  ICheckPersonInviteData,
  ICheckPersonInviteVars,
  ICreatePersonData,
  ICreatePersonVars,
} from "../../../apollo/mutations";
import { ErrorComponent, LoadingBackdrop } from "../../../components";

export const JoinScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const [searchParams] = useSearchParams();
  const [tokenError, setTokenError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const token = searchParams.get("token");

    if (!token) {
      setTokenError(true);
    }
  }, [searchParams]);

  const { inputFields, setInputFields } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleContinue();
    }
  };

  const [checkPersonInviteMutation, { loading, data }] = useMutation<
    ICheckPersonInviteData,
    ICheckPersonInviteVars
  >(CHECK_PERSON_INVITE, {
    onCompleted: (data) => {
      if (data.checkPersonInvite === false) {
        enqueueSnackbar(
          "No accounts with that email are found. You need to fill your information in order to complete the registration and accept the invitation",
          {
            variant: "info",
          }
        );
        return;
      }
      enqueueSnackbar(
        "You accepted the invitation. Click continue to proceeed to login form.",
        {
          variant: "success",
        }
      );
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [createPersonMutation, { loading: loadingCreatePerson }] = useMutation<
    ICreatePersonData,
    ICreatePersonVars
  >(CREATE_PERSON, {
    onCompleted: (data) => {
      enqueueSnackbar("Your account has been created!", {
        variant: "success",
      });
      handleNavigateToLogin();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleContinue = () => {
    const token = searchParams.get("token");
    if (data?.checkPersonInvite === false) {
      const fields = Object.keys(initialInputData);
      const result = validateForm(fields, inputFields);
      if (result.formValid && token) {
        createPersonMutation({
          variables: {
            data: {
              token: token,
              password: inputFields.password.inputProps.value,
              name: inputFields.name.inputProps.value,
            },
          },
        });
      } else {
        enqueueSnackbar("Not all required fields are set!", {
          variant: "error",
        });
        setInputFields(result.outputData);
      }
      return;
    }
    if (token) {
      checkPersonInviteMutation({
        variables: {
          token: token,
        },
      });
    } else {
      enqueueSnackbar("Token is missing", {
        variant: "error",
      });
    }
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  if (tokenError) {
    return <ErrorComponent />;
  }

  console.log(data);

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        {data?.checkPersonInvite === true ? (
          <>
            <Typography variant="h1" paragraph>
              Welcome
            </Typography>
            <Typography marginBottom={2}>
              You have accepted the invitation. Press continue to proceed to
              login page.
            </Typography>
            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleNavigateToLogin}
            >
              Continue
            </Button>
          </>
        ) : data?.checkPersonInvite === false ? (
          <>
            <Typography variant="h1" paragraph>
              Registration
            </Typography>
            <Typography marginBottom={2}>
              Please enter your name and password to complete the registration
            </Typography>

            <TextField
              {...inputFields.name.inputProps}
              autoFocus
              margin="normal"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              {...inputFields.password.inputProps}
              margin="normal"
              autoComplete="password"
              onKeyDown={handleEnterKey}
              fullWidth
              type={showPassword ? "text" : "password"}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleContinue}
            >
              Register
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h1" paragraph>
              Welcome
            </Typography>
            <Typography marginBottom={2}>
              You have been invited to join River! Press continue to proceed
              with registration.
            </Typography>

            <Button
              className={cx(classes.button)}
              variant="contained"
              fullWidth
              onClick={handleContinue}
            >
              Continue
            </Button>
          </>
        )}
      </Paper>
      <LoadingBackdrop loading={loading || loadingCreatePerson} />
    </main>
  );
};
