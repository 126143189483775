import { gql } from "@apollo/client";

export interface IDeleteRankGroupVars {
  id: number;
}

export interface IDeleteRankGroup {
  id: string;
  name: string;
}

export interface IDeleteRankGroupData {
  deleteRankGroup: IDeleteRankGroup;
}

export const DELETE_RANK_GROUP = gql`
  mutation deleteRankGroup($id: ID!) {
    deleteRankGroup(id: $id) {
      id
      name
    }
  }
`;
