import { gql } from "@apollo/client";

export interface IInviteAgentVars {
  data: {
    nickname: string;
    email: string;
    roleId: number;
    agencyId: number | undefined;
  };
}

export interface IInviteAgent {
  id: string;
  nickname: string;
  deactivated: boolean;
  person: {
    id: string;
    email: string;
  };
  agency: {
    id: string;
    name: string;
  };
  role: {
    id: string;
    name: string;
  };
  created: string;
  expired: string;
}

export interface IInviteAgentData {
  createAgent: IInviteAgent;
}

export const INVITE_AGENT = gql`
  mutation inviteAgent($data: PersonInvitationCreateInput!) {
    inviteAgent(data: $data) {
      id
      email
      nickname
      created
      expired
      revoked

      agency {
        id
        name
      }

      role {
        id
        name
      }
    }
  }
`;
