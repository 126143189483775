import { gql } from "@apollo/client";
import { IRankGroup } from "../../queries";

export interface ICreateRankVars {
  data: {
    name: string;
    abbreviation: string;
    daysOnBoard: number;
    isWatchkeeper: boolean;
    isPromotable: boolean;
    isOfficer: boolean;

    rankGroupId: number;
  };
}

export interface ICreateRank {
  id: string;

  name: string;
  abbreviation: string;
  daysOnBoard: number;
  isWatchkeeper: boolean;
  isPromotable: boolean;
  isOfficer: boolean;

  rankGroup: IRankGroup;
}

export interface ICreateRankData {
  createRank: ICreateRank;
}

export const CREATE_RANK = gql`
  mutation createRank($data: RankCreateInput!) {
    createRank(data: $data) {
      id

      name
      abbreviation
      daysOnBoard
      isWatchkeeper
      isPromotable
      isOfficer

      rankGroup {
        id
        name
      }
    }
  }
`;
