import { gql } from "@apollo/client";
import { IRankGroup } from "../../queries";

export interface IUpdateRank {
  id: string;

  name: string;
  abbreviation: string;
  daysOnBoard: number;
  isWatchkeeper: boolean;
  isPromotable: boolean;
  isOfficer: boolean;

  rankGroup: IRankGroup;
}

export interface IUpdateRankData {
  updateRank: IUpdateRank;
}

export interface IUpdateRankVars {
  data: {
    name: string | undefined;
    abbreviation: string | undefined;
    daysOnBoard: number | undefined;
    isWatchkeeper: boolean | undefined;
    isPromotable: boolean | undefined;
    isOfficer: boolean | undefined;
    rankGroupId: number | undefined;
  };
  id: number;
}

export const UPDATE_RANK = gql`
  mutation updateRank($id: ID!, $data: RankUpdateInput!) {
    updateRank(id: $id, data: $data) {
      id

      name
      abbreviation
      daysOnBoard
      isWatchkeeper
      isPromotable
      isOfficer

      rankGroup {
        id
        name
      }
    }
  }
`;
