import { createTypedDynamicFormInputs } from "../../../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    inputProps: {
      label: "Name",
      required: true,
    },
  },
  abbreviation: {
    inputProps: {
      label: "Abbreviation",
      required: true,
    },
  },
  rankGroup: {
    inputProps: {
      label: "Rank Group",
      required: true,
    },
  },
  daysOnBoard: {
    inputProps: {
      label: "Days on baard",
      required: true,
      type: "number",
    },
  },
  isOfficer: {
    isCheckbox: true,
    inputProps: {
      label: "Officer",
    },
  },
  isPromotable: {
    isCheckbox: true,
    inputProps: {
      label: "Promotable",
    },
  },
  isWatchkeeper: {
    isCheckbox: true,
    inputProps: {
      label: "Watchkeeper",
    },
  },
});
