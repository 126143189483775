import { gql } from "@apollo/client";

export interface IFilter {
  id: string;

  agentIds: string[];
  nationalityIds: string[];
  rankIds: string[];
  name: string;
  forAgents: { id: string; nickname: string }[];
}

export interface IFiltersData {
  allFilters?: IFilter[];
}

export interface IFiltersVars {
  id: number;
}

export const ALL_FILTERS = gql`
  query allFilters {
    allFilters {
      id

      agentIds
      nationalityIds
      rankIds
      name

      forAgents {
        id
        nickname
      }
    }
  }
`;

//_______________________________ ONE FILTER _______________________________//

export interface IFilterData {
  oneFilter?: IFilter;
}

export const ONE_FILTER = gql`
  query oneFilter($id: ID!) {
    oneFilter(id: $id) {
      id

      name
      agentIds
      nationalityIds
      rankIds

      forAgents {
        id
        nickname
      }
    }
  }
`;
