import { useState, FC, ChangeEvent, KeyboardEvent } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  TextFieldProps,
  FilledTextFieldProps,
} from "@mui/material";
import {
  SearchOutlined as SearchOutlinedIcon,
  CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import { useStyles } from "./SearchBar.styles";

type TSearchBarProps = {
  onSearch: (value: string) => void;
  activeSearch: string;
};

type TProps = TSearchBarProps & TextFieldProps;

export const SearchBar: FC<TProps> = (props) => {
  const { onSearch, activeSearch, className, ...rest } = props;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { classes, cx } = useStyles();

  const handleSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.currentTarget.value);
  };

  const handleSearchQueryKey = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch(searchQuery);
    }
  };
  const handleSearchQueryPress = () => {
    onSearch(searchQuery);
  };

  const handleResetSearchQuery = () => {
    setSearchQuery("");
    onSearch("");
  };

  const inputProps: FilledTextFieldProps["InputProps"] = {
    startAdornment: (
      <InputAdornment position="start">
        <IconButton
          className={cx(classes.iconButton)}
          size="small"
          onClick={handleSearchQueryPress}
          title="Pretraži"
        >
          <SearchOutlinedIcon />
        </IconButton>
      </InputAdornment>
    ),
    endAdornment:
      searchQuery || activeSearch ? (
        <InputAdornment position="end">
          <IconButton
            className={cx(classes.iconButton)}
            size="small"
            onClick={handleResetSearchQuery}
            title="Obriši tražilicu"
          >
            <CancelOutlinedIcon />
          </IconButton>
        </InputAdornment>
      ) : null,

    classes: {
      root: cx(classes.root),
      input: cx(classes.input),
      focused: cx(classes.focused),
      // notchedOutline: classes.notchedOutline,
    },
  };

  // const inputProps2: FilledTextFieldProps["inputProps"] = {
  //   classes: {
  //     root
  //     // root: cx(classes.root),
  //     // input: cx(classes.input),
  //     // focused: cx(classes.focused),
  //     // notchedOutline: classes.notchedOutline,
  //   },
  // };

  return (
    <TextField
      value={searchQuery}
      label="Search seafarer"
      variant="filled"
      fullWidth
      onChange={handleSearchQuery}
      onKeyDown={handleSearchQueryKey}
      size="small"
      className={cx(classes.searchBar, className)}
      InputProps={inputProps}
      InputLabelProps={{
        classes: {
          root: classes.inputRoot,
          focused: classes.inputFocused,
        },
      }}
      // inputProps={inputProps2}
      {...rest}
    />
  );
};
