import { FC, useEffect, useState } from "react";
import {
  DrawerProps,
  Drawer,
  List,
  ListSubheader,
  ListItem,
  IconButton,
  ListItemText,
  ListItemButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useStyles } from "./FilterDrawer.styles";
import { useSnackbar } from "notistack";
import { Reference, useLazyQuery, useMutation } from "@apollo/client";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
  ALL_FILTERS,
  IFilter,
  IFiltersData,
} from "../../../../../../apollo/queries";
import {
  DataHandlerComponent,
  DeleteDialog,
  LoadingBackdrop,
} from "../../../../../";
import {
  DELETE_FILTER,
  IDeleteFilterData,
  IDeleteFilterVars,
} from "../../../../../../apollo/mutations";

type TDrawerProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  handleSelectFilter: (item: IFilter) => void;
  handleOpenFilterDialog: (_: any, id?: string) => void;
};

type TProps = DrawerProps & TDrawerProps;

interface IActionMenu {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export const FilterDrawer: FC<TProps> = (props) => {
  const { handleSelectFilter, onClose, handleOpenFilterDialog, open, ...rest } =
    props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const [actionMenu, setActionMenu] = useState<IActionMenu>({
    id: undefined,
    open: false,
    anchorEl: null,
  });

  const handleOpenEditFilterDialog = () => {
    // console.log("IDD:", actionMenu);
    handleOpenFilterDialog(undefined, actionMenu.id);
  };

  const handleToggleActionMenu =
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setActionMenu((prev) => {
        return {
          open: prev.id !== id || !prev.open,
          anchorEl: event.currentTarget,
          id: id,
        };
      });
    };

  const handleCloseActionMenu = (event: Event | React.SyntheticEvent) => {
    setActionMenu((prev) => {
      return {
        ...prev,
        open: false,
        id: undefined,
      };
    });
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    } else if (event.key === "Escape") {
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    }
  }

  const [callFiltersQuery, { data, error, loading }] =
    useLazyQuery<IFiltersData>(ALL_FILTERS);

  useEffect(() => {
    if (open) {
      callFiltersQuery();
    }
  }, [open, callFiltersQuery]);

  const [deleteFilterMutation, { loading: loadingDeleteFilter }] = useMutation<
    IDeleteFilterData,
    IDeleteFilterVars
  >(DELETE_FILTER, {
    variables: {
      id: actionMenu?.id ? +actionMenu.id : 0,
    },
    onCompleted: (res) => {
      enqueueSnackbar(`Filter deleted: - ${res.deleteFilter.name}`, {
        variant: "success",
      });
      handleCloseDeleteDialog();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allFilters(existingFilters: Array<Reference>, { readField }) {
            if (data) {
              return existingFilters.filter(
                (taskRef) => data.deleteFilter.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  return (
    <Drawer
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={onClose}
      {...rest}
    >
      <List>
        <ListSubheader>Saved filters</ListSubheader>
        {data?.allFilters?.length
          ? data.allFilters.map((item) => {
              const handleSetFilter = () => {
                handleSelectFilter(item);
              };

              return (
                <ListItem
                  disablePadding
                  key={item.id}
                  secondaryAction={
                    <IconButton onClick={handleToggleActionMenu(item.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <ListItemButton onClick={handleSetFilter}>
                    <ListItemText
                      primary={item.name}
                      secondary={
                        item.forAgents.length
                          ? item.forAgents.length === 1
                            ? `1 agent`
                            : `${item.forAgents.length} agents`
                          : "Agency level"
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          : null}
      </List>
      <DataHandlerComponent
        loading={loading}
        error={Boolean(error)}
        hasData={Boolean(data?.allFilters?.length)}
        noDataText="No filters found"
      />

      <Popper
        open={actionMenu.open}
        className={classes.popper}
        anchorEl={actionMenu.anchorEl}
        role={undefined}
        placement="right-end"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "right bottom" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseActionMenu}>
                <MenuList
                  autoFocusItem={actionMenu.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleOpenEditFilterDialog}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleOpenDeleteDialog}>
                    <ListItemIcon>
                      <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        description="Are you sure you want to delete this filter? It will be deleted for other administrators too!"
        title="Delete filter"
        mutationCall={deleteFilterMutation}
      />
      <LoadingBackdrop loading={loadingDeleteFilter} />
    </Drawer>
  );
};
