import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  tableRow: {
    "& > th": {
      fontWeight: 600,
      border: "none",
    },
    "& > td": {
      border: "none",
    },
  },
  tableRowBorder: {
    "& > td": {
      border: "none",
    },
  },
}));
