import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  email: {
    inputProps: {
      required: true,
      label: "Email",
      type: "email",
    },
    rule: "email",
  },
  password: {
    inputProps: {
      label: "Password",
      required: true,
      type: "password",
    },
  },
  remember: {
    inputProps: {
      label: "Remember me for 30 days",
    },
  },
});
