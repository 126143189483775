import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
    borderRadius: "50%",
    border: "2px #fff solid",
    boxShadow: "0 0 1px 2px red",
  },
  avatarCell: {
    display: "flex",
    alignItems: "center",
  },
  tableRow: {
    "& > th": {
      fontWeight: 600,
      border: "none",
    },
    "& > td": {
      border: "none",
      cursor: "pointer",
    },
  },
  tableRowBorder: {
    "& > td": {
      border: "none",
    },
  },
}));
