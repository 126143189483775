import { FC } from "react";
import { useStyles } from "./ErrorComponent.styles";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const ErrorComponent: FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <ErrorIcon fontSize="large" color="error" />
      <Typography color="error" variant="caption">
        An error occured!
      </Typography>
    </div>
  );
};
