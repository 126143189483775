import { gql } from "@apollo/client";

export interface IAgent {
  id: string;
  nickname: string;
  deactivated: boolean;
  expired: string | null;

  person: {
    id: string;
    name: string;
    email: string;
  };
  agency: {
    id: string;
    name: string;
  };

  role: {
    id: string;
    name: string;
  };
}

export interface IAgentsData {
  allAgents: IAgent[];
}

export interface IAgentsVars {
  search: string;
  showArchived: boolean;
}

export const ALL_AGENTS = gql`
  query allAgents($search: String, $showArchived: Boolean) {
    allAgents(search: $search, showArchived: $showArchived) {
      id
      nickname
      deactivated
      expired

      person {
        id
        email
        name
      }
      agency {
        id
        name
      }

      role {
        id
        name
      }
    }
  }
`;

//_________________________________________ ONE AGENT ________________________________________________//
export interface IAgentData {
  oneAgent?: IAgent;
}

export interface IAgentVars {
  id: number;
}

export const ONE_AGENT = gql`
  query oneAgent($id: ID!) {
    oneAgent(id: $id) {
      id
      nickname
      role {
        id
        name
      }
      agency {
        id
        name
      }
      deactivated
    }
  }
`;
