import { gql } from "@apollo/client";

export interface IUpdateFilter {
  id: string;

  name: string;
  nationalityIds: string[] | undefined;
  agentIds: string[] | undefined;
  rankIds: string[] | undefined;
  forAgents: { id: string; nickname: string }[] | undefined;
}

export interface IUpdateFilterData {
  updateFilter: IUpdateFilter;
}

export interface IUpdateFilterVars {
  id: number;
  data: {
    name: string | undefined;
    nationalityIds: string[] | undefined | null;
    agentIds: string[] | undefined | null;
    rankIds: string[] | undefined | null;
    forAgents: string[] | undefined | null;
  };
}

export const UPDATE_FILTER = gql`
  mutation updateFilter($id: ID!, $data: FilterUpdateInput!) {
    updateFilter(id: $id, data: $data) {
      id
      name
      nationalityIds
      agentIds
      rankIds

      forAgents {
        id
        nickname
      }
    }
  }
`;
