import { createTypedDynamicFormInputs } from "../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  currentPassword: {
    values: { value: "" },
    inputProps: {
      label: "Current password",
      name: "currentPassword",
    },
  },
  newPassword: {
    values: { value: "" },
    inputProps: {
      type: "password",
      label: "New password",
      name: "newPassword",
    },
  },
  repeatNewPassword: {
    values: { value: "" },
    inputProps: {
      label: "Repeat new password",
      name: "repeatPassword",
    },
  },
});
