import { FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
} from "@mui/material";
import { useStyles } from "./UpsertRankGroupDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertRankGroupDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../../../utils";
import {
  CREATE_RANK_GROUP,
  ICreateRankGroupData,
  ICreateRankGroupVars,
  IUpdateRankGroupData,
  IUpdateRankGroupVars,
  UPDATE_RANK_GROUP,
} from "../../../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../../../components";

import {
  ALL_RANK_GROUPS,
  IRankGroup,
  IRankGroupData,
  IRankGroupsData,
  IRankGroupVars,
  ONE_RANK_GROUP,
} from "../../../../../../../apollo/queries";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  rankGroupId: string | undefined;
  // refetch: any;
};

type TProps = DialogProps & TDialogProps;

export const UpsertRankGroupDialog: FC<TProps> = (props) => {
  const { open, onClose, rankGroupId, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { inputFields, setInputFields, resetFields, handleDataToVar } =
    useForm<keyof typeof initialInputData>(initialInputData);
  console.log(inputFields);

  const [callOneRankGroupQuery, { loading, error, data }] = useLazyQuery<
    IRankGroupData,
    IRankGroupVars
  >(ONE_RANK_GROUP, {
    fetchPolicy: "network-only",
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (rankGroupId && open) {
      callOneRankGroupQuery({
        variables: {
          id: +rankGroupId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankGroupId, open]);

  const [createRankGroupMutation, { loading: loadingCreateRankGroup }] =
    useMutation<ICreateRankGroupData, ICreateRankGroupVars>(CREATE_RANK_GROUP, {
      onCompleted: () => {
        enqueueSnackbar("Added!", {
          variant: "success",
        });
        // refetch?.();
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        console.log({ error });
      },

      update: (cache, { data }) => {
        const existingData: IRankGroupsData | null = cache.readQuery({
          query: ALL_RANK_GROUPS,
        });
        if (data?.createRankGroup) {
          const newItem: IRankGroup = {
            ...data.createRankGroup,
          };
          cache.writeQuery({
            query: ALL_RANK_GROUPS,
            data: {
              allRankGroups: existingData?.allRankGroups
                ? [newItem, ...existingData.allRankGroups]
                : [newItem],
            },
          });
        }
      },
    });

  const [updateRankGroupMutation, { loading: loadingUpdateRankGroup }] =
    useMutation<IUpdateRankGroupData, IUpdateRankGroupVars>(UPDATE_RANK_GROUP, {
      onCompleted: () => {
        enqueueSnackbar("Updated!", {
          variant: "success",
        });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        console.log({ error });
      },
    });

  const handleCreateRankGroup = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!rankGroupId) {
        createRankGroupMutation({
          variables: {
            data: {
              name: inputFields.name.inputProps.value,
            },
          },
        });
      } else {
        updateRankGroupMutation({
          variables: {
            id: +rankGroupId,
            data: {
              name: handleDataToVar("name", "string", false),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (rankGroupId && data?.oneRankGroup) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "name",
          toFormProperty: "name",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneRankGroup, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rankGroupId]);

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={handleDialogCleanup}
      maxWidth="sm"
      fullWidth
      {...rest}
    >
      <DialogTitle>
        {rankGroupId ? "Edit rank group" : "Add new rank group"}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          hasData={Boolean(data?.oneRankGroup) || !Boolean(rankGroupId)}
        >
          <TextField
            {...inputFields.name.inputProps}
            fullWidth
            autoFocus
            margin="normal"
          />
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateRankGroup}
        >
          {rankGroupId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={loadingCreateRankGroup || loadingUpdateRankGroup}
      />
    </Dialog>
  );
};
