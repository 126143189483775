import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
  },
  marginTop: {
    marginTop: theme.spacing(4),
    height: `calc(100vh - ${theme.spacing(12)})`,
  },
  marginTop1x: {
    paddingTop: theme.spacing(12),
    height: `calc(100vh - ${theme.spacing(16)})`,
  },
  marginTop2x: {
    height: `calc(100vh - ${theme.spacing(14)})`,
  },
  row: {
    flexDirection: "row",
  },
  column: {
    flexDirection: "column",
  },
}));
