import { gql } from "@apollo/client";

export interface ICheckPersonInviteData {
  checkPersonInvite: boolean;
}

export interface ICheckPersonInviteVars {
  token: string;
}

export const CHECK_PERSON_INVITE = gql`
  mutation checkPersonInvite($token: NonEmptyString!) {
    checkPersonInvite(token: $token)
  }
`;

export interface IRevokePersonInvitation {
  id: string;
  nickname: string;
  email: string;
  revoked: boolean;

  agency?: {
    id: string;
    name: string;
  };

  role: {
    id: string;
    name: string;
  };

  created: string;
  expired: string | null;
}

export interface IRevokePersonInvitationData {
  revokePersonInvitation: IRevokePersonInvitation;
}

export interface IRevokePersonInvitationVars {
  id: number;
}

export const REVOKE_PERSON_INVITATION = gql`
  mutation revokePersonInvitation($id: ID!) {
    revokePersonInvitation(id: $id) {
      id
      email
      nickname
      revoked
      created
      expired

      agency {
        id
        name
      }

      role {
        id
        name
      }
    }
  }
`;

export interface IResendPersonInvitation {
  id: string;
  nickname: string;
  email: string;
  revoked: boolean;

  agency?: {
    id: string;
    name: string;
  };

  role: {
    id: string;
    name: string;
  };

  created: string;
  expired: string | null;
}

export interface IResendPersonInvitationData {
  //Created / Updated
  resendPersonInvitation: [IResendPersonInvitation, IResendPersonInvitation];
}

export interface IResendPersonInvitationVars {
  id: number;
}

export const RESEND_PERSON_INVITATION = gql`
  mutation resendPersonInvitation($id: ID!) {
    resendPersonInvitation(id: $id) {
      id
      email
      nickname
      revoked
      created
      expired

      agency {
        id
        name
      }

      role {
        id
        name
      }
    }
  }
`;
