import { gql } from "@apollo/client";

export interface IDeactivateOrReactivateAgent {
  id: string;
  deactivated: boolean;
}

export interface IDeactivateOrReactivateAgentData {
  deactivateOrReactivateAgent: IDeactivateOrReactivateAgent;
}
export interface IDeactivateOrReactivateAgentVars {
  id: number;
  deactivated: boolean;
}

export const DEACTIVATE_OR_REACTIVATE_AGENT = gql`
  mutation deactivateOrReactivateAgent($id: ID!, $deactivated: Boolean!) {
    deactivateOrReactivateAgent(id: $id, deactivated: $deactivated) {
      id
      deactivated
    }
  }
`;
