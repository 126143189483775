import { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useStyles } from "./SeafarersScreen.styles";
import { useSnackbar } from "notistack";
import { useOutletContext } from "react-router";
import { DataHandlerComponent, PageLayout } from "../../../components";
import { UpsertSeafarerDialog, SeafarerItem } from "./components";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  ALL_SEAFARERS,
  ISeafarersListData,
  ISeafarersListVars,
  ISeafarerToClipboardData,
  ISeafarerToClipboardVars,
  SEAFARER_TO_CLIPBOARD,
} from "../../../apollo/queries";
import { IOutletContext } from "../../../components/Navbar/components";
import { AddOutlined as AddOutlinedIcon } from "@mui/icons-material";

export const SeafarersScreen: FC = () => {
  const { activeSearch, activeFilter } = useOutletContext<IOutletContext>();
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  // const typographyRef = useRef<HTMLParagraphElement>(null);
  // const navigation = useNavigate();

  const [upsertSeafarerDialog, setUpsertSeafarerDialog] = useState(false);
  // const [seafarerToClipboardDialog, setSeafarerToClipboardDialog] =
  //   useState(false);

  // const handleOpenSeafarerToClipboardDialog = () => {
  //   setSeafarerToClipboardDialog(true);
  // };

  // const handleCloseSeafarerToClipboardDialog = () => {
  //   setSeafarerToClipboardDialog(false);
  // };

  const queryVariables: ISeafarersListVars = useMemo(() => {
    return {
      cursor: undefined,
      filter:
        activeFilter || activeSearch
          ? {
              searchQuery: activeSearch,
              nationalityIds: activeFilter?.nationalityIds.values.value
                ? activeFilter.nationalityIds.values.value.split(",")
                : // .map((x: any) => +x)
                  undefined,
              agentIds: activeFilter?.agentIds.values.value
                ? activeFilter.agentIds.values.value.split(",")
                : // .map( ( x: any ) => +x )
                  undefined,
              rankIds: activeFilter?.rankIds.values.value
                ? activeFilter.rankIds.values.value.split(",")
                : // .map( ( x: any ) => +x )
                  undefined,
            }
          : undefined,
    };
  }, [activeFilter, activeSearch]);

  const { loading, error, data, fetchMore, refetch, called } = useQuery<
    ISeafarersListData,
    ISeafarersListVars
  >(ALL_SEAFARERS, {
    variables: queryVariables,
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  //TODO: ADD WHEN IT BECOMES DEPRICATED
  const [callSeafarerToClipboardQuery] = useLazyQuery<
    ISeafarerToClipboardData,
    ISeafarerToClipboardVars
  >(SEAFARER_TO_CLIPBOARD, {
    onCompleted: (data) => {
      enqueueSnackbar("Text copied", { variant: "success" });
      navigator.clipboard.writeText(data?.seafarerToClipboard || "Error");
    },
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: "error" });
    },
  });

  useEffect(() => {
    if (called) {
      refetch(queryVariables);
    }
  }, [called, refetch, queryVariables]);

  const handleOpenUpsertSeafarerDialog = () => {
    setUpsertSeafarerDialog(true);
  };

  const handleCloseUpsertSeafarerDialog = () => {
    setUpsertSeafarerDialog(false);
  };

  const handleFetchMore = async () => {
    if (data?.allSeafarers) {
      const moreData = await fetchMore({
        variables: {
          ...queryVariables,
          cursor: +data?.allSeafarers?.[data.allSeafarers.length - 1]?.id,
        },
      });
      // console.log(moreData);
      if (moreData.data?.allSeafarers?.length) {
        enqueueSnackbar(
          `Fetched ${moreData.data.allSeafarers.length} more seafarers.`,
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(`No more seafarers found!`, { variant: "info" });
      }
    }
  };

  // useEffect(() => {
  //   if (seafarerToClipboardDialog) {
  //     if (typographyRef.current) {

  //       typographyRef.current.;
  //     }
  //   }
  // }, [seafarerToClipboardDialog]);

  return (
    <>
      <PageLayout marginTop="2x">
        <div className={cx(classes.justify)}>
          <Typography variant="h1">Seafarers</Typography>
          <Button
            onClick={handleOpenUpsertSeafarerDialog}
            variant="contained"
            startIcon={<AddOutlinedIcon />}
          >
            Add new seafarer
          </Button>
        </div>
        <Typography marginBottom={2}>
          Showing: {data?.allSeafarers?.length || "0"} seafarers
        </Typography>

        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className={cx(classes.tableRow)}>
                <TableCell align="left">Name</TableCell>
                <TableCell width="10%" align="left">
                  Rank
                </TableCell>
                <TableCell width="10%" align="left">
                  Nationality
                </TableCell>
                <TableCell width="10%" align="left">
                  Status
                </TableCell>
                <TableCell width="10%" align="left">
                  Complaint status
                </TableCell>
                <TableCell width="10%" align="left">
                  Current vessel
                </TableCell>
                <TableCell width="10%" align="left">
                  Available from
                </TableCell>
                <TableCell width="5%" align="left">
                  Rating
                </TableCell>
                <TableCell width="5%" align="left">
                  Agent
                </TableCell>
                <TableCell width={64} align="left">
                  More
                </TableCell>
                <TableCell width={64} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={cx(classes.tableBody)}>
              {data?.allSeafarers?.length
                ? data.allSeafarers.map((item) => {
                    const handleSeafarerToClipboard = () => {
                      // handleOpenSeafarerToClipboardDialog();
                      callSeafarerToClipboardQuery({
                        variables: {
                          id: +item.id,
                        },
                      });
                    };

                    return (
                      <SeafarerItem
                        key={item.id}
                        data={item}
                        handleSeafarerToClipboard={handleSeafarerToClipboard}
                      />
                    );
                  })
                : null}
            </TableBody>
          </Table>
          <DataHandlerComponent
            loading={loading}
            error={Boolean(error)}
            hasData={Boolean(data?.allSeafarers)}
          />
        </TableContainer>

        <Button
          onClick={handleFetchMore}
          variant="outlined"
          className={cx(classes.showMoreButton)}
        >
          Show more
        </Button>
        <UpsertSeafarerDialog
          open={upsertSeafarerDialog}
          maxWidth="md"
          fullWidth
          onClose={handleCloseUpsertSeafarerDialog}
          searchQuery={""}
          refetch={refetch}
        />
        {/* <Dialog
          open={seafarerToClipboardDialog}
          onClose={handleCloseSeafarerToClipboardDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>Copy to clipboard</DialogTitle>
          <DialogContent>
            <DataHandlerComponent
              loading={loadingSeafarerToClipboard}
              error={Boolean(errorSeafarerToClipboard)}
              hasData={Boolean(dataSeafarerToClipboard?.seafarerToClipboard)}
            >
              <Typography className={classes.whiteSpace} ref={typographyRef}>
                {dataSeafarerToClipboard?.seafarerToClipboard}
              </Typography>
            </DataHandlerComponent>
          </DialogContent>
          <DialogActions>
            <Button>Close</Button>
          </DialogActions>
        </Dialog> */}
      </PageLayout>
    </>
  );
};
