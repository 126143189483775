import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: "16px 0",
  },
  inputField: {
    margin: "8px 0",
  },
  bigInput: {
    flexBasis: "45%",
  },
  smallInput: {
    flexBasis: "30%",
  },
  tabPanel: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 0,
  },
  content: {
    // minHeight: "460px",
  },
}));
