import { gql } from "@apollo/client";
import { ICountry } from "../../../queries";

export interface ICreateAgency {
  id: string;
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: ICountry;
}

export interface ICreateAgencyData {
  createAgency: ICreateAgency;
}

export interface ICreateAgencyVars {
  data: {
    name: string;
    street: string;
    zipCode: string;
    city: string;
    countryId: number;
  };
}

export const CREATE_AGENCY = gql`
  mutation createAgency($data: AgencyCreateInput!) {
    createAgency(data: $data) {
      id
      name
      street
      zipCode
      city
      country {
        id
        name
      }
    }
  }
`;
