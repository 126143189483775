import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    color: "#999",
  },
  rootSmall: {
    display: "flex",
    margin: "16px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "#999",
  },
}));
