import { gql } from "@apollo/client";

export interface ICreateRankGroupVars {
  data: {
    name: string;
  };
}

export interface ICreateRankGroup {
  id: string;

  name: string;
}

export interface ICreateRankGroupData {
  createRankGroup: ICreateRankGroup;
}

export const CREATE_RANK_GROUP = gql`
  mutation createRankGroup($data: RankGroupCreateInput!) {
    createRankGroup(data: $data) {
      id

      name
    }
  }
`;
