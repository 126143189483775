import { gql } from "@apollo/client";

export interface ICreatePersonPasswordResetTokenVars {
  data: {
    email: string;
  };
}

export interface ICreatePersonPasswordResetToken {
  id: string;
}

export interface ICreatePersonPasswordResetTokenData {
  createPersonPasswordResetToken: ICreatePersonPasswordResetToken;
}

export const CREATE_PERSON_PASSWORD_RESET_TOKEN = gql`
  mutation createPersonPasswordResetToken(
    $data: PersonCreatePasswordResetTokenInput!
  ) {
    createPersonPasswordResetToken(data: $data)
  }
`;
