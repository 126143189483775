import "./fonts/fonts.css";
import ReactDOM from "react-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { client } from "./apollo";
import { theme } from "./styles/theme";
import { App } from "./App";
import { StrictMode } from "react";
import { ApolloProvider } from "@apollo/client";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export const muiCache = createCache({
  key: "mui",
  prepend: true,
});

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <SnackbarProvider maxSnack={3} preventDuplicate>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
              </LocalizationProvider>
            </SnackbarProvider>
          </CssBaseline>
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root")
);
