import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IAgent } from "../../../../../../../apollo/queries";
import { useStyles } from "./AgentItem.styles";

interface IProps {
  data: IAgent;
  handleToggleActionMenu: any;
}

export const AgentItem: FC<IProps> = (props) => {
  const { data, handleToggleActionMenu } = props;
  const { classes } = useStyles();

  const status = data.deactivated
    ? "Deactivated"
    : data.expired
    ? "Deleted"
    : "Active";

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>{data.nickname? data.nickname : data.person.name}</TableCell>
      <TableCell>{data.person.email}</TableCell>
      <TableCell>{data.agency?.name || "N/A"}</TableCell>
      <TableCell>{data.role.name}</TableCell>
      <TableCell>{status}</TableCell>

      <TableCell>
        <IconButton onClick={handleToggleActionMenu}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
