import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { FC } from "react";
import { IRankGroup } from "../../../../../apollo/queries";
import { useStyles } from "./RankGroupListItem.styles";

interface IProps {
  data: IRankGroup;
  selected: string | undefined;
  onClick: () => void;
  handleToggleActionMenu: any;
}

export const RankGroupListItem: FC<IProps> = (props) => {
  const { data, selected, onClick, handleToggleActionMenu } = props;
  const { classes } = useStyles();

  return (
    <ListItem
      onClick={onClick}
      className={selected === data.id ? classes.selected : ""}
      disablePadding
      secondaryAction={
        <IconButton onClick={handleToggleActionMenu} edge="end">
          <MoreVertIcon />
        </IconButton>
      }
    >
      <ListItemButton>
        <ListItemText primary={data.name} />
      </ListItemButton>
    </ListItem>
  );
};
