import { gql } from "@apollo/client";
import { ICurrentUser } from "../queries";

export interface ILoginData {
  login: ICurrentUser;
}

export interface ILoginVars {
  data: {
    email: string;
    password: string;
    remember: boolean;
  };
}

export const LOGIN = gql`
  mutation login($data: LoginInput) {
    login(data: $data) {
      id
      currentAgent {
        id
        organisationId
        organisation {
          id
          name
        }
      }
      name
      email
      allOrganisations {
        id
        name
      }
    }
  }
`;
