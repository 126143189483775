import {
  Dispatch,
  forwardRef,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  Tab,
  DialogProps,
  TextField,
  Autocomplete,
  FormControlLabel,
  Switch,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Checkbox,
  MenuItem,
  createFilterOptions,
  Divider,
  Typography,
} from "@mui/material";
import { useStyles } from "./FilterDialog.styles";
import { useSnackbar } from "notistack";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  ContextProvider,
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../../utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ALL_AGENTS,
  ALL_FILTERS,
  ALL_NATIONALITIES,
  ALL_RANKS,
  IAgent,
  IAgentsData,
  IFilter,
  IFilterData,
  IFiltersData,
  INationalitiesData,
  INationality,
  IRank,
  IRanksData,
  ONE_FILTER,
} from "../../../../../../apollo/queries";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { initialInputData } from "./FilterDialog.inputs";
import {
  CREATE_FILTER,
  ICreateFilterData,
  ICreateFilterVars,
  IUpdateFilterData,
  IUpdateFilterVars,
  UPDATE_FILTER,
} from "../../../../../../apollo/mutations";
import { LoadingBackdrop } from "../../../../../LoadingBackdrop/LoadingBackdrop";
import { DataHandlerComponent } from "../../../../../dataHandlerComponent/DataHandlerComponent";
import { TActiveFilter } from "../../SeafarersNavbar";
import { useGlobalStyles } from "../../../../../../styles";
import dcopy from "deep-copy";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  activeFilter: TActiveFilter | undefined;
  setActiveFilter: Dispatch<SetStateAction<TActiveFilter | undefined>>;
  filterId?: string | undefined;
  handleClearDialogState: () => void;
};

type TProps = DialogProps & TDialogProps;

export type TGetSavedFilter = {
  getSavedFilter: (filter: IFilter) => void;
};

export const FilterDialog = forwardRef<TGetSavedFilter, TProps>(
  (props, ref) => {
    const {
      onClose,
      setActiveFilter,
      activeFilter,
      open,
      filterId,
      handleClearDialogState,
      ...rest
    } = props;
    const { classes } = useStyles();
    const { user } = useContext(ContextProvider);
    const { classes: globalClasses } = useGlobalStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [saveFilterActive, setSaveFilterActive] = useState(false);
    const [tabToShow, setTabToShow] = useState("1");

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const {
      inputFields,
      resetFields,
      setInputField,
      setInputFields,
      handleDataToVar,
    } = useForm<keyof typeof initialInputData>(initialInputData);

    useImperativeHandle(ref, () => ({
      getSavedFilter: (filter) => {
        getSavedFilter(filter);
      },
    }));

    const getSavedFilter = (filter: IFilter) => {
      if (filter) {
        // TODO: FIX
        // const valueToFormOptions: TValueToFormOptions = [
        //   {
        //     fromDataProperty: "nationality",
        //     toFormProperty: "nationality",
        //   },
        //   {
        //     fromDataProperty: "agent",
        //     toFormProperty: "agent",
        //   },
        //   {
        //     fromDataProperty: "rank",
        //     toFormProperty: "rank",
        //   },
        // ];
        // const getUpdatedInputs = getFormValuesFromFetchedData<
        //   keyof typeof initialInputData
        // >(filter, valueToFormOptions, inputFields);

        const getUpdatedInputs = dcopy(inputFields);

        if (filter.agentIds) {
          getUpdatedInputs.agentIds.values.value =
            filter.agentIds?.join(",") || "";
        }

        if (filter.nationalityIds) {
          getUpdatedInputs.nationalityIds.values.value =
            filter.nationalityIds?.join(",") || "";
        }

        if (filter.rankIds) {
          getUpdatedInputs.rankIds.values.value =
            filter.rankIds?.join(",") || "";
        }

        console.log("FILTER", filter);

        enqueueSnackbar("Filter applied!", { variant: "info" });
        setInputFields(getUpdatedInputs);
        setActiveFilter(getUpdatedInputs);
      }
    };

    const [
      callOneFilterQuery,
      { data: dataFilter, error: errorFilter, loading: loadingFilter },
    ] = useLazyQuery<IFilterData>(ONE_FILTER);

    const [
      callAllAgentsQuery,
      { data: dataAgents, error: errorAgents, loading: loadingAgents },
    ] = useLazyQuery<IAgentsData>(ALL_AGENTS);

    const [
      callAllRanksQuery,
      { data: dataRanks, error: errorRanks, loading: loadingRanks },
    ] = useLazyQuery<IRanksData>(ALL_RANKS);

    const [
      callAllNationalitiesQuery,
      {
        data: dataNationalities,
        error: errorNationalities,
        loading: loadingNationalities,
      },
    ] = useLazyQuery<INationalitiesData>(ALL_NATIONALITIES);

    useEffect(() => {
      if (filterId && dataFilter?.oneFilter) {
        const valueToFormOptions: TValueToFormOptions = [
          //TODO: FIX STRING ARRRAY
          // {
          //   fromDataProperty: "nationality",
          //   toFormProperty: "nationality",
          // },
          // {
          //   fromDataProperty: "agent",
          //   toFormProperty: "agent",
          // },
          // {
          //   fromDataProperty: "rank",
          //   toFormProperty: "rank",
          // },
          {
            fromDataProperty: "name",
            toFormProperty: "filterName",
          },
          // {
          //   fromDataProperty: "personOrganisationRole.id",
          //   toFormProperty: "saveForAgents",
          // },
        ];
        const getUpdatedInputs = getFormValuesFromFetchedData<
          keyof typeof initialInputData
        >(dataFilter.oneFilter, valueToFormOptions, inputFields);

        //TODO:: TEMPORARY ------
        if (dataFilter.oneFilter.agentIds) {
          getUpdatedInputs.agentIds.values.value =
            dataFilter.oneFilter.agentIds?.join(",") || "";
          getUpdatedInputs.agentIds.initialValues = {
            value: dataFilter.oneFilter.agentIds?.join(",") || "",
            hasChanged: false,
          };
        }

        if (dataFilter.oneFilter.nationalityIds) {
          getUpdatedInputs.nationalityIds.values.value =
            dataFilter.oneFilter.nationalityIds?.join(",") || "";
          getUpdatedInputs.nationalityIds.initialValues = {
            value: dataFilter.oneFilter.nationalityIds?.join(",") || "",
            hasChanged: false,
          };
        }

        if (dataFilter.oneFilter.rankIds) {
          getUpdatedInputs.rankIds.values.value =
            dataFilter.oneFilter.rankIds?.join(",") || "";
          getUpdatedInputs.rankIds.initialValues = {
            value: dataFilter.oneFilter.rankIds?.join(",") || "",
            hasChanged: false,
          };
        }

        if (dataFilter.oneFilter.forAgents) {
          getUpdatedInputs.forAgents.values.value =
            dataFilter.oneFilter.forAgents.map((x) => x.id).join(",") || "";
          getUpdatedInputs.forAgents.initialValues = {
            value:
              dataFilter.oneFilter.forAgents?.map((x) => x.id)?.join(",") || "",
            hasChanged: false,
          };
        }
        //TODO:: TEMPORARY ------

        // console.log("UPDATED: ", getUpdatedInputs);
        setInputFields(getUpdatedInputs);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFilter, filterId]);

    useEffect(() => {
      if (open) {
        callAllAgentsQuery();
        callAllRanksQuery();
        callAllNationalitiesQuery();

        // console.log("ID OPEN: ", filterId);

        if (filterId) {
          callOneFilterQuery({
            variables: {
              id: +filterId,
            },
          });
          setSaveFilterActive(true);
        } else {
          //Load the filter back in when closed
          //! TYPESCRIPT
          //TODO: FIX
          if (activeFilter) {
            setInputFields({ ...(activeFilter as any) });
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, filterId]);

    const [updateFilterMutation, { loading: loadingUpdateFilter }] =
      useMutation<IUpdateFilterData, IUpdateFilterVars>(UPDATE_FILTER, {
        onCompleted: (res) => {
          enqueueSnackbar(`Filter updated: - ${res.updateFilter.name} -`, {
            variant: "success",
          });
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          // console.log({ error });
        },
      });

    const [createFilterMutation, { loading: loadingCreateFilter }] =
      useMutation<ICreateFilterData, ICreateFilterVars>(CREATE_FILTER, {
        onCompleted: (res) => {
          enqueueSnackbar(`Filter added: - ${res.createFilter.name} -`, {
            variant: "success",
          });
          handleCancelSaveFilterActive();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          // console.log({ error });
        },
        notifyOnNetworkStatusChange: true,

        update: (cache, { data }) => {
          if (
            data?.createFilter.forAgents.length &&
            !data?.createFilter.forAgents?.find(
              (x) => x.id === user?.currentAgent?.id
            )
          ) {
            return null;
          }

          const existingData: IFiltersData | null = cache.readQuery({
            query: ALL_FILTERS,
          });
          if (data?.createFilter) {
            const newItem: IFilter = {
              ...data.createFilter,
            };

            cache.writeQuery({
              query: ALL_FILTERS,

              data: {
                allFilters: existingData?.allFilters
                  ? [newItem, ...existingData.allFilters]
                  : [newItem],
              },
            });
          }
        },
      });

    const filterOptions = createFilterOptions<INationality>({
      limit: 50,
    });

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
      setTabToShow(newValue);
    };

    const handleSetFilter = () => {
      //Check if all inputfield.value.values are empty
      const isEmpty = Object.values(inputFields).every((item) => {
        return item.values.value === "";
      });

      if (isEmpty) {
        setActiveFilter(undefined);
      } else {
        setActiveFilter(inputFields);
      }
      enqueueSnackbar("Filter applied", { variant: "info" });
      onClose();
    };

    const handleCancelSaveFilterActive = () => {
      resetFields(["forAgents", "filterName"]);
      setSaveFilterActive(false);
    };

    const handleOpenSaveFilterActive = () => {
      setSaveFilterActive(true);
    };

    const handleUpsertFilter = () => {
      const result = validateForm(["filterName"], inputFields);
      if (result.formValid) {
        if (!filterId) {
          createFilterMutation({
            variables: {
              data: {
                name: inputFields.filterName.inputProps.value,
                nationalityIds: handleDataToVar(
                  "nationalityIds",
                  "stringArray",
                  false
                ),
                agentIds: handleDataToVar("agentIds", "stringArray", false),
                rankIds: handleDataToVar("rankIds", "stringArray", false),
                forAgents: handleDataToVar("forAgents", "stringArray", false),
              },
            },
          });
        } else {
          updateFilterMutation({
            variables: {
              id: +filterId,
              data: {
                name: handleDataToVar("filterName", "string", false),
                nationalityIds: handleDataToVar(
                  "nationalityIds",
                  "stringArray"
                ),
                agentIds: handleDataToVar("agentIds", "stringArray"),
                rankIds: handleDataToVar("rankIds", "stringArray"),
                forAgents: handleDataToVar("forAgents", "stringArray"),
              },
            },
          });
        }
      } else {
        enqueueSnackbar("Not all required fields are set!", {
          variant: "error",
        });
        setInputFields((prev) => ({ ...prev, ...result.outputData }));
      }
    };

    const handleSetAutocompleteValue = (inputField: string, value: string) => {
      setInputField(inputField as any, value ? value : "");
    };

    const onChangeNationality = (
      event: SyntheticEvent<Element, Event>,
      value: INationality[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<INationality> | undefined
    ) => {
      handleSetAutocompleteValue(
        "nationalityIds",
        value.map((item) => item.id).toString()
      );
    };

    const onChangeAgent = (
      event: SyntheticEvent<Element, Event>,
      value: IAgent[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<IAgent> | undefined
    ) => {
      handleSetAutocompleteValue(
        "agentIds",
        value.map((item) => item.id).toString()
      );
    };

    const onChangeRank = (
      event: SyntheticEvent<Element, Event>,
      value: IRank[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<IRank> | undefined
    ) => {
      handleSetAutocompleteValue(
        "rankIds",
        value.map((item) => item.id).toString()
      );
    };

    const onChangeSaveForAgents = (
      event: SyntheticEvent<Element, Event>,
      value: IAgent[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<IAgent> | undefined
    ) => {
      handleSetAutocompleteValue(
        "forAgents",
        value.map((item) => item.id).toString()
      );
    };

    const clearFilter = () => {
      enqueueSnackbar("Filter cleared", { variant: "info" });
      setActiveFilter(undefined);
      resetFields();
    };

    const handleDialogCleanup = {
      onExited: () => {
        resetFields();
        handleClearDialogState();
        handleCancelSaveFilterActive();
      },
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionProps={handleDialogCleanup}
        {...rest}
      >
        <DialogTitle className={globalClasses.disableDialogTitleMargin}>
          Filters
        </DialogTitle>
        <TabContext value={tabToShow}>
          <div className={classes.tabDiv}>
            <TabList
              onChange={handleChangeTab}
              variant="fullWidth"
              sx={{ flexBasis: "60%" }}
            >
              <Tab label="Seafarer" value="1" />
              <Tab label="Vessel" value="2" />
            </TabList>
            <FormControlLabel control={<Switch />} label="Advanced filters" />
          </div>
          <DialogContent className={classes.content}>
            <TabPanel value="1" className={classes.tabPanel}>
              <DataHandlerComponent
                loading={
                  loadingAgents ||
                  loadingNationalities ||
                  loadingRanks ||
                  loadingFilter
                }
                error={Boolean(
                  errorAgents || errorNationalities || errorRanks || errorFilter
                )}
                hasData={true}
                skeletonNum={3}
              >
                <Autocomplete
                  multiple
                  options={
                    dataNationalities?.allNationalities?.length
                      ? dataNationalities.allNationalities
                      : []
                  }
                  disableCloseOnSelect
                  loading={loadingNationalities}
                  autoComplete
                  className={classes.bigInput}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={onChangeNationality}
                  value={
                    dataNationalities?.allNationalities?.filter((x) =>
                      inputFields.nationalityIds.values.value
                        .split(",")
                        .includes(x.id)
                    ) || []
                  }
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={inputFields.nationalityIds.inputProps.label}
                      margin="normal"
                    />
                  )}
                />

                <Autocomplete
                  multiple
                  options={
                    dataRanks?.allRanks?.length ? dataRanks.allRanks : []
                  }
                  disableCloseOnSelect
                  loading={loadingRanks}
                  className={classes.bigInput}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={onChangeRank}
                  value={
                    dataRanks?.allRanks?.filter((x) =>
                      inputFields.rankIds.values.value.split(",").includes(x.id)
                    ) || []
                  }
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={inputFields.rankIds.inputProps.label}
                      margin="normal"
                    />
                  )}
                />
                <Autocomplete
                  multiple
                  options={
                    dataAgents?.allAgents?.length ? dataAgents?.allAgents : []
                  }
                  disableCloseOnSelect
                  loading={loadingAgents}
                  className={classes.bigInput}
                  getOptionLabel={(option) => option.nickname}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={onChangeAgent}
                  value={
                    dataAgents?.allAgents?.filter((x) =>
                      inputFields.agentIds.values.value
                        .split(",")
                        .includes(x.id)
                    ) || []
                  }
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nickname}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={inputFields.agentIds.inputProps.label}
                      margin="normal"
                    />
                  )}
                />
              </DataHandlerComponent>
            </TabPanel>
            <TabPanel value="2" className={classes.tabPanel}>
              {/* <TextField
              {...inputProps("vesselType")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              //TODO: Select?
              {...inputProps("mainEngine")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("grtMin")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("grtMax")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("teuMin")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("teuMax")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("kwMin")}
              className={cx(classes.bigInput)}
              margin="normal"
            />
            <TextField
              {...inputProps("kwMax")}
              className={cx(classes.bigInput)}
              margin="normal"
            /> */}
            </TabPanel>
            {saveFilterActive ? (
              <>
                <div className={globalClasses.justifyBetween}>
                  <Typography color="primary" variant="subtitle1">
                    Filter info
                  </Typography>
                  {!filterId ? (
                    <Button onClick={handleCancelSaveFilterActive} size="small">
                      Cancel
                    </Button>
                  ) : null}
                </div>
                <Divider />
                <TextField
                  {...inputFields.filterName.inputProps}
                  className={classes.bigInput}
                  margin="normal"
                />
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={dataAgents?.allAgents || []}
                  disableCloseOnSelect
                  loading={loadingAgents}
                  className={classes.bigInput}
                  getOptionLabel={(option) => option.nickname}
                  // renderTags={
                  //   inputFields.agent.initialValues?.hasChanged
                  //     ? (value, getTagProps) =>
                  //         value.map((option, index) => (
                  //           <Chip
                  //             variant="outlined"
                  //             label={option.nickname}
                  //             size="small"
                  //             color={
                  //               inputFields.agent.initialValues?.value
                  //                 .split(",")
                  //                 .includes(option.id)
                  //                 ? "default"
                  //                 : "primary"
                  //             }
                  //             {...getTagProps({ index })}
                  //           />
                  //         ))
                  //     : undefined
                  // }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={onChangeSaveForAgents}
                  value={
                    dataAgents?.allAgents?.filter((x) =>
                      inputFields.forAgents.values.value
                        .split(",")
                        .includes(x.id)
                    ) || []
                  }
                  renderOption={(props, option, { selected }) => (
                    <MenuItem {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.nickname}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={inputFields.forAgents.inputProps.label}
                      helperText={inputFields.forAgents.inputProps.helperText}
                      margin="normal"
                    />
                  )}
                />
              </>
            ) : null}
          </DialogContent>
        </TabContext>

        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="text" onClick={clearFilter}>
            Clear filter
          </Button>
          <Button
            variant={saveFilterActive ? "contained" : "outlined"}
            onClick={
              saveFilterActive ? handleUpsertFilter : handleOpenSaveFilterActive
            }
          >
            {saveFilterActive
              ? filterId
                ? "Update"
                : "Save filter"
              : "Save..."}
          </Button>
          {!filterId ? (
            <Button variant="contained" onClick={handleSetFilter}>
              Apply
            </Button>
          ) : null}
        </DialogActions>
        <LoadingBackdrop loading={loadingCreateFilter || loadingUpdateFilter} />
      </Dialog>
    );
  }
);
