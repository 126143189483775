import { createTypedDynamicFormInputs } from "../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  title: {
    values: { value: "" },
    inputProps: {
      name: "title",
      label: "Title",
      required: true,
    },
  },
  message: {
    values: { value: "" },
    inputProps: {
      name: "remark",
      label: "Remark",
      required: true,
    },
  },
  main: {
    values: { value: "" },
    isCheckbox: true,
    inputProps: {
      name: "main",
      label: "Main",
    },
  },
});
