import { FC, useState } from "react";
import { Paper, Tab, Typography } from "@mui/material";
import { useStyles } from "./SeafarerProfileScreen.styles";
import { useOutletContext, useParams } from "react-router";
import { ActivityList, InputShowcase, RemarkList } from "../../../components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IRemarksVars, ISeafarer } from "../../../apollo/queries";
import { joinClassNames } from "../../../utils";
import { useGlobalStyles } from "../../../styles";

interface IOutletContext {
  data: ISeafarer;
}

export const SeafarerProfileScreen: FC = () => {
  const { id: seafarerId } = useParams();
  const { data } = useOutletContext<IOutletContext>();
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const [tabToShow, setTabToShow] = useState("1");
  const [tabRemarksAndActivities, setTabRemarksAndActivities] = useState("1");

  const remarkQueryVariables: IRemarksVars["filter"] = {
    seafarerId: seafarerId ? +seafarerId : undefined,
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabToShow(newValue);
  };

  const handleChangeTabRemarksAndActivities = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabRemarksAndActivities(newValue);
  };

  return (
    <>
      <div className={classes.summaryDiv}>
        <TabContext value={tabToShow}>
          <TabList
            component={Paper}
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            variant="fullWidth"
            className={classes.tabDiv}
          >
            <Tab label="General" value="1" />
            <Tab label="Assignments" value="2" />
            <Tab label="Employment Stat." value="3" />
            <Tab label="Experience" value="4" />
          </TabList>
          <Paper className={globalClasses.scrollablePaper}>
            <TabPanel value="1" className={classes.tabPanel}>
              <div className={classes.tabPanelContent}>
                <InputShowcase
                  label="Email"
                  value={data.emails.length ? data.emails[0].value : "N/A"}
                />
                <InputShowcase
                  label="Phone"
                  value={
                    data.phones.length
                      ? data.phones.filter((x) => x.type.name === "Phone")?.[0]
                          ?.value
                      : "N/A"
                  }
                />
                <InputShowcase
                  label="Mobile phone"
                  value={
                    data.phones.length
                      ? data.phones.filter(
                          (x) => x.type.name === "Mobile phone"
                        )?.[0]?.value
                      : "N/A"
                  }
                />
                <InputShowcase label="Seafarer ID" value={data.id} />
                <InputShowcase label="Employed since" value="-" />
                <InputShowcase label="Total briefings" value="-" />
                <InputShowcase label="Last briefing" value="-" />
                <InputShowcase label="Last debriefing" value="-" />
                <InputShowcase label="Assignements" value="-" />
                <InputShowcase label="Experience" value="-" />
                <InputShowcase label="Document alerts" value="-" />
              </div>
            </TabPanel>
          </Paper>
        </TabContext>
      </div>
      <div className={classes.remarksAndActivitiesDiv}>
        {/* <TabContext value={tabRemarksAndActivities}> */}
        {/* <TabList
            component={Paper}
            onChange={handleChangeTabRemarksAndActivities}
            aria-label="lab API tabs example"
            variant="fullWidth"
            className={classes.tabDiv}
          >
            <Tab label="Remarks" value="1" />
             <Tab label="Activities" value="2" />
          </TabList> */}
        <Paper className={joinClassNames([globalClasses.scrollablePaper])}>
          {/* <TabPanel value="1" className={classes.tabPanelRemarks}> */}
          <RemarkList
            idList={{
              seafarerId: seafarerId ? +seafarerId : undefined,
            }}
            queryVariables={remarkQueryVariables}
          />
          {/* </TabPanel> */}
          {/* <TabPanel value="2" className={classes.tabPanelRemarks}>
              <ActivityList
                idList={{
                  seafarerId: seafarerId ? +seafarerId : undefined,
                }}
                queryVariables={remarkQueryVariables}
              />
            </TabPanel> */}
        </Paper>
        {/* </TabContext> */}
      </div>
    </>
  );
};
