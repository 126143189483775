import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  justify: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  //Agents
  agentsPaper: {
    padding: theme.spacing(2),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  popper: {
    zIndex: 1000,
    "$[data-popper-reference-hidden]": {
      visibility: "hidden",
      pointerEvents: "none",
    },
  },

  noDataFound: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  //Table
  tableContainer: {
    overflow: "hidden",
  },
  tableRow: {
    "& > th": {
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      // border: "none",
    },
    "& > td": {
      border: "none",
      // borderTop: "solid 1px #aaa",
    },
  },
  tableBody: {
    "& > tr:nth-of-type(2n + 1)": {
      backgroundColor: theme.palette.secondary.lighter,
    },
    "& > tr": {
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
    // "& > td": {
    //   borderBottom: "none",
    //   // borderTop: "solid 1px #aaa",
    // },
  },
}));
