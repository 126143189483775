import { gql } from "@apollo/client";
import { ICommunication, INationality } from "../../queries";

export interface ICreateSeafarerVars {
  data: {
    name: string;
    surname: string;
    birthday: Date;
    // employedSince: Date | undefined;
    nationalityId: number;

    placeOfBirth: string | undefined;
    englishProficiency: string | undefined;
    sex: string | undefined;
    maritalStatus: string | undefined;

    //- Physical
    eyeColor: string | undefined;
    hairColor: string | undefined;
    overallSize: string | undefined;
    height: number | undefined;
    weight: number | undefined;
    shoeSize: number | undefined;

    //- Education
    nameOfInstitution: string | undefined;
    cityOfCollege: string | undefined;
    countryOfCollegeId: number | undefined;
    educationSpeciality: string | undefined;
    educationStartDate: Date | undefined;
    graduationDate: Date | undefined;

    agentId: number | undefined;
    rankId: number | undefined;
  };
}

export interface ICreateSeafarer {
  id: string;

  name: string;
  surname: string;
  nationality: INationality;
  rank:
    | {
        id: string;
      }
    | undefined;

  //TODO? Remove?
  emails: ICommunication[];
  phones: ICommunication[];

  created: Date;
  updated: Date;
}

export interface ICreateSeafarerData {
  createSeafarer: ICreateSeafarer;
}

export const CREATE_SEAFARER = gql`
  mutation createSeafarer($data: SeafarerCreateInput!) {
    createSeafarer(data: $data) {
      id
      name
      surname
      nationality {
        id
        name
      }
      rank {
        id
      }

      emails {
        id
        value
        type {
          id
          name
        }
      }
      phones {
        id
        value
        type {
          id
          name
        }
      }

      created
      updated
    }
  }
`;
