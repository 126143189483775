import { gql } from "@apollo/client";
import { ICommunicationType } from "../";

export interface ICommunication {
  id: string;

  type: ICommunicationType;
  value: string;
  main: boolean;
  remarkCount?: number;
}

export interface ICommunicationsData {
  allCommunications?: ICommunication[];
}

export interface ICommunicationsVars {
  id: number;
}

export const ALL_COMMUNICATIONS = gql`
  query allCommunications($id: ID!) {
    allCommunications(id: $id) {
      id

      type {
        id
        name
      }
      value
      main
      remarkCount
    }
  }
`;

//_______________________________ ONE ADDRESS _______________________________//

export interface ICommunicationData {
  oneCommunication?: ICommunication;
}

export interface ICommunicationVars {
  id: number;
}

export const ONE_COMMUNICATION = gql`
  query oneCommunication($id: ID!) {
    oneCommunication(id: $id) {
      id

      type {
        id
        name
      }
      value
      main
    }
  }
`;
