import { FC } from "react";
import { useStyles } from "./SettingsNavbar.styles";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { NAVBAR_SETTINGS_DATA } from "../../../utils";

export const SettingsNavbar: FC = () => {
  const { classes, cx } = useStyles();

  return (
    <>
      <nav className={cx(classes.header, classes.navDiv)}>
        {NAVBAR_SETTINGS_DATA.map((item) => (
          <NavLink
            key={item.name}
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to={item.to}
            end
          >
            {item.name}
          </NavLink>
        ))}
      </nav>

      <Outlet />
    </>
  );
};
