import { gql } from "@apollo/client";
import { ICommunicationType } from "../../../queries";

export interface IDeleteCommunicationVars {
  id: number;
}

export interface IDeleteCommunication {
  id: string;
  type: ICommunicationType;
  value: string;
}

export interface IDeleteCommunicationData {
  deleteCommunication: IDeleteCommunication;
}

export const DELETE_COMMUNICATION = gql`
  mutation deleteCommunication($id: ID!) {
    deleteCommunication(id: $id) {
      id
      type {
        id
        name
      }
      value
    }
  }
`;
