import { gql } from "@apollo/client";
import { ICountry } from "../other/country.query";
import { INationality } from "../other/nationality.query";
import { ICommunication } from "./communication.query";

export interface ISeafarersList {
  id: string;

  name: string;
  surname: string;
  nationality: {
    id: string;
    name: string;
  };
  agent:
    | {
        nickname: string;
        name: string;
      }
    | undefined;

  rank:
    | {
        name: string;
      }
    | undefined;

  emails: ICommunication[];
  phones: ICommunication[];

  created: Date;
  updated: Date;
}

export interface ISeafarersListData {
  allSeafarers?: ISeafarersList[];
}

export interface ISeafarersListVars {
  cursor: number | undefined;
  filter?: {
    searchQuery: string | undefined;
    agentIds?: string[] | undefined;
    nationalityIds?: string[] | undefined;
    rankIds?: string[] | undefined;
  };
}

export const ALL_SEAFARERS = gql`
  query allSeafarers($cursor: Int, $filter: SeafarerFilterInput) {
    allSeafarers(cursor: $cursor, filter: $filter) {
      id

      name
      surname
      nationality {
        id
        name
      }
      agent {
        id
        nickname
      }
      rank {
        id
        name
      }
      emails {
        id
        value
        main
      }
      phones {
        id
        value
        main
      }

      created
      updated
    }
  }
`;

//______________________________________________________SEAFARER______________________________________________________//

export interface ISeafarer {
  id: string;

  //GENERAL
  name: string;
  surname: string;
  nationality: INationality;
  birthday: string;
  placeOfBirth: string | undefined;
  englishProficiency: string | undefined;
  sex: string | undefined;
  maritalStatus: string | undefined;

  //- Physical
  eyeColor: string | undefined;
  hairColor: string | undefined;
  overallSize: string | undefined;
  height: number | undefined;
  weight: number | undefined;
  shoeSize: number | undefined;

  //Education
  nameOfInstitution: string | undefined;
  cityOfCollege: string | undefined;
  countryOfCollege: ICountry | undefined;
  educationSpeciality: string | undefined;
  educationStartDate: Date | undefined;
  graduationDate: Date | undefined;

  //Other
  emails: ICommunication[];
  phones: ICommunication[];

  agent:
    | {
        id: string;
        nickname: string;
      }
    | undefined;

  rank:
    | {
        id: string;
        name: string;
      }
    | undefined;
  // created: Date;
  // updated: Date;
}

export interface ISeafarerData {
  oneSeafarer?: ISeafarer;
}

export interface ISeafarerVars {
  id: number;
}

export const ONE_SEAFARER = gql`
  query oneSeafarer($id: ID!) {
    oneSeafarer(id: $id) {
      id

      name
      surname
      nationality {
        id
        name
      }
      birthday
      placeOfBirth
      englishProficiency
      sex
      maritalStatus

      eyeColor
      hairColor
      overallSize
      height
      weight
      shoeSize

      nameOfInstitution
      cityOfCollege
      countryOfCollege {
        id
        name
      }
      educationSpeciality
      educationStartDate
      graduationDate

      emails {
        id
        value
        main
      }
      phones {
        id
        value
        main
        type {
          id
          name
        }
      }

      agent {
        id
        nickname
      }
      rank {
        id
        name
      }
    }
  }
`;
