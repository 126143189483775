import { FC, useContext } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Navbar, PageLayout } from "../components";
import {
  SeafarerProfileNavbar,
  SeafarersNavbar,
} from "../components/Navbar/components";
import { ContextProvider } from "../utils";
import {
  ForgotPasswordScreen,
  LoginScreen,
  ForgotPasswordConfirmScreen,
  NotFoundPage,
} from "./Public";
import { ParticularsScreen } from "./Private/SeafarerProfile/routes";
import { AgenciesPage, AgentsScreen } from "./Private/Settings/routes";
import { RanksScreen } from "./Private/Settings/routes/Ranks/RanksScreen";
import { SettingsNavbar } from "./Private/Settings/SettingsNavbar";
import { SeafarerProfileScreen, SeafarersScreen } from "./Private";
import { Typography } from "@mui/material";
import { UpsertRemarkDialog } from "../components/remarkList/components";
import { DeleteRemarkDialog } from "../components/remarkList/components/deleteRemarkDialog/DeleteRemarkDialog";
import { JoinScreen } from "./Public/Join/JoinScreen";

export enum ERouterPath {
  SEAFARERS = "seafarers",
  SEAFARER = "seafarers/:id",
  PARTICULARS = "particulars",

  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  FORGOT_PASSWORD_TOKEN = "forgot-password/:token",

  SETTINGS = "settings",
  RANKS = "ranks",
  AGENTS = "agents",
  AGENCY = "agencies",

  JOIN = "join",
}

const PrivateOutlet: FC = ({ children }) => {
  const { user } = useContext(ContextProvider);
  if (user?.id) {
    if (user.currentAgent) {
      return (
        <>
          {children}
          <Outlet />
        </>
      );
    }
    return (
      <>
        {children}
        <PageLayout marginTop="2x">
          <Typography variant="h1">
            You are not assigned to any organisation.
          </Typography>
          <Typography>
            Your agent status has either been expired or removed.
          </Typography>
        </PageLayout>
      </>
    );
  }
  return <Navigate to="/login" replace />;
};

const PublicOutlet: FC = ({ children }) => {
  const { user } = useContext(ContextProvider);
  return !user?.id ? (
    <>
      {children}
      <Outlet />
    </>
  ) : (
    <Navigate to="/" replace />
  );
};

export const MainRouter = () => {
  const { remarkDialogContext, setRemarkDialogContext } =
    useContext(ContextProvider);

  const handleCloseRemarkDialog = () => {
    setRemarkDialogContext((prev) => ({ ...prev, open: false }));
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateOutlet>
              <Navbar />
              <UpsertRemarkDialog
                open={
                  remarkDialogContext.open &&
                  remarkDialogContext.type === "upsert"
                }
                onClose={handleCloseRemarkDialog}
                remarkId={remarkDialogContext.id}
                idList={remarkDialogContext.idList}
              />
              <DeleteRemarkDialog
                id={remarkDialogContext.id}
                open={
                  remarkDialogContext.open &&
                  remarkDialogContext.type === "delete"
                }
                onClose={handleCloseRemarkDialog}
                description="Are you sure you want to delete this remark?"
                title="Delete remark"
              />
            </PrivateOutlet>
          }
        >
          <Route index element={<Navigate to={"/seafarers"} />} />
          <Route path={ERouterPath.SEAFARERS} element={<SeafarersNavbar />}>
            <Route index element={<SeafarersScreen />} />
          </Route>
          <Route
            path={ERouterPath.SEAFARER}
            element={<SeafarerProfileNavbar />}
          >
            <Route index element={<SeafarerProfileScreen />} />
            <Route
              path={ERouterPath.PARTICULARS}
              element={<ParticularsScreen />}
            />
          </Route>

          {/* Settings */}
          <Route path={ERouterPath.SETTINGS} element={<SettingsNavbar />}>
            <Route path={ERouterPath.RANKS} element={<RanksScreen />} />
            <Route path={ERouterPath.AGENTS} element={<AgentsScreen />} />
            <Route path={ERouterPath.AGENCY} element={<AgenciesPage />} />
            <Route path={"*"} element={<NotFoundPage />} />
          </Route>
          <Route path={"*"} element={<NotFoundPage />} />
        </Route>

        <Route
          path={ERouterPath.LOGIN}
          element={
            <PublicOutlet>
              <LoginScreen />
            </PublicOutlet>
          }
        />
        <Route
          path={ERouterPath.FORGOT_PASSWORD}
          element={
            <PublicOutlet>
              <ForgotPasswordScreen />
            </PublicOutlet>
          }
        />
        <Route
          path={ERouterPath.FORGOT_PASSWORD_TOKEN}
          element={
            <PublicOutlet>
              <ForgotPasswordConfirmScreen />
            </PublicOutlet>
          }
        />
        <Route path={ERouterPath.JOIN} element={<JoinScreen />} />
      </Routes>
    </BrowserRouter>
  );
};
