import { createContext, Dispatch, SetStateAction } from "react";
import { ICurrentUser } from "../apollo/queries";
import { IRemarkDialogContext } from "./constants";
export interface IContextProvider {
  user: ICurrentUser | undefined;
  setUser: Dispatch<SetStateAction<ICurrentUser | undefined>>;
  remarkDialogContext: IRemarkDialogContext;
  setRemarkDialogContext: Dispatch<SetStateAction<IRemarkDialogContext>>;
}

export const ContextProvider = createContext<IContextProvider>({
  user: undefined,
  setUser: () => {},
  remarkDialogContext: {
    open: false,
    idList: {},
    id: undefined,
  },
  setRemarkDialogContext: () => {},
});
