import { ChangeEvent, FC, SyntheticEvent, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { useStyles } from "./UpsertAddressDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertAddressDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../utils";
import {
  CREATE_ADDRESS,
  ICreateAddressData,
  ICreateAddressVars,
  IUpdateAddressData,
  IUpdateAddressVars,
  UPDATE_ADDRESS,
} from "../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../components";

import {
  ALL_ADDRESSES,
  ALL_COUNTRIES,
  IAddress,
  IAddressData,
  IAddressesData,
  IAddressVars,
  ICountriesData,
  ICountry,
  ONE_ADDRESS,
} from "../../../../../apollo/queries";

import { useParams } from "react-router";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  addressId: string | undefined;
  open: boolean;
};

type TProps = DialogProps & TDialogProps;

export const UpsertAddressDialog: FC<TProps> = (props) => {
  const { id } = useParams();
  const { onClose, addressId, open, ...rest } = props;
  const { classes, cx } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    setInputField,
    resetFields,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("main", event.target.checked ? "True" : "");
  };

  const [
    callAllCountriesQuery,
    { loading: loadingCountries, error: errorCountries, data: dataCountries },
  ] = useLazyQuery<ICountriesData, null>(ALL_COUNTRIES);

  useEffect(() => {
    if (open) {
      callAllCountriesQuery();
      if (addressId) {
        callOneAddressQuery({
          variables: {
            id: +addressId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, addressId]);

  const [callOneAddressQuery, { loading, error, data }] = useLazyQuery<
    IAddressData,
    IAddressVars
  >(ONE_ADDRESS, {
    onError: (err) => {
      console.log(err);
    },
  });

  const [createAddressMutation, { loading: loadingCreateAddress }] =
    useMutation<ICreateAddressData, ICreateAddressVars>(CREATE_ADDRESS, {
      onCompleted: () => {
        enqueueSnackbar("Added!", {
          variant: "success",
        });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        console.log({ error });
      },

      update: (cache, { data }) => {
        console.log(cache);
        const existingData: IAddressesData | null = cache.readQuery({
          query: ALL_ADDRESSES,
          variables: {
            id: id ? +id : undefined,
          },
        });
        if (data?.createAddress) {
          const newItem: IAddress = {
            ...data.createAddress,
            remarkCount: 0,
          };

          cache.writeQuery({
            query: ALL_ADDRESSES,
            variables: {
              id: id ? +id : undefined,
            },

            data: {
              allAddresses: existingData?.allAddresses
                ? [newItem, ...existingData.allAddresses]
                : [newItem],
            },
          });
        }
      },
    });

  const [updateAddressMutation, { loading: loadingUpdateAddress }] =
    useMutation<IUpdateAddressData, IUpdateAddressVars>(UPDATE_ADDRESS, {
      onCompleted: () => {
        enqueueSnackbar("Updated!", {
          variant: "success",
        });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
        console.log({ error });
      },
    });

  const handleCreateAddress = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid && id) {
      if (!addressId) {
        createAddressMutation({
          variables: {
            data: {
              city: inputFields.city.values.value,
              countryId: +inputFields.country.inputProps.value,
              main: inputFields.main.values.value === "True" ? true : false,
              nearestAirport:
                inputFields.nearestAirport.values.value || undefined,
              postalCode: inputFields.postalCode.values.value,
              region: handleDataToVar("region", "string", false),
              street: inputFields.street.values.value,
              street2: handleDataToVar("street2", "string", false),

              seafarerId: +id,
            },
          },
        });
      } else {
        updateAddressMutation({
          variables: {
            id: +addressId,
            data: {
              city: handleDataToVar("city", "string", false),
              countryId: handleDataToVar("country", "number", false),
              main: handleDataToVar("main", "boolean", false),
              nearestAirport: handleDataToVar("nearestAirport", "string"),
              postalCode: handleDataToVar("postalCode", "string", false),
              region: handleDataToVar("region", "string"),
              street: handleDataToVar("street", "string", false),
              street2: handleDataToVar("street2", "string"),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields((prev) => ({ ...prev, ...result.outputData }));
    }
  };

  useEffect(() => {
    if (addressId && data?.oneAddress) {
      console.log({ data });
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "street",
          toFormProperty: "street",
        },
        {
          fromDataProperty: "street2",
          toFormProperty: "street2",
        },
        {
          fromDataProperty: "postalCode",
          toFormProperty: "postalCode",
        },
        {
          fromDataProperty: "city",
          toFormProperty: "city",
        },
        {
          fromDataProperty: "region",
          toFormProperty: "region",
        },
        {
          fromDataProperty: "country.id",
          toFormProperty: "country",
        },
        {
          fromDataProperty: "nearestAirport",
          toFormProperty: "nearestAirport",
        },
        {
          fromDataProperty: "main",
          toFormProperty: "main",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneAddress, valueToFormOptions, inputFields);

      // if (data.oneAddress.main) {
      //   getUpdatedInputs.main.values.value = "True";
      // }
      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, addressId]);

  const filterOptionsCountries = createFilterOptions<ICountry>({
    limit: 50,
  });

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  const handleSetDateAndAutocompleteValue = (
    inputField: string,
    value: any
  ) => {
    setInputField(inputField as any, value ? value : "");
  };

  const onChangeCountry = (
    event: SyntheticEvent<Element, Event>,
    value: ICountry | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ICountry> | undefined
  ) => {
    handleSetDateAndAutocompleteValue("country", value?.id || "");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={handleDialogCleanup}
      {...rest}
    >
      <DialogTitle>
        {addressId ? "Edit address" : "Add new address"}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading || loadingCountries}
          error={Boolean(error || errorCountries)}
          hasData={Boolean(
            addressId
              ? data?.oneAddress?.id
              : dataCountries?.allCountries?.length
          )}
        >
          <TextField
            {...inputFields.street.inputProps}
            className={cx(classes.bigInput)}
            autoFocus
            margin="normal"
          />
          <TextField
            {...inputFields.street2.inputProps}
            className={cx(classes.bigInput)}
            margin="normal"
          />
          <TextField
            {...inputFields.postalCode.inputProps}
            className={cx(classes.bigInput)}
            margin="normal"
          />
          <TextField
            {...inputFields.city.inputProps}
            className={cx(classes.bigInput)}
            margin="normal"
          />
          <TextField
            {...inputFields.region.inputProps}
            className={cx(classes.bigInput)}
            margin="normal"
          />
          <Autocomplete
            options={
              dataCountries?.allCountries?.length
                ? dataCountries.allCountries
                : []
            }
            loading={loadingCountries}
            autoComplete
            filterOptions={filterOptionsCountries}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={onChangeCountry}
            value={
              dataCountries?.allCountries?.find(
                (x) => x.id === inputFields.country.inputProps.value
              ) || null
            }
            className={classes.bigInput}
            renderInput={(params) => (
              <TextField
                {...params}
                label={inputFields.country.inputProps.label}
                required={inputFields.country.inputProps.required}
                margin="normal"
              />
            )}
          />
          <TextField
            {...inputFields.nearestAirport.inputProps}
            className={cx(classes.bigInput)}
            margin="normal"
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(inputFields.main.inputProps.value)}
                  onChange={handleCheckboxChange}
                />
              }
              label={inputFields.main.inputProps.label}
            />
          </FormGroup>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateAddress}
        >
          {addressId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingCreateAddress || loadingUpdateAddress} />
    </Dialog>
  );
};
