import { FC } from "react";
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./ForgotPasswordScreen.styles";
import { useSnackbar } from "notistack";
import { Email as EmailIcon } from "@mui/icons-material";
import { useForm, validateForm } from "../../../utils";
import { initialInputData } from "./ForgotPasswordScreen.inputs";
import { useNavigate } from "react-router";
import { useMutation } from "@apollo/client";
import {
  CREATE_PERSON_PASSWORD_RESET_TOKEN,
  ICreatePersonPasswordResetTokenData,
  ICreatePersonPasswordResetTokenVars,
} from "../../../apollo/mutations";

export const ForgotPasswordScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const { inputFields, setInputFields } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [sendPasswordResetTokenMutation] = useMutation<
    ICreatePersonPasswordResetTokenData,
    ICreatePersonPasswordResetTokenVars
  >(CREATE_PERSON_PASSWORD_RESET_TOKEN, {
    onCompleted: (data) => {
      enqueueSnackbar(
        "Password reset link has been sent to your email. Please confirm the request by opening your email and clicking on the link.",
        {
          variant: "success",
          autoHideDuration: 8000,
        }
      );
      handleNavigateToLogin();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleSendForgotPasswordEmail = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      sendPasswordResetTokenMutation({
        variables: {
          data: {
            email: inputFields.email.values.value,
          },
        },
      });
    } else {
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
  };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph variant="h1">
            Forgot password
          </Typography>
          <Typography>
            Please enter your email so we can send you a reset password link.
          </Typography>
        </div>
        <TextField
          {...inputFields.email.inputProps}
          autoFocus
          margin="normal"
          autoComplete="username"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          className={cx(classes.button)}
          variant="outlined"
          fullWidth
          onClick={handleNavigateToLogin}
        >
          Back to log in
        </Button>
        <Button
          variant="contained"
          fullWidth
          className={cx(classes.button)}
          onClick={handleSendForgotPasswordEmail}
        >
          Send
        </Button>
      </Paper>
    </main>
  );
};
