import { gql } from "@apollo/client";

export interface IUpdateSeafarerVars {
  id: number;
  data: {
    name: string | undefined;
    surname: string | undefined;
    birthday: Date | undefined;
    // employedSince: Date | undefined;
    nationalityId: number | undefined;

    placeOfBirth: string | undefined | null;
    englishProficiency: string | undefined | null;
    sex: string | undefined | null;
    maritalStatus: string | undefined | null;

    //- Physical
    eyeColor: string | undefined | null;
    hairColor: string | undefined | null;
    overallSize: string | undefined | null;
    height: number | undefined | null;
    weight: number | undefined | null;
    shoeSize: number | undefined | null;

    //- Education
    nameOfInstitution: string | undefined | null;
    cityOfCollege: string | undefined | null;
    countryOfCollegeId: number | undefined | null;
    educationSpeciality: string | undefined | null;
    educationStartDate: Date | undefined | null;
    graduationDate: Date | undefined | null;

    agentId: number | undefined | null;
    rankId: number | undefined | null;
  };
}

export interface IUpdateSeafarer {
  id: string;
  name: string;
  surname: string;
  agent?: {
    id: string;
  };
  rank: {
    id: string;
  };
}

export interface IUpdateSeafarerData {
  updateSeafarer: IUpdateSeafarer;
}

export const UPDATE_SEAFARER = gql`
  mutation updateSeafarer($id: ID!, $data: SeafarerUpdateInput!) {
    updateSeafarer(id: $id, data: $data) {
      id
      name
      surname
      nationality {
        id
        name
      }
      birthday
      placeOfBirth
      englishProficiency
      sex
      maritalStatus

      eyeColor
      hairColor
      overallSize
      height
      weight
      shoeSize

      nameOfInstitution
      cityOfCollege
      countryOfCollege {
        id
        name
      }
      educationSpeciality
      educationStartDate
      graduationDate

      emails {
        id
        value
        main
      }
      phones {
        id
        value
        main
        type {
          id
          name
        }
      }

      agent {
        id
        nickname
      }
      rank {
        id
        name
      }
    }
  }
`;
