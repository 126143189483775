import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useParams } from "react-router";
import { ICommunication } from "../../../../../apollo/queries";
import { InputShowcase, RemarkList } from "../../../../../components";
import { useGlobalStyles } from "../../../../../styles";
import { useStyles } from "./CommunicationItem.styles";

interface IProps {
  data: ICommunication;
  handleEdit: () => void;
  handleDelete: () => void;
}

export const CommunicationItem: FC<IProps> = (props) => {
  const { id: seafarerId } = useParams();
  const { main, value, type, id, remarkCount } = props.data;
  const { handleDelete, handleEdit } = props;

  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (e: any, expanded: boolean) => {
    setExpanded(expanded);
  };

  return (
    <Accordion elevation={2} expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
        <Typography marginRight={4}>{value}</Typography>
        {main ? (
          <Chip
            className={globalClasses.chipMarginRight}
            size="small"
            color="secondary"
            label="Main"
          />
        ) : null}
        {remarkCount ? (
          <Chip size="small" color="info" label={`Remarks: ${remarkCount}`} />
        ) : null}
      </AccordionSummary>
      <AccordionDetails className={classes.root}>
        <InputShowcase label="Type" value={type.name} />
        <InputShowcase label="Value" value={value} />
        <div className={globalClasses.buttonDivRight}>
          <Button
            onClick={handleDelete}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
          <Button
            onClick={handleEdit}
            variant="outlined"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </div>
        <RemarkList
          compact
          skipQuery={!expanded}
          idList={{
            seafarerId: seafarerId ? +seafarerId : undefined,
            communicationId: +id,
          }}
          className={globalClasses.fullWidth}
          queryVariables={{
            seafarerId: seafarerId ? +seafarerId : undefined,
            communicationId: +id,
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
