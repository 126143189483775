import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { IActionMenuContext } from "../../utils";
import { useStyles } from "./PopperMenu.styles";

interface IProps {
  children?: any;
  actionMenu: IActionMenuContext;
  setActionMenu: Dispatch<SetStateAction<IActionMenuContext>>;
}

export const PopperMenu: FC<IProps> = (props) => {
  const { children, setActionMenu, actionMenu } = props;
  const { classes } = useStyles();

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    } else if (event.key === "Escape") {
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    }
  };

  const handleCloseActionMenu = (event: Event | React.SyntheticEvent) => {
    setActionMenu((prev) => {
      return {
        ...prev,
        open: false,
        id: undefined,
      };
    });
  };

  return (
    <Popper
      open={actionMenu.open}
      className={classes.popper}
      anchorEl={actionMenu.anchorEl}
      role={undefined}
      placement="right-end"
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "right bottom" : "right bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleCloseActionMenu}>
              <MenuList
                autoFocusItem={actionMenu.open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
              >
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
