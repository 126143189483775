import { Reference, useMutation, useQuery } from "@apollo/client";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, MouseEvent, useState } from "react";
import { ALL_AGENCIES, IAgenciesData } from "../../../../../apollo/queries";
import {
  DataHandlerComponent,
  DeleteDialog,
  LoadingBackdrop,
  PageLayout,
} from "../../../../../components";
import { UpsertAgencyDialog, AgencyItem } from "./components";

import { useStyles } from "./AgenciesPage.styles";
import { useSnackbar } from "notistack";
import {
  DELETE_AGENCY,
  IDeleteAgencyData,
  IDeleteAgencyVars,
} from "../../../../../apollo/mutations";

interface IDialog {
  open: boolean;
  id: undefined | string;
  type: "delete" | "upsert" | undefined;
}

interface IActionMenu {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export const AgenciesPage: FC = () => {
  const { classes, cx } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [agencyDialog, setAgencyDialog] = useState<IDialog>({
    id: undefined,
    open: false,
    type: undefined,
  });

  const [actionMenu, setActionMenu] = useState<IActionMenu>({
    id: undefined,
    open: false,
    anchorEl: null,
  });

  const { loading, error, data } = useQuery<IAgenciesData>(ALL_AGENCIES, {
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const [deleteAgencyMutation, { loading: loadingDeleteAgency }] = useMutation<
    IDeleteAgencyData,
    IDeleteAgencyVars
  >(DELETE_AGENCY, {
    onCompleted: (res) => {
      enqueueSnackbar(`${res.deleteAgency.name} deleted!`, {
        variant: "success",
      });
      handleCloseAgencyDialog();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allAgencies(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) => data.deleteAgency.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const handleDeleteAgencyMutation = () => {
    if (agencyDialog.id) {
      deleteAgencyMutation({
        variables: {
          id: +agencyDialog.id,
        },
      });
    }
  };

  const handleToggleActionMenu =
    (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setActionMenu((prev) => {
        return {
          open: prev.id !== id || !prev.open,
          anchorEl: event.currentTarget,
          id: id,
        };
      });
    };

  const handleOpenAgencyDialog = () => {
    setAgencyDialog({ open: true, id: undefined, type: "upsert" });
  };

  const handleOpenDeleteDialog = () => {
    setAgencyDialog({ open: true, id: actionMenu.id, type: "delete" });
  };

  const handleEditDialog = () => {
    handleCloseActionMenu();
    setAgencyDialog({ open: true, id: actionMenu.id, type: "upsert" });
  };

  const handleCloseAgencyDialog = () => {
    setAgencyDialog((prev) => ({ ...prev, open: false, id: undefined }));
  };

  const handleCloseActionMenu = (event?: Event | React.SyntheticEvent) => {
    setActionMenu((prev) => {
      return {
        ...prev,
        open: false,
        id: undefined,
      };
    });
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    } else if (event.key === "Escape") {
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    }
  }

  return (
    <PageLayout marginTop="2x">
      <div className={cx(classes.justify)}>
        <Typography variant="h1">Agencies</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAgencyDialog}
        >
          Add new agency
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={cx(classes.tableRow)}>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Street</TableCell>
              <TableCell align="left">Zip code</TableCell>
              <TableCell align="left">City</TableCell>
              <TableCell align="left">Country</TableCell>

              <TableCell width={64} align="left">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {data?.allAgencies?.length
              ? data.allAgencies.map((item) => {
                  return (
                    <AgencyItem
                      key={item.id}
                      data={item}
                      handleToggleActionMenu={handleToggleActionMenu(item.id)}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>

        <Popper
          open={actionMenu.open}
          anchorEl={actionMenu.anchorEl}
          role={undefined}
          placement="right-end"
          transition
          className={classes.popper}
          popperOptions={{
            modifiers: [
              {
                name: "hide",
                enabled: true,
              },
            ],
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start"
                    ? "right bottom"
                    : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseActionMenu}>
                  <MenuList
                    autoFocusItem={actionMenu.open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleEditDialog}>
                      <ListItemIcon>
                        <EditIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenDeleteDialog}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          hasData={Boolean(data?.allAgencies?.length)}
        />
      </TableContainer>
      <DeleteDialog
        title="Delete agency?"
        description="This is a permanent action"
        mutationCall={handleDeleteAgencyMutation}
        onClose={handleCloseAgencyDialog}
        open={agencyDialog.open && agencyDialog.type === "delete"}
      />
      <UpsertAgencyDialog
        agencyId={agencyDialog.id}
        onClose={handleCloseAgencyDialog}
        open={agencyDialog.open && agencyDialog.type === "upsert"}
      />
      <LoadingBackdrop loading={loadingDeleteAgency} />
    </PageLayout>
  );
};
