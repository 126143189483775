import { makeStyles } from "../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  header: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    height: 60,
    padding: "0 32px 0 32px",
    alignItems: "center",
    // position: "fixed",
    top: 60,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer + 1,
  },

  iconButtonInactive: {
    borderRadius: 4,
    marginLeft: 4,
    borderColor: theme.palette.grey[300],
    borderStyle: "solid",
    transition: "0.25s border-color ease-in-out",
    borderWidth: 1,
    "& svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.grey[300],
    },
    "&:hover svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      transition: "0.25s border-color ease-in-out",
      borderColor: theme.palette.primary.contrastText,
    },
  },

  iconButton: {
    borderRadius: 4,
    marginLeft: 4,
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.primary.main,
    borderStyle: "solid",
    transition: "0.25s border-color ease-in-out",
    borderWidth: 1,
    "& svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.grey[300],
    },
    "&:hover svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.primary.contrastText,
    },
    "&:hover": {
      transition: "0.25s border-color ease-in-out",
      borderColor: theme.palette.primary.contrastText,
    },
  },
  searchBar: {
    width: 256,
  },
}));
