import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  Avatar,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { FC, MouseEvent } from "react";
import { IRemark } from "../../../../apollo/queries";
import { useGlobalStyles } from "../../../../styles";
// import { useStyles } from "./RemarkItem.styles";

interface IProps {
  item: IRemark;
  compact?: boolean;
  handleToggleActionMenu: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const RemarkItem: FC<IProps> = (props) => {
  const {
    title,
    message,
    seafarerId,
    addressId,
    created,
    communicationId,
    agencyId,
    main,
  } = props.item;
  // const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  return (
    <ListItem divider alignItems="flex-start">
      <ListItemAvatar className={globalClasses.justifyBetween}>
        <Avatar src="https://i.pravatar.cc/64" />
      </ListItemAvatar>
      <div>
        {/* {!props.compact ? ( */}
        {/* <> */}
        {main ? (
          <Chip
            className={globalClasses.chipMarginRight}
            color="error"
            size="small"
            label="Main"
          />
        ) : null}
        {seafarerId ? (
          <Chip
            className={globalClasses.chipMarginRight}
            color="primary"
            size="small"
            label="Seafarer"
          />
        ) : null}

        {addressId ? (
          <Chip
            className={globalClasses.chipMarginRight}
            color="secondary"
            size="small"
            label="Address"
          />
        ) : null}
        {communicationId ? (
          <Chip
            className={globalClasses.chipMarginRight}
            color="info"
            size="small"
            label="Communication"
          />
        ) : null}
        {agencyId ? (
          <Chip
            className={globalClasses.chipMarginRight}
            color="success"
            size="small"
            label="Communication"
          />
        ) : null}
        {/* </>
        ) : null} */}
        <ListItemText secondary={message}>{title}</ListItemText>
        <Typography variant="caption">
          {format(new Date(created), "dd.MM.yyyy. HH:mm")}
        </Typography>
      </div>
      <ListItemSecondaryAction
      // className={classes.secondaryAction}
      >
        <IconButton onClick={props.handleToggleActionMenu}>
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
