import { gql } from "@apollo/client";
import { ICommunicationType } from "../../../queries";

export interface IUpdateCommunicationVars {
  data: {
    typeId: number | undefined;
    value: string | undefined;
    main: boolean | undefined;
  };
  id: number;
}

export interface IUpdateCommunication {
  id: string;

  type: ICommunicationType;
  value: string;
  main: boolean;
}

export interface IUpdateCommunicationData {
  updateCommunication: IUpdateCommunication;
}

export const UPDATE_COMMUNICATION = gql`
  mutation updateCommunication($data: CommunicationUpdateInput!, $id: ID!) {
    updateCommunication(data: $data, id: $id) {
      id

      type {
        id
        name
      }
      value
      main
    }
  }
`;
