import { createTypedDynamicFormInputs } from "../../../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    values: { value: "" },
    inputProps: {
      label: "Name",
      required: true,
    },
  },
  street: {
    values: { value: "" },
    inputProps: {
      label: "Street",
      required: true,
    },
  },
  zipCode: {
    values: { value: "" },
    inputProps: {
      label: "Zip code",
      required: true,
    },
  },
  city: {
    values: { value: "" },
    inputProps: {
      label: "City",
      required: true,
    },
  },
  country: {
    values: { value: "" },
    inputProps: {
      label: "Country",
      required: true,
    },
  },
});
