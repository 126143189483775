import { gql } from "@apollo/client";

export interface ISwitchOrganisationVars {
  id: number;
}

export interface ISwitchOrganisationData {
  switchOrganisation: {
    id: string;
    organisationId: string;
    organisation: {
      id: string;
      name: string;
    };
  };
}

export const SWITCH_ORGANISATION = gql`
  mutation switchOrganisation($id: ID!) {
    switchOrganisation(id: $id) {
      id
      organisationId
      organisation {
        id
        name
      }
    }
  }
`;
