import { gql } from "@apollo/client";

export interface IUpdateAgentVars {
  data: {
    nickname: string;
    roleId: number;
    agencyId: number | undefined;
  };
  id: number;
}

export interface IUpdateAgent {
  id: string;
  nickname: string;
  role: { id: string };
  agency: { id: string; name: string };
}

export interface IUpdateAgentData {
  updateAgent: IUpdateAgent;
}

export const UPDATE_AGENT = gql`
  mutation updateAgent($id: ID!, $data: PersonOrganisationRoleUpdateInput!) {
    updateAgent(id: $id, data: $data) {
      id
      nickname

      role {
        id
      }
      agency {
        id
        name
      }
    }
  }
`;
