import { FC } from "react";
import { Dialog, LinearProgress } from "@mui/material";
// import { useStyles } from "./LoadingBackdrop.styles";

interface IProps {
  loading: boolean;
}

export const LoadingBackdrop: FC<IProps> = (props) => {
  const { loading } = props;
  // const styles = useStyles();

  const PaperProps = {
    style: {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };

  return (
    <Dialog open={loading} fullScreen PaperProps={PaperProps}>
      <LinearProgress color="primary" />
    </Dialog>
  );
};
