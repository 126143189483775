import { gql } from "@apollo/client";
import { IRankGroup } from "./rankGroup.query";

export interface IRank {
  id: string;

  name: string;
  abbreviation: string;
  daysOnBoard: number;
  isOfficer: boolean;
  isPromotable: boolean;
  isWatchkeeper: boolean;

  rankGroup: IRankGroup;
}

export interface IRanksData {
  allRanks?: IRank[];
}

export interface IRanksVars {
  groupId?: number;
}

export const ALL_RANKS = gql`
  query allRanks($groupId: ID) {
    allRanks(groupId: $groupId) {
      id

      name
      abbreviation
      daysOnBoard
      isOfficer
      isPromotable
      isWatchkeeper

      rankGroup {
        id
        name
      }
    }
  }
`;

//_______________________________ ONE ADDRESS _______________________________//

export interface IRankData {
  oneRank?: IRank;
}

export interface IRankVars {
  id: number;
}

export const ONE_RANK = gql`
  query oneRank($id: ID!) {
    oneRank(id: $id) {
      id

      name
      abbreviation
      daysOnBoard
      isOfficer
      isPromotable
      isWatchkeeper

      rankGroup {
        id
        name
      }
    }
  }
`;
