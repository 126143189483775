import { FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { useStyles } from "./UpsertAgentDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertAgentDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../../../utils";
import {
  IInviteAgentData,
  IInviteAgentVars,
  INVITE_AGENT,
  IUpdateAgentData,
  IUpdateAgentVars,
  UPDATE_AGENT,
} from "../../../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
} from "../../../../../../../components";

import {
  ALL_ROLES,
  IAgentData,
  IAgentVars,
  IRolesData,
  ONE_AGENT,
  ALL_AGENCIES,
  IAgenciesData,
  IPersonInvitationsData,
  ALL_PERSON_INVITATIONS,
  IPersonInvitation,
} from "../../../../../../../apollo/queries";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  agentId: string | undefined;
  edit: boolean;
};

type TProps = DialogProps & TDialogProps;

export const UpsertAgentDialog: FC<TProps> = (props) => {
  const { open, edit, onClose, agentId, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { inputFields, setInputFields, resetFields } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [callOneAgentQuery, { loading, error, data }] = useLazyQuery<
    IAgentData,
    IAgentVars
  >(ONE_AGENT);

  const [
    callAllRolesQuery,
    { loading: loadingRoles, error: errorRoles, data: dataRoles },
  ] = useLazyQuery<IRolesData>(ALL_ROLES, {
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  const [
    callAllAgenciesQuery,
    { loading: loadingAgencies, error: errorAgencies, data: dataAgencies },
  ] = useLazyQuery<IAgenciesData>(ALL_AGENCIES, {
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  useEffect(() => {
    if (edit && agentId && open) {
      callOneAgentQuery({
        variables: {
          id: +agentId,
        },
      });
    } else {
      resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, open, edit]);

  useEffect(() => {
    if (open) {
      callAllRolesQuery();
      callAllAgenciesQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [inviteAgentMutation, { loading: loadingInviteAgent }] = useMutation<
    IInviteAgentData,
    IInviteAgentVars
  >(INVITE_AGENT, {
    onCompleted: () => {
      enqueueSnackbar("Agent invited!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log({ error });
    },

    update: (cache, { data }) => {
      const existingData: IPersonInvitationsData | null = cache.readQuery({
        query: ALL_PERSON_INVITATIONS,
      });
      if (data?.createAgent) {
        const newItem: IPersonInvitation = {
          id: data.createAgent.id,
          email: data.createAgent.person.email,
          nickname: data.createAgent.nickname,
          role: data.createAgent.role,
          agency: data.createAgent.agency,
          created: data.createAgent.created,
          expired: data.createAgent.expired,
          revoked: false,
        };
        cache.writeQuery({
          query: ALL_PERSON_INVITATIONS,
          data: {
            allPersonInvitations: existingData?.allPersonInvitations
              ? [newItem, ...existingData.allPersonInvitations]
              : [newItem],
          },
        });
      }
    },
  });

  const [updateAgentMutation, { loading: loadingUpdateAgent }] = useMutation<
    IUpdateAgentData,
    IUpdateAgentVars
  >(UPDATE_AGENT, {
    onCompleted: () => {
      enqueueSnackbar("Updated!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log({ error });
    },
  });

  const handleAgentMutation = () => {
    const fields = edit
      ? ["nickname", "role", "agency"]
      : Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!edit) {
        inviteAgentMutation({
          variables: {
            data: {
              nickname: inputFields.nickname.values.value,
              email: inputFields.email.values.value,
              roleId: +inputFields.role.values.value,
              agencyId: inputFields.agency.values.value
                ? +inputFields.agency.values.value
                : undefined,
            },
          },
        });
      } else if (agentId) {
        updateAgentMutation({
          variables: {
            id: +agentId,
            data: {
              nickname: inputFields.nickname.values.value,
              roleId: +inputFields.role.values.value,
              agencyId: inputFields.agency.values.value
                ? +inputFields.agency.values.value
                : undefined,
            },
          },
        });
      } else {
        enqueueSnackbar("No agent id!", {
          variant: "error",
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (agentId && edit && data?.oneAgent) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "nickname",
          toFormProperty: "nickname",
        },
        {
          fromDataProperty: "role.id",
          toFormProperty: "role",
        },
        {
          fromDataProperty: "agency.id",
          toFormProperty: "agency",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneAgent, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, agentId, edit]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle>{edit ? "Edit agent" : "Invite new agent"}</DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          hasData={Boolean(data?.oneAgent) || !Boolean(agentId)}
          skeletonNum={2}
        >
          <>
            <TextField
              {...inputFields.nickname.inputProps}
              className={classes.bigInput}
              autoFocus
              margin="normal"
            />

            <TextField
              {...inputFields.role.inputProps}
              select
              className={classes.bigInput}
              margin="normal"
            >
              {loadingRoles ? (
                <LoadingComponent small />
              ) : errorRoles ? (
                <ErrorComponent />
              ) : dataRoles?.allRoles?.length ? (
                dataRoles.allRoles.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  No data found
                </MenuItem>
              )}
            </TextField>

            <TextField
              {...inputFields.agency.inputProps}
              select
              className={classes.bigInput}
              margin="normal"
            >
              {loadingAgencies ? (
                <LoadingComponent small />
              ) : errorAgencies ? (
                <ErrorComponent />
              ) : dataAgencies?.allAgencies?.length ? (
                dataAgencies.allAgencies.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  No data found
                </MenuItem>
              )}
            </TextField>
            {!edit ? (
              <>
                <TextField
                  {...inputFields.email.inputProps}
                  className={classes.bigInput}
                  margin="normal"
                />
                <Typography>
                  An invite link will be sent to this email address.
                </Typography>
              </>
            ) : null}
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAgentMutation}
        >
          {edit ? "Update" : "Invite"}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingInviteAgent || loadingUpdateAgent} />
    </Dialog>
  );
};
