import { gql } from "@apollo/client";
import { ICommunicationType } from "../../../queries";

export interface ICreateCommunication {
  id: string;

  type: ICommunicationType;
  value: string;
  main: boolean;
}
export interface ICreateCommunicationData {
  createCommunication: ICreateCommunication;
}

export interface ICreateCommunicationVars {
  data: {
    typeId: number;
    value: string;
    main: boolean | undefined;

    seafarerId: number;
  };
}

export const CREATE_COMMUNICATION = gql`
  mutation createCommunication($data: CommunicationCreateInput!) {
    createCommunication(data: $data) {
      id

      type {
        id
        name
      }
      value
      main
    }
  }
`;
