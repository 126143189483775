import { Reference, useLazyQuery, useMutation } from "@apollo/client";
import { Add as AddIcon } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Paper, Tab, Typography } from "@mui/material";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import {
  DELETE_ADDRESS,
  DELETE_COMMUNICATION,
  IDeleteAddressData,
  IDeleteAddressVars,
  IDeleteCommunicationData,
  IDeleteCommunicationVars,
} from "../../../../../apollo/mutations";
import {
  ALL_ADDRESSES,
  ALL_COMMUNICATIONS,
  IAddressesData,
  IAddressesVars,
  ICommunication,
  ICommunicationsData,
  ICommunicationsVars,
  IRemarksVars,
  ISeafarer,
} from "../../../../../apollo/queries";
import {
  DataHandlerComponent,
  DeleteDialog,
  InputShowcase,
  LoadingBackdrop,
  RemarkList,
} from "../../../../../components";
import { useGlobalStyles } from "../../../../../styles";
import {
  ContextProvider,
  IDialogContext,
  joinClassNames,
  MSG_NO_ID_FOUND,
} from "../../../../../utils";
import {
  AddressItem,
  CommunicationItem,
  UpsertAddressDialog,
  UpsertCommunicationDialog,
} from "../../components";
import { useStyles } from "./ParticularsScreen.styles";

export const ParticularsScreen: FC = () => {
  const { user } = useContext(ContextProvider);
  const { data } = useOutletContext<{ data: ISeafarer }>();
  const { id: seafarerId } = useParams();
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [groupedCommunicationData, setGroupedCommunicationData] = useState<{
    [key: string]: ICommunication[];
  }>({});

  const [tabToShow, setTabToShow] = useState("1");
  const [addressDialog, setAddressDialog] = useState<IDialogContext>({
    id: undefined,
    open: false,
    type: "upsert",
  });
  const [communicationDialog, setCommunicationDialog] =
    useState<IDialogContext>({
      id: undefined,
      open: false,
      type: "upsert",
    });

  const remarkQueryVariables: IRemarksVars["filter"] = {
    seafarerId: seafarerId ? +seafarerId : undefined,
  };

  const [deleteAddressMutation, { loading: loadingDeleteAddress }] =
    useMutation<IDeleteAddressData, IDeleteAddressVars>(DELETE_ADDRESS, {
      onCompleted: (res) => {
        enqueueSnackbar(
          `Address deleted: - ${res.deleteAddress.street}, ${res.deleteAddress.city}`,
          {
            variant: "success",
          }
        );
        handleCloseAddressDialog();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            allAddresses(existingData: Array<Reference>, { readField }) {
              if (data) {
                return existingData.filter(
                  (taskRef) =>
                    data.deleteAddress.id !== readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    });

  const handleDeleteAddress = () => {
    if (!addressDialog.id) {
      enqueueSnackbar(MSG_NO_ID_FOUND, { variant: "error" });
      return null;
    }
    deleteAddressMutation({
      variables: {
        id: +addressDialog.id,
      },
    });
  };

  const [deleteCommunicationMutation, { loading: loadingDeleteCommunication }] =
    useMutation<IDeleteCommunicationData, IDeleteCommunicationVars>(
      DELETE_COMMUNICATION,
      {
        onCompleted: (res) => {
          enqueueSnackbar(
            `Communication deleted: - ${res.deleteCommunication.type.name}, ${res.deleteCommunication.value}`,
            {
              variant: "success",
            }
          );
          handleCloseCommunicationDialog();
        },
        onError: (err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              allCommunications(existingData: Array<Reference>, { readField }) {
                if (data) {
                  return existingData.filter(
                    (taskRef) =>
                      data.deleteCommunication.id !== readField("id", taskRef)
                  );
                }
              },
            },
          });
        },
      }
    );

  const handleDeleteCommunication = () => {
    if (!communicationDialog.id) {
      enqueueSnackbar(MSG_NO_ID_FOUND, { variant: "error" });
      return null;
    }
    deleteCommunicationMutation({
      variables: {
        id: +communicationDialog.id,
      },
    });
  };

  const handleCloseAddressDialog = () => {
    setAddressDialog({ id: undefined, open: false });
  };

  const handleOpenAddressDialog = (
    event?: any,
    id?: string | undefined,
    type?: "upsert" | "delete"
  ) => {
    setAddressDialog((prev) => ({
      ...prev,
      id: id,
      open: true,
      type: type ? type : "upsert",
    }));
  };

  const handleCloseCommunicationDialog = () => {
    setCommunicationDialog({ id: undefined, open: false });
  };

  const handleOpenCommunicationDialog = (
    event?: any,
    id?: string | undefined,
    type?: "upsert" | "delete"
  ) => {
    setCommunicationDialog((prev) => ({
      ...prev,
      id: id,
      open: true,
      type: type ? type : "upsert",
    }));
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabToShow(newValue);
  };

  const [
    callAllAddressesQuery,
    { loading: loadingAddresses, error: errorAddresses, data: dataAddresses },
  ] = useLazyQuery<IAddressesData, IAddressesVars>(ALL_ADDRESSES);

  const [
    callAllCommunicationsQuery,
    {
      loading: loadingCommunications,
      error: errorCommunications,
      data: dataCommunications,
    },
  ] = useLazyQuery<ICommunicationsData, ICommunicationsVars>(
    ALL_COMMUNICATIONS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (seafarerId) {
      callAllAddressesQuery({
        variables: {
          id: +seafarerId,
        },
      });
      callAllCommunicationsQuery({
        variables: {
          id: +seafarerId,
        },
      });
    }
  }, [
    seafarerId,
    callAllAddressesQuery,
    callAllCommunicationsQuery,
    user?.currentAgent?.organisationId,
  ]);

  useEffect(() => {
    if (dataCommunications?.allCommunications?.length) {
      const groupedData = dataCommunications.allCommunications.reduce(
        (acc, current) => ({
          ...acc,
          [current.type.name]: acc[current.type.name]
            ? [...acc[current.type.name], { ...current }]
            : [{ ...current }],
        }),
        {} as { [key: string]: ICommunication[] }
      );

      setGroupedCommunicationData(groupedData);
    }
  }, [dataCommunications]);

  return (
    <>
      <div className={classes.summaryDiv}>
        <TabContext value={tabToShow}>
          <TabList
            component={Paper}
            onChange={handleChangeTab}
            aria-label="lab API tabs example"
            variant="fullWidth"
            className={classes.tabDiv}
          >
            <Tab label="General" value="1" />
            <Tab label="Addresses" value="2" />
            <Tab label="Communications" value="3" />
            <Tab label="Bank Details" value="4" />
          </TabList>
          <Paper className={globalClasses.scrollablePaper}>
            <TabPanel
              //GENERAL
              value="1"
              className={classes.tabPanel}
            >
              <div className={classes.tabPanelContent}>
                <Typography
                  marginBottom={2}
                  sx={{ flexBasis: "100%" }}
                  variant="h6"
                >
                  General
                </Typography>
                <InputShowcase label="Name" value={data.name} />
                <InputShowcase label="Surname" value={data.surname} />
                <InputShowcase
                  label="Nationality"
                  value={data.nationality.name}
                />
                <InputShowcase label="Rank" value={data.rank?.name} />
                <InputShowcase
                  label="Date of birth"
                  value={format(new Date(data.birthday), "dd.MM.yyyy.")}
                />
                <InputShowcase
                  label="Place of birth"
                  value={data.placeOfBirth}
                />
                <InputShowcase
                  small
                  label="English proficiency"
                  value={data.englishProficiency}
                />
                <InputShowcase small label="Sex" value={data.sex} />
                <InputShowcase
                  small
                  label="Marital status"
                  value={data.maritalStatus}
                />
                <Typography
                  marginBottom={2}
                  sx={{ flexBasis: "100%" }}
                  variant="h6"
                >
                  Physical
                </Typography>
                <InputShowcase small label="Eye color" value={data.eyeColor} />
                <InputShowcase
                  small
                  label="Hair color"
                  value={data.hairColor}
                />
                <InputShowcase
                  small
                  label="Overall size"
                  value={data.overallSize}
                />
                <InputShowcase small label="Height (cm)" value={data.height} />
                <InputShowcase small label="Weight (kg)" value={data.weight} />
                <InputShowcase small label="Shoe size" value={data.shoeSize} />
                <Typography
                  marginBottom={2}
                  sx={{ flexBasis: "100%" }}
                  variant="h6"
                >
                  Education
                </Typography>
                <InputShowcase
                  label="Name of institution"
                  value={data.nameOfInstitution}
                />
                <InputShowcase
                  label="City of college"
                  value={data.cityOfCollege}
                />
                <InputShowcase
                  label="Country of college"
                  value={data.countryOfCollege?.name}
                />
                <InputShowcase
                  label="Education speciality"
                  value={data.educationSpeciality}
                />
                <InputShowcase
                  label="Education start date"
                  value={
                    data.educationStartDate
                      ? format(new Date(data.educationStartDate), "dd.MM.yyyy.")
                      : "N/A"
                  }
                />
                <InputShowcase
                  label="Graduation date"
                  value={
                    data.graduationDate
                      ? format(new Date(data.graduationDate), "dd.MM.yyyy.")
                      : "N/A"
                  }
                />
                <Typography
                  marginBottom={2}
                  sx={{ flexBasis: "100%" }}
                  variant="h6"
                >
                  Other
                </Typography>
                <InputShowcase label="Agent" value={data.agent?.nickname} />
              </div>
            </TabPanel>
            <TabPanel
              //ADDRESS
              value="2"
              className={classes.tabPanel}
            >
              <div
                className={joinClassNames([
                  globalClasses.justifyBetween,
                  globalClasses.marginBottom,
                ])}
              >
                <Typography variant="h6">Addresses</Typography>
                <Button
                  onClick={handleOpenAddressDialog}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Add
                </Button>
              </div>
              <DataHandlerComponent
                loading={loadingAddresses}
                error={Boolean(errorAddresses)}
                hasData={Boolean(dataAddresses?.allAddresses?.length)}
              >
                {dataAddresses?.allAddresses?.length
                  ? dataAddresses.allAddresses.map((item) => {
                      const handleEdit = () => {
                        handleOpenAddressDialog(undefined, item.id, "upsert");
                      };

                      const handleDelete = () => {
                        handleOpenAddressDialog(undefined, item.id, "delete");
                      };

                      return (
                        <AddressItem
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          key={item.id}
                          data={item}
                        />
                      );
                    })
                  : null}
              </DataHandlerComponent>
            </TabPanel>
            <TabPanel
              //COMMUNICATIONS
              value="3"
              className={classes.tabPanel}
            >
              <div
                className={joinClassNames([
                  globalClasses.justifyBetween,
                  globalClasses.marginBottom,
                ])}
              >
                <Typography variant="h6">Communications</Typography>
                <Button
                  onClick={handleOpenCommunicationDialog}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Add
                </Button>
              </div>
              <DataHandlerComponent
                loading={loadingCommunications}
                error={Boolean(errorCommunications)}
                hasData={Boolean(dataCommunications)}
              >
                {Object.entries(groupedCommunicationData).map(
                  ([key, value]) => {
                    return (
                      <Fragment key={key}>
                        <Typography variant="caption" marginBottom={2}>
                          {key}
                        </Typography>
                        {value.map((item) => {
                          const handleEdit = () => {
                            handleOpenCommunicationDialog(
                              undefined,
                              item.id,
                              "upsert"
                            );
                          };

                          const handleDelete = () => {
                            handleOpenCommunicationDialog(
                              undefined,
                              item.id,
                              "delete"
                            );
                          };

                          return (
                            <CommunicationItem
                              key={item.id}
                              data={item}
                              handleEdit={handleEdit}
                              handleDelete={handleDelete}
                            />
                          );
                        })}
                      </Fragment>
                    );
                  }
                )}
              </DataHandlerComponent>
            </TabPanel>
          </Paper>
        </TabContext>
        <UpsertAddressDialog
          open={addressDialog.open && addressDialog.type === "upsert"}
          addressId={addressDialog.id}
          onClose={handleCloseAddressDialog}
          maxWidth="md"
          fullWidth
        />
        <DeleteDialog
          open={
            (addressDialog.open && addressDialog.type === "delete") ||
            (communicationDialog.open && communicationDialog.type === "delete")
          }
          onClose={
            addressDialog.id
              ? handleCloseAddressDialog
              : handleCloseCommunicationDialog
          }
          mutationCall={
            addressDialog.id ? handleDeleteAddress : handleDeleteCommunication
          }
          title={addressDialog.id ? "Delete address?" : "Delete communication?"}
          description={`Are you sure you want to delete this ${
            addressDialog.id ? "address" : "communication"
          }?`}
          maxWidth="md"
          fullWidth
        />

        <UpsertCommunicationDialog
          open={
            communicationDialog.open && communicationDialog.type === "upsert"
          }
          communicationId={communicationDialog.id}
          onClose={handleCloseCommunicationDialog}
          maxWidth="md"
          fullWidth
        />
      </div>
      <Paper
        className={joinClassNames([
          globalClasses.scrollablePaper,
          classes.remarksPaper,
        ])}
      >
        <RemarkList
          idList={{
            seafarerId: seafarerId ? +seafarerId : undefined,
          }}
          queryVariables={remarkQueryVariables}
        />
      </Paper>
      <LoadingBackdrop
        loading={loadingDeleteAddress || loadingDeleteCommunication}
      />
    </>
  );
};
