import { useCallback, useState } from "react";
import { IMedia } from "../../apollo/queries";
import { IDynamicFormInitialInput, IDynamicFormInput } from "../formUtils";
import { useGlobalStyles } from "../../styles/globalStyles";
import { theme } from "../../styles/theme";
import { NonNullTypeNode } from "graphql";

export const useForm = <T extends string | number | symbol>(
  initialInputData: Record<T, IDynamicFormInitialInput>
) => {
  const { classes } = useGlobalStyles();

  const getInitialInputFields = useCallback(
    (inputData: Record<T, IDynamicFormInitialInput>, setState?: boolean) => {
      Object.keys(inputData).forEach((key) => {
        const thisKey = key as keyof typeof initialInputData;

        const isMediaRule = inputData[thisKey].rule === "media";
        const returnValueProperty = isMediaRule ? "media" : "value";

        inputData[thisKey].inputProps.onChange = (event: any) => {
          setInputFields((prevState) => {
            const isMediaRule = prevState[thisKey].rule === "media";
            const returnValueProperty = isMediaRule ? "media" : "value";
            const initialValues = prevState[thisKey].initialValues
              ? {
                  ...prevState[thisKey].initialValues,
                  hasChanged:
                    prevState[thisKey].initialValues?.value !==
                    event.target.value,
                }
              : undefined;

            const returnInputFields = {
              ...prevState,
              [key]: {
                ...prevState[thisKey],
                inputProps: {
                  ...prevState[thisKey].inputProps,
                  error: false,

                  InputProps: initialValues?.hasChanged
                    ? {
                        classes: {
                          root: classes.inputHasChanged,
                        },
                      }
                    : undefined,
                },
                values: {
                  ...prevState[thisKey].values,
                  value: isMediaRule
                    ? event.map((item: IMedia) => item.id).join(",")
                    : event.target.value,
                  media: isMediaRule ? event : undefined,
                },

                initialValues: initialValues,
              },
            };

            returnInputFields[thisKey].inputProps.value =
              returnInputFields[thisKey].values[returnValueProperty];

            returnInputFields[thisKey].inputProps.helperText =
              returnInputFields[thisKey].inputProps.error
                ? returnInputFields[thisKey].errorText
                : returnInputFields[thisKey].helperText;

            return returnInputFields;
          });
        };

        if (!setState) {
          if (!inputData[thisKey].values) {
            inputData[thisKey].values = {
              value: "",
              media: undefined,
            };
          }
        }

        //TODO: Check if defined
        inputData[thisKey].inputProps.value =
          inputData[thisKey].values![returnValueProperty];

        inputData[thisKey].inputProps.helperText = inputData[thisKey].inputProps
          .error
          ? inputData[thisKey].errorText
          : inputData[thisKey].helperText;
      });

      if (setState) {
        setInputFields(inputData as Record<T, IDynamicFormInput>);
        return inputData as Record<T, IDynamicFormInput>;
      } else {
        return inputData as Record<T, IDynamicFormInput>;
      }
    },
    []
  );

  const [inputFields, setInputFields] = useState<Record<T, IDynamicFormInput>>(
    () => getInitialInputFields(initialInputData, false)
  );

  const resetFields = (fieldsToReset?: T[]) => {
    if (fieldsToReset?.length) {
      let dataToClear = {} as Record<T, IDynamicFormInitialInput>;
      Object.keys(initialInputData).forEach((key) => {
        const thisKey = key as keyof typeof initialInputData;

        if (fieldsToReset.includes(thisKey)) {
          dataToClear[thisKey] = initialInputData[thisKey];
        }
      });

      getInitialInputFields({ ...inputFields, ...dataToClear }, true);
      return null;
    }
    getInitialInputFields(initialInputData, true);
  };

  const setInputField = (inputKey: T, value: any): void => {
    setInputFields((prevState) => {
      const isMediaRule = inputFields[inputKey].rule === "media";
      const returnValueProperty = isMediaRule ? "media" : "value";

      const initialValues = prevState[inputKey].initialValues
        ? {
            ...prevState[inputKey].initialValues,
            hasChanged: prevState[inputKey].initialValues?.value !== value,
          }
        : undefined;

      const toReturnData: Record<T, IDynamicFormInput> = {
        ...prevState,
        [inputKey]: {
          ...prevState[inputKey],
          inputProps: {
            ...prevState[inputKey].inputProps,
            error: false,
          },

          values: {
            ...prevState[inputKey].values,
            value: value,
          },

          initialValues: initialValues,
        },
      };

      toReturnData[inputKey].inputProps.value =
        toReturnData[inputKey].values[returnValueProperty];

      return toReturnData;
    });
  };
  //String
  function handleDataToVar(
    field: T,
    returnType: "string"
  ): string | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "string",
    nullable?: false
  ): string | undefined;
  //Number
  function handleDataToVar(
    field: T,
    returnType: "number"
  ): number | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "number",
    nullable?: false
  ): number | undefined;
  //Date
  function handleDataToVar(
    field: T,
    returnType: "date"
  ): Date | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "date",
    nullable?: false
  ): Date | undefined;
  //StringArray
  function handleDataToVar(
    field: T,
    returnType: "stringArray"
  ): string[] | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "stringArray",
    nullable?: false
  ): string[] | undefined;
  //Boolean
  function handleDataToVar(
    field: T,
    returnType: "boolean"
  ): boolean | undefined | null;
  function handleDataToVar(
    field: T,
    returnType: "boolean",
    nullable?: false
  ): boolean | undefined;

  function handleDataToVar(
    field: T,
    returnType: "number" | "boolean" | "stringArray" | "date" | "string",
    nullable: boolean = true
  ): number | string | string[] | Date | boolean | undefined | null {
    let returnValue = undefined;
    console.log("DATATOVAR: ", field, inputFields[field]);

    if (!inputFields[field].initialValues) {
      console.log("IT IS EMPTY OR NEW");
      if (!inputFields[field].values.value) {
        console.log("VALUE IS EMPTY");

        return undefined;
      }
      console.log("VALUE IS NEW");
      returnValue = inputFields[field].values.value;
    } else if (
      inputFields[field].values.value !==
      inputFields[field].initialValues?.value
    ) {
      console.log("IS AN UPDATED VALUE");
      if (nullable) {
        console.log("IS NULLABLE");
        if (inputFields[field].values.value) {
          console.log("IS NOT NULL");

          returnValue = inputFields[field].values.value;
        } else {
          console.log("IS NULL");
          returnValue = null;
        }
      } else {
        console.log("RETURN UPDATED VALUE");
        returnValue = inputFields[field].values.value;
      }
    } else {
      console.log("VALUE REMAINS SAME");
      return undefined;
    }

    //TODO: CHECK

    if (returnValue && returnType === "number") {
      console.log("RETURN NUMBER");
      return +returnValue;
    } else if (returnValue && returnType === "date") {
      console.log("FIX DATE");
    } else if (returnType === "boolean") {
      console.log("RETURN BOOLEAN");
      return Boolean(returnValue);
    } else if (returnType === "stringArray") {
      if (returnValue) {
        console.log("RETURN ARRAY");
        console.log("SPLIT: ", returnValue.split(","));
        return returnValue.split(",");
      } else {
        console.log("RETURN EMPTY ARRAY");

        return [];
      }
    }

    console.log("RETURN ELSE");
    return returnValue;
  }

  return {
    inputFields,
    setInputFields,
    resetFields,
    setInputField,
    handleDataToVar,
  } as const;
};
