import { gql } from "@apollo/client";

export interface ISeafarerToClipboardData {
  seafarerToClipboard?: string;
}

export interface ISeafarerToClipboardVars {
  id: number;
}

export const SEAFARER_TO_CLIPBOARD = gql`
  query seafarerToClipboard($id: ID!) {
    seafarerToClipboard(id: $id)
  }
`;
