import { useMutation, useQuery } from "@apollo/client";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DoDisturbOff as DoDisturbOffIcon,
  DoDisturbOn as DoDisturbOnIcon,
  Edit as EditIcon,
  ReplayOutlined as ReplayOutlinedIcon,
} from "@mui/icons-material";
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, MouseEvent, useState } from "react";
import {
  ALL_AGENTS,
  ALL_PERSON_INVITATIONS,
  IAgentsData,
  IAgentsVars,
  IPersonInvitation,
  IPersonInvitationsData,
  IPersonInvitationsVars,
} from "../../../../../apollo/queries";
import {
  DataHandlerComponent,
  LoadingBackdrop,
  PageLayout,
  SearchBar,
} from "../../../../../components";
import {
  UpsertAgentDialog,
  AgentItem,
  PersonInvitationItem,
} from "./components";

import { useStyles } from "./AgentsPage.styles";
import { useGlobalStyles } from "../../../../../styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  DEACTIVATE_OR_REACTIVATE_AGENT,
  IDeactivateOrReactivateAgentData,
  IDeactivateOrReactivateAgentVars,
  IResendPersonInvitationData,
  IResendPersonInvitationVars,
  IRevokePersonInvitationData,
  IRevokePersonInvitationVars,
  RESEND_PERSON_INVITATION,
  REVOKE_PERSON_INVITATION,
} from "../../../../../apollo/mutations";
import { useSnackbar } from "notistack";

interface IDialog {
  open: boolean;
  id: undefined | string;
  edit: boolean;
}

interface IActionMenu {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export const AgentsScreen: FC = () => {
  const { classes, cx } = useStyles();
  const { classes: globalClasses, cx: gcx } = useGlobalStyles();
  const [activeSearch, setActiveSearch] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [tabToShow, setTabToShow] = useState("1");
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeTabRemarksAndActivities = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    handleCloseActionMenu();
    handleCloseAgentDialog();
    setTabToShow(newValue);
  };

  const handleSetActiveSearch = (search: string) => {
    handleCloseActionMenu();
    handleCloseAgentDialog();
    setActiveSearch(search);
  };

  const handleChangeShowArchived = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    handleCloseActionMenu();
    handleCloseAgentDialog();
    setShowArchived(checked);
  };

  const [agentDialog, setAgentDialog] = useState<IDialog>({
    id: undefined,
    open: false,
    edit: false,
  });

  const [actionMenu, setActionMenu] = useState<IActionMenu>({
    id: undefined,
    open: false,
    anchorEl: null,
  });

  const handleToggleActionMenu =
    (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setActionMenu((prev) => {
        return {
          open: prev.id !== id || !prev.open,
          anchorEl: event.currentTarget,
          id: id,
        };
      });
    };

  const handleOpenAgentDialog = () => {
    setAgentDialog({ open: true, id: undefined, edit: false });
  };

  const handleEditDialog = () => {
    handleCloseActionMenu();
    setAgentDialog({ open: true, id: actionMenu.id, edit: true });
  };

  const handleCloseAgentDialog = () => {
    setAgentDialog((prev) => ({ ...prev, open: false, id: undefined }));
  };

  const handleCloseActionMenu = (event?: Event | React.SyntheticEvent) => {
    setActionMenu((prev) => {
      return {
        ...prev,
        open: false,
        id: undefined,
      };
    });
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    } else if (event.key === "Escape") {
      setActionMenu({
        open: false,
        id: undefined,
        anchorEl: null,
      });
    }
  }

  const { loading, error, data } = useQuery<IAgentsData, IAgentsVars>(
    ALL_AGENTS,
    {
      variables: {
        search: activeSearch,
        showArchived: showArchived,
      },
    }
  );

  const {
    loading: loadingInvitations,
    error: errorInvitations,
    data: dataInvitations,
  } = useQuery<IPersonInvitationsData, IPersonInvitationsVars>(
    ALL_PERSON_INVITATIONS,
    {
      variables: {
        search: activeSearch,
        showArchived: showArchived,
      },
    }
  );

  const [
    revokePersonInvitationMutation,
    { loading: loadingRevokePersonInvitation },
  ] = useMutation<IRevokePersonInvitationData, IRevokePersonInvitationVars>(
    REVOKE_PERSON_INVITATION,
    {
      onCompleted: () => {
        enqueueSnackbar("Invitation has been revoked!", {
          variant: "success",
        });
        handleCloseActionMenu();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
    }
  );

  const [
    deactivateOrReactivateAgentMutation,
    { loading: loadingDeactivateOrReactivateAgentMutation },
  ] = useMutation<
    IDeactivateOrReactivateAgentData,
    IDeactivateOrReactivateAgentVars
  >(DEACTIVATE_OR_REACTIVATE_AGENT, {
    onCompleted: (data) => {
      enqueueSnackbar(
        data.deactivateOrReactivateAgent.deactivated
          ? "Agent has been deactivated!"
          : "Agent has been deactivated!",
        {
          variant: "success",
        }
      );
      handleCloseActionMenu();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [
    resendPersonInvitationMutation,
    { loading: loadingResendPersonInvitation },
  ] = useMutation<IResendPersonInvitationData, IResendPersonInvitationVars>(
    RESEND_PERSON_INVITATION,
    {
      onCompleted: () => {
        enqueueSnackbar("Invitation has been sent again!", {
          variant: "success",
        });
        handleCloseActionMenu();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      },
      //TODO: FIX CACHE
      update: (cache, { data }) => {
        const existingData: IPersonInvitationsData | null = cache.readQuery({
          query: ALL_PERSON_INVITATIONS,
        });
        if (data?.resendPersonInvitation?.length) {
          const newItem: IPersonInvitation = {
            id: data.resendPersonInvitation[0].id,
            email: data.resendPersonInvitation[0].email,
            nickname: data.resendPersonInvitation[0].nickname,
            role: data.resendPersonInvitation[0].role,
            agency: data.resendPersonInvitation[0].agency,
            created: data.resendPersonInvitation[0].created,
            expired: data.resendPersonInvitation[0].expired,
            revoked: false,
          };
          cache.writeQuery({
            query: ALL_PERSON_INVITATIONS,
            data: {
              allPersonInvitations: existingData?.allPersonInvitations
                ? [newItem, ...existingData.allPersonInvitations]
                : [newItem],
            },
          });
        }
      },
    }
  );

  const handleDeactivateAgent = () => {
    if (actionMenu.id) {
      deactivateOrReactivateAgentMutation({
        variables: {
          id: +actionMenu.id,
          deactivated: true,
        },
      });
    }
  };

  const handleReactivateAgent = () => {
    if (actionMenu.id) {
      deactivateOrReactivateAgentMutation({
        variables: {
          id: +actionMenu.id,
          deactivated: false,
        },
      });
    }
  };

  const handleRevokeInvitation = () => {
    if (actionMenu.id) {
      revokePersonInvitationMutation({
        variables: {
          id: +actionMenu.id,
        },
      });
    }
  };
  const handleResendInvitation = () => {
    if (actionMenu.id) {
      resendPersonInvitationMutation({
        variables: {
          id: +actionMenu.id,
        },
      });
    }
  };

  return (
    <PageLayout marginTop="2x">
      <div className={cx(classes.justify)}>
        <Typography variant="h1">Agents</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAgentDialog}
        >
          Invite new Agent
        </Button>
      </div>
      <TabContext value={tabToShow}>
        <TabList
          component={Paper}
          onChange={handleChangeTabRemarksAndActivities}
          aria-label="lab API tabs example"
          variant="fullWidth"
          className={classes.tabDiv}
        >
          <Tab label="Agents" value="1" />
          <Tab label="Invites" value="2" />
        </TabList>

        <div
          className={gcx(
            globalClasses.marginBottom,
            globalClasses.justifyBetween
          )}
        >
          <SearchBar
            label="Search agent"
            classes={{
              root: classes.searchBarBackground,
            }}
            className={cx(classes.searchBar)}
            activeSearch={activeSearch}
            onSearch={handleSetActiveSearch}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={showArchived}
                onChange={handleChangeShowArchived}
                name="showArchived"
                color="primary"
              />
            }
            label="Show archived agents"
          />
        </div>

        <TableContainer component={Paper}>
          <TabPanel value="1" className={classes.tabPanel}>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={cx(classes.tableRow)}>
                  <TableCell align="left">Display name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Agency</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Status</TableCell>

                  <TableCell width={64} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {data?.allAgents?.length
                  ? data.allAgents.map((item) => {
                      return (
                        <AgentItem
                          key={item.id}
                          data={item}
                          handleToggleActionMenu={handleToggleActionMenu(
                            item.id
                          )}
                        />
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TabPanel>

          <TabPanel value="2" className={classes.tabPanel}>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={cx(classes.tableRow)}>
                  <TableCell align="left">Nickname</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Agency</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Invited</TableCell>

                  <TableCell width={64} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                {dataInvitations?.allPersonInvitations?.length
                  ? dataInvitations?.allPersonInvitations.map((item) => {
                      return (
                        <PersonInvitationItem
                          key={item.id}
                          data={item}
                          handleToggleActionMenu={handleToggleActionMenu(
                            item.id
                          )}
                        />
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TabPanel>

          <Popper
            open={actionMenu.open}
            anchorEl={actionMenu.anchorEl}
            role={undefined}
            placement="right-end"
            transition
            className={classes.popper}
            popperOptions={{
              modifiers: [
                {
                  name: "hide",
                  enabled: true,
                },
              ],
            }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start"
                      ? "right bottom"
                      : "right bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseActionMenu}>
                    <MenuList
                      autoFocusItem={actionMenu.open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {tabToShow === "1"
                        ? [
                            <MenuItem key={"edit"} onClick={handleEditDialog}>
                              <ListItemIcon>
                                <EditIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Edit</ListItemText>
                            </MenuItem>,
                            data?.allAgents?.length &&
                            data.allAgents.find((x) => x.id === actionMenu.id)
                              ?.deactivated ? (
                              <MenuItem onClick={handleReactivateAgent}>
                                <ListItemIcon>
                                  <DoDisturbOnIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Reactivate</ListItemText>
                              </MenuItem>
                            ) : (
                              <MenuItem onClick={handleDeactivateAgent}>
                                <ListItemIcon>
                                  <DoDisturbOffIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Deactivate</ListItemText>
                              </MenuItem>
                            ),
                          ]
                        : [
                            <MenuItem
                              key={"resend"}
                              onClick={handleResendInvitation}
                            >
                              <ListItemIcon>
                                <ReplayOutlinedIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Resend invitation</ListItemText>
                            </MenuItem>,
                            <MenuItem
                              key={"revoke"}
                              onClick={handleRevokeInvitation}
                            >
                              <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>Revoke invitation</ListItemText>
                            </MenuItem>,
                          ]}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </TableContainer>
      </TabContext>
      <DataHandlerComponent
        loading={tabToShow === "1" ? loading : loadingInvitations}
        error={Boolean(tabToShow === "1" ? error : errorInvitations)}
        hasData={Boolean(
          tabToShow === "1"
            ? data?.allAgents
            : dataInvitations?.allPersonInvitations
        )}
      />

      <UpsertAgentDialog
        edit={agentDialog.edit}
        agentId={agentDialog.id}
        onClose={handleCloseAgentDialog}
        open={agentDialog.open}
      />
      <LoadingBackdrop
        loading={loadingResendPersonInvitation || loadingRevokePersonInvitation}
      />
    </PageLayout>
  );
};
