import { gql } from "@apollo/client";

export interface IRankGroup {
  id: string;
  name: string;
}

export interface IRankGroupsData {
  allRankGroups?: IRankGroup[];
}

export interface IRankGroupsVars {
  id: number;
}

export const ALL_RANK_GROUPS = gql`
  query allRankGroups {
    allRankGroups {
      id

      name
    }
  }
`;

//_______________________________ ONE RANK GROUP _______________________________//

export interface IRankGroupData {
  oneRankGroup?: IRankGroup;
}

export interface IRankGroupVars {
  id: number;
}

export const ONE_RANK_GROUP = gql`
  query oneRankGroup($id: ID!) {
    oneRankGroup(id: $id) {
      id
      name
    }
  }
`;
