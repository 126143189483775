import { FC, SyntheticEvent, useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  Tab,
  DialogProps,
  TextField,
  InputAdornment,
  MenuItem,
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  createFilterOptions,
} from "@mui/material";
import { useStyles } from "./UpsertSeafarerDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertSeafarerDialog.inputs";
import { DatePicker } from "@mui/x-date-pickers";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../utils";
import {
  CREATE_SEAFARER,
  ICreateSeafarerData,
  ICreateSeafarerVars,
  IUpdateSeafarerData,
  IUpdateSeafarerVars,
  UPDATE_SEAFARER,
} from "../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../components";
import {
  ALL_AGENTS,
  IAgentsData,
  IRanksData,
  IRanksVars,
  ALL_RANKS,
  INationalitiesData,
  ALL_NATIONALITIES,
  INationality,
  ALL_COUNTRIES,
  ICountriesData,
  ICountry,
  ISeafarerData,
  ISeafarerVars,
  ONE_SEAFARER,
} from "../../../../../apollo/queries";
import { useGlobalStyles } from "../../../../../styles";

type TDialogProps = {
  searchQuery?: string;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  seafarerId?: string;
  refetch?: any;
};

type TProps = DialogProps & TDialogProps;

export const UpsertSeafarerDialog: FC<TProps> = (props) => {
  const { searchQuery, onClose, open, seafarerId, refetch, ...rest } = props;
  const { classes, cx } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    setInputField,
    handleDataToVar,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [tabToShow, setTabToShow] = useState("1");

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTabToShow(newValue);
  };

  const [createSeafarer, { loading: loadingCreateSeafarer }] = useMutation<
    ICreateSeafarerData,
    ICreateSeafarerVars
  >(CREATE_SEAFARER, {
    onCompleted: (res) => {
      enqueueSnackbar(
        `Seafarer added: - ${res.createSeafarer.name} ${res.createSeafarer.surname} -`,
        {
          variant: "success",
        }
      );
      refetch?.();
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [updateSeafarer, { loading: loadingUpdateSeafarer }] = useMutation<
    IUpdateSeafarerData,
    IUpdateSeafarerVars
  >(UPDATE_SEAFARER, {
    onCompleted: (res) => {
      enqueueSnackbar(
        `Updated seafarer: - ${res.updateSeafarer.name} ${res.updateSeafarer.surname} -`,
        {
          variant: "success",
        }
      );
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const [callOneSeafarerQuery, { loading, error, data }] = useLazyQuery<
    ISeafarerData,
    ISeafarerVars
  >(ONE_SEAFARER);

  const [
    callAllNationalitiesQuery,
    {
      loading: loadingNationalities,
      error: errorNationalities,
      data: dataNationalities,
    },
  ] = useLazyQuery<INationalitiesData, null>(ALL_NATIONALITIES);

  const [
    callAllCountriesQuery,
    { loading: loadingCountries, error: errorCountries, data: dataCountries },
  ] = useLazyQuery<ICountriesData, null>(ALL_COUNTRIES);

  const [
    callAllRanksQuery,
    { loading: loadingRanks, error: errorRanks, data: dataRanks },
  ] = useLazyQuery<IRanksData, IRanksVars>(ALL_RANKS);

  const [
    callAllAgentsQuery,
    { loading: loadingAgents, data: dataAgents, error: errorAgents },
  ] = useLazyQuery<IAgentsData>(ALL_AGENTS);

  useEffect(() => {
    if (open) {
      if (seafarerId) {
        callOneSeafarerQuery({
          variables: {
            id: +seafarerId,
          },
        });
      }
      callAllRanksQuery();
      callAllAgentsQuery();
      callAllNationalitiesQuery();
      callAllCountriesQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, seafarerId]);

  useEffect(() => {
    console.log("USE EFFECT");
    if (open && seafarerId && data?.oneSeafarer) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "surname",
          toFormProperty: "surname",
        },
        {
          fromDataProperty: "birthday",
          toFormProperty: "birthday",
        },
        {
          fromDataProperty: "placeOfBirth",
          toFormProperty: "placeOfBirth",
        },
        {
          fromDataProperty: "nationality.id",
          toFormProperty: "nationality",
        },
        {
          fromDataProperty: "englishProficiency",
          toFormProperty: "englishProficiency",
        },
        {
          fromDataProperty: "sex",
          toFormProperty: "sex",
        },
        {
          fromDataProperty: "maritalStatus",
          toFormProperty: "maritalStatus",
        },
        {
          fromDataProperty: "eyeColor",
          toFormProperty: "eyeColor",
        },
        {
          fromDataProperty: "hairColor",
          toFormProperty: "hairColor",
        },
        {
          fromDataProperty: "overallSize",
          toFormProperty: "overallSize",
        },
        {
          fromDataProperty: "height",
          toFormProperty: "height",
        },
        {
          fromDataProperty: "weight",
          toFormProperty: "weight",
        },
        {
          fromDataProperty: "shoeSize",
          toFormProperty: "shoeSize",
        },
        {
          fromDataProperty: "nameOfInstitution",
          toFormProperty: "nameOfInstitution",
        },
        {
          fromDataProperty: "cityOfCollege",
          toFormProperty: "cityOfCollege",
        },
        {
          fromDataProperty: "countryOfCollege.id",
          toFormProperty: "countryOfCollege",
        },
        {
          fromDataProperty: "educationSpeciality",
          toFormProperty: "educationSpeciality",
        },
        {
          fromDataProperty: "educationStartDate",
          toFormProperty: "educationStartDate",
        },
        {
          fromDataProperty: "graduationDate",
          toFormProperty: "graduationDate",
        },
        {
          fromDataProperty: "agent.id",
          toFormProperty: "agent",
        },
        {
          fromDataProperty: "rank.id",
          toFormProperty: "rank",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneSeafarer, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, seafarerId, open]);

  const handleSetDateAndAutocompleteValue = (
    inputField: string,
    value: any
  ) => {
    setInputField(inputField as any, value ? value : "");
  };

  const onChangeBirthday = (value: Date | null) => {
    handleSetDateAndAutocompleteValue("birthday", value);
  };

  const onChangeGraduationDate = (value: Date | null) => {
    handleSetDateAndAutocompleteValue("graduationDate", value);
  };

  const onChangeEducationStartDate = (value: Date | null) => {
    handleSetDateAndAutocompleteValue("educationStartDate", value);
  };

  const onChangeAvailableFrom = (value: Date | null) => {
    handleSetDateAndAutocompleteValue("availableFrom", value);
  };

  const onChangeNationality = (
    event: SyntheticEvent<Element, Event>,
    value: INationality | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<INationality> | undefined
  ) => {
    handleSetDateAndAutocompleteValue("nationality", value?.id || "");
  };

  const onChangeCountryOfCollege = (
    event: SyntheticEvent<Element, Event>,
    value: ICountry | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ICountry> | undefined
  ) => {
    handleSetDateAndAutocompleteValue("countryOfCollege", value?.id || "");
  };

  const handleCreateSeafarer = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!seafarerId) {
        createSeafarer({
          variables: {
            data: {
              name: inputFields.name.inputProps.value,
              surname: inputFields.surname.inputProps.value,
              birthday: inputFields.birthday.inputProps.value,
              nationalityId: +inputFields.nationality.inputProps.value,

              // employedSince: inputFields..values.value as Date,
              placeOfBirth: handleDataToVar("placeOfBirth", "string", false),
              englishProficiency: handleDataToVar(
                "englishProficiency",
                "string",
                false
              ),
              sex: handleDataToVar("sex", "string", false),
              maritalStatus: handleDataToVar("maritalStatus", "string", false),

              //- Physical
              eyeColor: handleDataToVar("eyeColor", "string", false),
              hairColor: handleDataToVar("hairColor", "string", false),
              overallSize: handleDataToVar("overallSize", "string", false),
              shoeSize: handleDataToVar("shoeSize", "number", false),
              height: handleDataToVar("height", "number", false),
              weight: handleDataToVar("weight", "number", false),

              //- Education
              nameOfInstitution: handleDataToVar(
                "nameOfInstitution",
                "string",
                false
              ),
              cityOfCollege: handleDataToVar("cityOfCollege", "string", false),
              countryOfCollegeId: handleDataToVar(
                "countryOfCollege",
                "number",
                false
              ),
              educationSpeciality: handleDataToVar(
                "educationSpeciality",
                "string",
                false
              ),
              educationStartDate: handleDataToVar(
                "educationStartDate",
                "date",
                false
              ),
              graduationDate: handleDataToVar("graduationDate", "date", false),

              // profileImage: inputFields.profie.values.value || undefined,
              agentId: handleDataToVar("agent", "number", false),
              rankId: handleDataToVar("rank", "number", false),
            },
          },
        });
      } else {
        updateSeafarer({
          variables: {
            id: +seafarerId,
            data: {
              name: handleDataToVar("name", "string", false),
              surname: handleDataToVar("surname", "string", false),
              birthday: handleDataToVar("birthday", "date", false),
              nationalityId: handleDataToVar("nationality", "number", false),

              // employedSince: inputFields..values.value as Date,
              placeOfBirth: handleDataToVar("placeOfBirth", "string"),
              englishProficiency: handleDataToVar(
                "englishProficiency",
                "string"
              ),
              sex: handleDataToVar("sex", "string"),
              maritalStatus: handleDataToVar("maritalStatus", "string"),

              //- Physical
              eyeColor: handleDataToVar("eyeColor", "string"),
              hairColor: handleDataToVar("hairColor", "string"),
              overallSize: handleDataToVar("overallSize", "string"),
              shoeSize: handleDataToVar("shoeSize", "number"),
              height: handleDataToVar("height", "number"),
              weight: handleDataToVar("weight", "number"),

              //- Education
              nameOfInstitution: handleDataToVar("nameOfInstitution", "string"),
              cityOfCollege: handleDataToVar("cityOfCollege", "string"),
              countryOfCollegeId: handleDataToVar("countryOfCollege", "number"),
              educationSpeciality: handleDataToVar(
                "educationSpeciality",
                "string"
              ),
              educationStartDate: handleDataToVar("educationStartDate", "date"),
              graduationDate: handleDataToVar("graduationDate", "date"),

              // profileImage: inputFields.profie.values.value || undefined,
              agentId: handleDataToVar("agent", "number"),
              rankId: handleDataToVar("rank", "number"),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  // console.log(inputFields.birthday);

  const filterOptionsNationality = createFilterOptions<INationality>({
    limit: 50,
  });

  const filterOptionsCountries = createFilterOptions<ICountry>({
    limit: 50,
  });

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      {...rest}
      TransitionProps={handleDialogCleanup}
    >
      <DialogTitle className={globalClasses.disableDialogTitleMargin}>
        {seafarerId ? "Edit seafarer" : "Add new seafarer"}
      </DialogTitle>
      <TabContext value={tabToShow}>
        <TabList onChange={handleChangeTab} variant="fullWidth">
          <Tab label="General" value="1" />
          <Tab label="Physical" value="2" />
          <Tab label="Education" value="3" />
          <Tab label="Other" value="4" />
        </TabList>
        <DialogContent className={classes.content}>
          <DataHandlerComponent
            error={Boolean(
              errorAgents ||
                errorNationalities ||
                errorRanks ||
                errorCountries ||
                error
            )}
            loading={
              loadingAgents ||
              loadingNationalities ||
              loadingAgents ||
              loadingCountries ||
              loadingRanks ||
              loading
            }
            hasData={seafarerId ? Boolean(data?.oneSeafarer?.id) : true}
          >
            <TabPanel value="1" className={cx(classes.tabPanel)}>
              <TextField
                {...inputFields.name.inputProps}
                className={cx(classes.bigInput)}
                autoCapitalize="words"
                autoFocus
                margin="normal"
              />
              <TextField
                {...inputFields.surname.inputProps}
                className={cx(classes.bigInput)}
                margin="normal"
                autoCapitalize="words"
              />

              <DatePicker
                label="Birthday"
                value={inputFields.birthday.values.value || null}
                onChange={onChangeBirthday}
                disableFuture
                renderInput={(props) => (
                  <TextField
                    {...inputFields.birthday.inputProps}
                    {...props}
                    className={cx(classes.smallInput)}
                    margin="normal"
                  />
                )}
              />

              <Autocomplete
                options={
                  dataNationalities?.allNationalities?.length
                    ? dataNationalities.allNationalities
                    : []
                }
                loading={loadingNationalities}
                autoComplete
                filterOptions={filterOptionsNationality}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={onChangeNationality}
                value={dataNationalities?.allNationalities?.find(
                  (x) => x.id === inputFields.nationality.inputProps.value
                )}
                className={classes.smallInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ required: true }}
                    label={inputFields.nationality.inputProps.label}
                    margin="normal"
                  />
                )}
              />

              <TextField
                {...inputFields.rank.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                select
              >
                <MenuItem value="">-- Choose option --</MenuItem>
                {dataRanks?.allRanks?.length ? (
                  dataRanks.allRanks.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled value="">
                    No data found
                  </MenuItem>
                )}
              </TextField>

              <TextField
                {...inputFields.placeOfBirth.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                autoCapitalize="on"
              />

              <TextField
                {...inputFields.englishProficiency.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                select
              >
                <MenuItem value="">--Choose option--</MenuItem>
                <MenuItem value="TBC">TBC</MenuItem>
                <MenuItem value="Poor">Poor</MenuItem>
                <MenuItem value="Fair">Fair</MenuItem>
                <MenuItem value="Good">Good</MenuItem>
              </TextField>
              <TextField
                {...inputFields.sex.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                select
              >
                <MenuItem value="">--Choose option--</MenuItem>
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </TextField>
              <TextField
                {...inputFields.maritalStatus.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                select
              >
                <MenuItem value="">--Choose option--</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Divorced">Divorced</MenuItem>
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Separated">Separated</MenuItem>
                <MenuItem value="Widowed">Widowed</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
              </TextField>
            </TabPanel>
            <TabPanel value="2" className={cx(classes.tabPanel)}>
              <TextField
                {...inputFields.eyeColor.inputProps}
                className={cx(classes.smallInput)}
                autoFocus
                margin="normal"
              />
              <TextField
                {...inputFields.hairColor.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
              />
              <TextField
                {...inputFields.overallSize.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
              />
              <TextField
                {...inputFields.height.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
              />
              <TextField
                {...inputFields.weight.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kg</InputAdornment>
                  ),
                }}
              />
              <TextField
                {...inputFields.shoeSize.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
              />
            </TabPanel>
            <TabPanel value="3" className={cx(classes.tabPanel)}>
              <TextField
                {...inputFields.nameOfInstitution.inputProps}
                className={cx(classes.smallInput)}
                autoFocus
                margin="normal"
              />
              <TextField
                {...inputFields.cityOfCollege.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
              />
              <Autocomplete
                options={
                  dataCountries?.allCountries?.length
                    ? dataCountries.allCountries
                    : []
                }
                loading={loadingCountries}
                autoComplete
                filterOptions={filterOptionsCountries}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={onChangeCountryOfCollege}
                value={
                  dataCountries?.allCountries?.find(
                    (x) =>
                      x.id === inputFields.countryOfCollege.inputProps.value
                  ) || null
                }
                className={classes.smallInput}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={inputFields.countryOfCollege.inputProps.label}
                    margin="normal"
                  />
                )}
              />
              <TextField
                {...inputFields.educationSpeciality.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
              />
              <DatePicker
                label="Education start date"
                value={inputFields.educationStartDate.values.value || null}
                onChange={onChangeEducationStartDate}
                renderInput={(props) => (
                  <TextField
                    {...inputFields.educationStartDate.inputProps}
                    {...props}
                    className={cx(classes.smallInput)}
                    margin="normal"
                  />
                )}
              />
              <DatePicker
                label="Graduation date"
                value={inputFields.graduationDate.values.value || null}
                onChange={onChangeGraduationDate}
                renderInput={(props) => (
                  <TextField
                    {...inputFields.graduationDate.inputProps}
                    {...props}
                    className={cx(classes.smallInput)}
                    margin="normal"
                  />
                )}
              />
            </TabPanel>
            <TabPanel value="4" className={cx(classes.tabPanel)}>
              <DatePicker
                label="Available from"
                value={inputFields.availableFrom.values.value || null}
                onChange={onChangeAvailableFrom}
                renderInput={(props) => (
                  <TextField
                    {...inputFields.availableFrom.inputProps}
                    {...props}
                    className={cx(classes.smallInput)}
                    margin="normal"
                  />
                )}
              />
              <TextField
                {...inputFields.status.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                disabled
              />
              <TextField
                {...inputFields.agency.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                disabled
              />
              <TextField
                {...inputFields.agent.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                select
              >
                <MenuItem value="">-- Choose option --</MenuItem>
                {dataAgents?.allAgents?.length ? (
                  dataAgents.allAgents.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        {item.nickname}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled value="">
                    No data found
                  </MenuItem>
                )}
              </TextField>
              <TextField
                {...inputFields.remarks.inputProps}
                className={cx(classes.smallInput)}
                margin="normal"
                disabled
              />
            </TabPanel>
          </DataHandlerComponent>
        </DialogContent>
      </TabContext>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateSeafarer}
        >
          {seafarerId ? "Update" : "Create"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={loadingCreateSeafarer || loadingUpdateSeafarer}
      />
    </Dialog>
  );
};
