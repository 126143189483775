import { gql } from "@apollo/client";

export interface IDeleteAddressVars {
  id: number;
}

export interface IDeleteAddress {
  id: string;
  street: string;
  city: string;
}

export interface IDeleteAddressData {
  deleteAddress: IDeleteAddress;
}

export const DELETE_ADDRESS = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(id: $id) {
      id
      street
      city
    }
  }
`;
