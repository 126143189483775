import { ChangeEvent, FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useStyles } from "./UpsertCommunicationDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertCommunicationDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../utils";
import {
  CREATE_COMMUNICATION,
  ICreateCommunicationData,
  ICreateCommunicationVars,
  IUpdateCommunicationData,
  IUpdateCommunicationVars,
  UPDATE_COMMUNICATION,
} from "../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../components";

import {
  ALL_COMMUNICATIONS,
  ALL_COMMUNICATION_TYPES,
  ICommunication,
  ICommunicationData,
  ICommunicationsData,
  ICommunicationTypesData,
  ICommunicationVars,
  ONE_COMMUNICATION,
} from "../../../../../apollo/queries";
import { useParams } from "react-router";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  communicationId: string | undefined;
  open: boolean;
};

type TProps = DialogProps & TDialogProps;

export const UpsertCommunicationDialog: FC<TProps> = (props) => {
  const { id: seafarerId } = useParams();
  const { onClose, communicationId, open, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    setInputField,
    handleDataToVar,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("main", event.target.checked ? "True" : "");
  };

  const [callOneCommunicationQuery, { loading, error, data }] = useLazyQuery<
    ICommunicationData,
    ICommunicationVars
  >(ONE_COMMUNICATION, {
    onError: (err) => {
      console.log(err);
    },
  });

  const [
    callAllCommunicationTypesQuery,
    {
      loading: loadingCommunicationTypes,
      error: errorCommunicationTypes,
      data: dataCommunicationTypes,
    },
  ] = useLazyQuery<ICommunicationTypesData, null>(ALL_COMMUNICATION_TYPES);

  useEffect(() => {
    if (open) {
      callAllCommunicationTypesQuery();
      if (communicationId) {
        callOneCommunicationQuery({
          variables: {
            id: +communicationId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, communicationId]);

  const [createCommunicationMutation, { loading: loadingCreateCommunication }] =
    useMutation<ICreateCommunicationData, ICreateCommunicationVars>(
      CREATE_COMMUNICATION,
      {
        onCompleted: () => {
          enqueueSnackbar("Added!", {
            variant: "success",
          });
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          console.log({ error });
        },

        update: (cache, { data }) => {
          const existingData: ICommunicationsData | null = cache.readQuery({
            query: ALL_COMMUNICATIONS,
            variables: {
              id: seafarerId ? +seafarerId : undefined,
            },
          });
          if (data?.createCommunication) {
            const newItem: ICommunication = {
              ...data.createCommunication,
            };

            cache.writeQuery({
              query: ALL_COMMUNICATIONS,
              variables: {
                id: seafarerId ? +seafarerId : undefined,
              },

              data: {
                allCommunications: existingData?.allCommunications
                  ? [newItem, ...existingData.allCommunications]
                  : [newItem],
              },
            });
          }
        },
      }
    );

  const [updateCommunicationMutation, { loading: loadingUpdateCommunication }] =
    useMutation<IUpdateCommunicationData, IUpdateCommunicationVars>(
      UPDATE_COMMUNICATION,
      {
        onCompleted: () => {
          enqueueSnackbar("Updated!", {
            variant: "success",
          });
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          console.log({ error });
        },
      }
    );

  const handleCreateCommunication = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid && seafarerId) {
      if (!communicationId) {
        createCommunicationMutation({
          variables: {
            data: {
              typeId: +inputFields.type.inputProps.value,
              main: handleDataToVar("main", "boolean", false),
              value: inputFields.value.values.value,
              seafarerId: +seafarerId,
            },
          },
        });
      } else {
        updateCommunicationMutation({
          variables: {
            id: +communicationId,
            data: {
              typeId: handleDataToVar("type", "number", false),
              main: handleDataToVar("main", "boolean", false),
              value: handleDataToVar("value", "string", false),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (communicationId && data?.oneCommunication) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "type.id",
          toFormProperty: "type",
        },
        {
          fromDataProperty: "value",
          toFormProperty: "value",
        },
        {
          fromDataProperty: "main",
          toFormProperty: "main",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneCommunication, valueToFormOptions, inputFields);

      // if (data.oneCommunication.main) {
      //   getUpdatedInputs.main.values.value = "True";
      // }
      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, communicationId]);

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={handleDialogCleanup}
      {...rest}
    >
      <DialogTitle>
        {communicationId ? "Edit communication" : "Add new communication"}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading || loadingCommunicationTypes}
          error={Boolean(error || errorCommunicationTypes)}
          hasData={
            communicationId
              ? Boolean(data?.oneCommunication?.id)
              : Boolean(dataCommunicationTypes?.allCommunicationTypes?.length)
          }
        >
          <TextField
            {...inputFields.type.inputProps}
            className={classes.bigInput}
            margin="normal"
            select
          >
            <MenuItem value="">-- Choose option --</MenuItem>

            {dataCommunicationTypes?.allCommunicationTypes?.length ? (
              dataCommunicationTypes.allCommunicationTypes.map((item) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem disabled value="">
                No data found
              </MenuItem>
            )}
          </TextField>
          <TextField
            {...inputFields.value.inputProps}
            className={classes.bigInput}
            margin="normal"
          />

          <FormGroup className={classes.fullFlex}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(inputFields.main.inputProps.value)}
                  onChange={handleCheckboxChange}
                />
              }
              label={inputFields.main.inputProps.label}
            />
          </FormGroup>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateCommunication}
        >
          {communicationId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop
        loading={loadingCreateCommunication || loadingUpdateCommunication}
      />
    </Dialog>
  );
};
