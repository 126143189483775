import { MainRouter } from "./routes/Router";
import { CURRENT_USER, ICurrentUser, ICurrentUserData } from "./apollo/queries";
import { ContextProvider, IRemarkDialogContext } from "./utils";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { LoadingComponent } from "./components";

export const App = () => {
  const [user, setUser] = useState<ICurrentUser | undefined>(undefined);
  const [remarkDialogContext, setRemarkDialogContext] =
    useState<IRemarkDialogContext>({
      id: undefined,
      idList: {},
      open: false,
    });
  const [authComplete, setAuthComplete] = useState<boolean | undefined>(
    undefined
  );

  const { loading, error, data } = useQuery<ICurrentUserData, null>(
    CURRENT_USER,
    {
      onCompleted: (data) => {
        if (!data.currentUser?.id) {
          setAuthComplete(false);
        }
      },
    }
  );

  useEffect(() => {
    if (data?.currentUser) {
      setUser(data.currentUser);
      setAuthComplete(true);
    }
  }, [data]);

  const contextValue = {
    user,
    setUser,
    remarkDialogContext,
    setRemarkDialogContext,
  };

  if (loading || authComplete === undefined) {
    return (
      <>
        <LoadingComponent />;
      </>
    );
  }

  if (error) {
    return <p>error</p>;
  }

  return (
    <ContextProvider.Provider value={contextValue}>
      <MainRouter />
    </ContextProvider.Provider>
  );
};
