import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  password: {
    values: { value: "" },
    inputProps: {
      label: "Password",
      required: true,
      type: "password",
    },
  },
  passwordRepeat: {
    values: { value: "" },
    inputProps: {
      label: "Repeat password",
      required: true,
      type: "password",
    },
  },
});
