import { ICreateRemarkVarsIdList } from "../apollo/mutations";

export interface IDialogContext {
  open: boolean;
  id: undefined | string;
  type?: "upsert" | "delete";
}

export interface IRemarkDialogContext {
  open: boolean;
  id: undefined | string;
  idList: ICreateRemarkVarsIdList;
  type?: "upsert" | "delete";
}

export interface IActionMenuContext {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export const DEFAULT_ACTION_MENU_CONTEXT: IActionMenuContext = {
  id: undefined,
  open: false,
  anchorEl: null,
};

export const MSG_NO_ID_FOUND = "No ID found. Please try again later.";

export const NAVBAR_DATA = [
  {
    to: "/seafarers",
    name: "Seafarers",
  },
  {
    to: "/planning",
    name: "Planning",
  },
  {
    to: "/vessels",
    name: "Vessels",
  },
  {
    to: "/settings",
    name: "Settings",
  },
];

export const NAVBAR_SETTINGS_DATA = [
  {
    to: "/settings/documents",
    name: "Documents",
  },
  {
    to: "/settings/ranks",
    name: "Ranks",
  },
  {
    to: "/settings/officer-matrix",
    name: "Officer matrix",
  },
  {
    to: "/settings/agencies",
    name: "Agencies",
  },
  {
    to: "/settings/travel-agencies",
    name: "Travel agencies",
  },
  {
    to: "/settings/miscellaneous",
    name: "Miscellaneous",
  },
  {
    to: "/settings/email-templates",
    name: "Email templates",
  },
  {
    to: "/settings/agents",
    name: "Agents",
  },
];
