import { gql } from "@apollo/client";

export interface IOrganisation {
  id: string;
  name: string;
}

export interface IOrganisationsData {
  personOrganisations?: IOrganisation[];
}

export const ALL_PERSON_ORGANISATIONS = gql`
  query personOrganisations {
    personOrganisations {
      id

      name
    }
  }
`;
