import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IRank } from "../../../../../apollo/queries";
import { useStyles } from "./RankItem.styles";

interface IProps {
  data: IRank;
  handleToggleActionMenu: any;
}

export const RankItem: FC<IProps> = (props) => {
  const { data, handleToggleActionMenu } = props;
  const { classes } = useStyles();

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>{data.name}</TableCell>
      <TableCell>{data.abbreviation}</TableCell>
      <TableCell>-</TableCell>
      <TableCell>-</TableCell>
      <TableCell>{data.daysOnBoard} days</TableCell>
      <TableCell>-</TableCell>
      <TableCell>
        <IconButton onClick={handleToggleActionMenu}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
