import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    "& > span": {
      backgroundColor: theme.palette.background.default,
    },
    "& > span:nth-of-type(2n + 1)": {
      backgroundColor: theme.palette.secondary.lighter,
    },
  },
}));
