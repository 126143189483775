import { gql } from "@apollo/client";
import { ICountry } from "../../../queries";

export interface IUpdateAddress {
  id: string;

  street: string;
  street2: string | undefined;
  postalCode: string;
  city: string;
  region: string | undefined;
  country: ICountry;
  nearestAirport: string | undefined;
  main: boolean;
}

export interface IUpdateAddressData {
  updateAddress: IUpdateAddress;
}

export interface IUpdateAddressVars {
  data: {
    street: string | undefined;
    street2: string | undefined | null;
    postalCode: string | undefined;
    city: string | undefined;
    region: string | undefined | null;
    countryId: number | undefined;
    nearestAirport: string | undefined | null;
    main: boolean | undefined;
  };
  id: number;
}

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($data: AddressUpdateInput!, $id: ID!) {
    updateAddress(data: $data, id: $id) {
      id

      street
      street2
      postalCode
      city
      region
      country {
        id
        name
      }
      nearestAirport
      main
    }
  }
`;
