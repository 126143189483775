import { CircularProgress } from "@mui/material";
import { FC, ReactNode } from "react";
import { TableSkeleton, NoDataComponent, ErrorComponent } from "../";
import { useStyles } from "./DataHandlerComponent.styles";

interface IProps {
  spinner?: boolean;
  error: boolean;
  hasData: boolean;
  loading: boolean;
  skeletonNum?: number;
  skeletonHeight?: number;
  children?: any;
  noDataText?: string;
}

export const DataHandlerComponent: FC<IProps> = (props) => {
  const {
    error,
    hasData,
    loading,
    skeletonHeight,
    skeletonNum,
    spinner,
    children,
    noDataText,
  } = props;
  const { classes } = useStyles();

  if (loading) {
    if (spinner) {
      return (
        <div className={classes.circularProgress}>
          <CircularProgress />
        </div>
      );
    } else {
      return (
        <TableSkeleton
          height={skeletonHeight || 72}
          repeatNum={skeletonNum || 4}
        />
      );
    }
  }

  if (error) {
    return <ErrorComponent />;
  }
  // console.log({ hasData });

  if (!hasData) {
    return <NoDataComponent noDataText={noDataText} />;
  }

  if (children) {
    //TODO: fix fade
    // return (
    //   <Fade in>
    //     <div>{children}</div>
    //   </Fade>
    // );
    return children;
  } else return null;
};
