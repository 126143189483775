import { gql } from "@apollo/client";

export interface INationality {
  id: string;
  name: string;
}

export interface INationalitiesData {
  allNationalities?: INationality[];
}

export const ALL_NATIONALITIES = gql`
  query allNationalities {
    allNationalities {
      id
      name
    }
  }
`;
