import { ChangeEvent, FC, KeyboardEvent, useContext } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  Link as MuiLink,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./LoginScreen.styles";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import {
  EmailOutlined as EmailOutlinedIcon,
  LockOutlined as LockOutlinedIcon,
} from "@mui/icons-material";
import { ContextProvider, useForm, validateForm } from "../../../utils";
import { ILoginData, ILoginVars, LOGIN } from "../../../apollo/mutations";
import { initialInputData } from "./LoginScreen.inputs";
// import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

export const LoginScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const { setUser } = useContext(ContextProvider);

  const { inputFields, setInputFields, setInputField } =
    useForm<keyof typeof initialInputData>(initialInputData);

  console.log(inputFields);

  // const reCaptchaOnChange = (value: string | null) => {
  //   console.log("Captcha value:", value);
  // };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("remember", event.target.checked ? "True" : "False");
  };

  const handleEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginMutation();
    }
  };

  const [LoginPerson] = useMutation<ILoginData, ILoginVars>(LOGIN, {
    onCompleted: (data) => {
      enqueueSnackbar("Logged in!", {
        variant: "success",
      });
      setUser(data.login);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    variables: {
      data: {
        email: inputFields.email.values.value,
        password: inputFields.password.values.value,
        remember: inputFields.remember.values.value === "True",
      },
    },
  });

  const handleLoginMutation = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      LoginPerson();
    } else {
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography variant="h1" paragraph>
            Welcome
          </Typography>
          <Typography>Please enter your login credentials.</Typography>
        </div>
        <TextField
          {...inputFields.email.inputProps}
          autoFocus
          margin="normal"
          autoComplete="username"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputFields.password.inputProps}
          fullWidth
          margin="normal"
          autoComplete="current-password"
          variant="outlined"
          onKeyDown={handleEnterKey}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <div className={cx(classes.checkboxDiv)}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputFields.remember.values.value === "True"}
                  onChange={handleCheckboxChange}
                />
              }
              label={inputFields.remember.inputProps.label}
            />
          </FormGroup>
          <MuiLink component={Link} to="/forgot-password">
            Forgot password?
          </MuiLink>
        </div>
        <div className={classes.reCaptchaDiv}>
          {/* <ReCAPTCHA
            sitekey=""
            onChange={reCaptchaOnChange}
          /> */}
        </div>

        <Button
          className={cx(classes.button)}
          variant="contained"
          fullWidth
          onClick={handleLoginMutation}
        >
          Log in
        </Button>
      </Paper>
    </main>
  );
};
