import {
  ContentPasteGoOutlined as ContentPasteGoOutlinedIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  ClickAwayListener,
  Collapse,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { ISeafarersList } from "../../../../../apollo/queries";
import { useNavigate } from "react-router";
import { useStyles } from "./SeafarerItem.styles";

interface IProps {
  data: ISeafarersList;
  handleSeafarerToClipboard: () => void;
}

export const SeafarerItem: FC<IProps> = (props) => {
  const { id, name, surname, nationality, agent, emails, phones, rank } =
    props.data;
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);
  const [openActionMenu, setOpenActionMenu] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggleActionMenu = (event: any) => {
    event.stopPropagation();
    setOpenActionMenu((prevOpen) => !prevOpen);
  };

  const handleCloseActionMenu = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenActionMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenActionMenu(false);
    } else if (event.key === "Escape") {
      setOpenActionMenu(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openActionMenu);
  useEffect(() => {
    if (prevOpen.current === true && openActionMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openActionMenu;
  }, [openActionMenu]);

  const handleExpand = (event: any) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const handleNavigateToProfile = () => {
    navigate(`${id}`);
  };

  return (
    <>
      <TableRow className={classes.tableRow} onClick={handleNavigateToProfile}>
        <TableCell className={cx(classes.avatarCell)}>
          <Avatar
            src="https://i.pravatar.cc/32"
            className={cx(classes.avatar)}
          />
          <strong>
            {name} {surname}
          </strong>
        </TableCell>
        <TableCell>{rank?.name || "-/-"}</TableCell>
        <TableCell>{nationality.name}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        {agent ? (
          <Tooltip title="Agent info">
            <TableCell>{agent.nickname}</TableCell>
          </Tooltip>
        ) : (
          <TableCell>-</TableCell>
        )}
        <TableCell>
          <Button onClick={handleExpand} variant="outlined" size="small">
            {expanded ? "Less" : "More"}
          </Button>
        </TableCell>
        <TableCell>
          <IconButton ref={anchorRef} onClick={handleToggleActionMenu}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
        <Popper
          open={openActionMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="left-end"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start"
                    ? "right bottom"
                    : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseActionMenu}>
                  <MenuList
                    autoFocusItem={openActionMenu}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleSeafarerToClipboard();
                      }}
                    >
                      <ListItemIcon>
                        <ContentPasteGoOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Copy to clipboard</ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow className={cx(classes.tableRow)}>
                  <TableCell width="20%">Email</TableCell>
                  <TableCell width="20%">Mobile</TableCell>
                  <TableCell width="20%">Last contact</TableCell>
                  <TableCell width="20%">Previous vessel</TableCell>
                  <TableCell width="20%">Next assignment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={cx(classes.tableRowBorder)}>
                  {emails?.length ? (
                    <Tooltip
                      title={
                        <>
                          {emails.map((item) => {
                            return (
                              <Typography key={item.id}>
                                {item.value}
                                {item.main ? " *" : ""}
                              </Typography>
                            );
                          })}
                        </>
                      }
                    >
                      <TableCell>{emails[0].value}</TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell>N/A</TableCell>
                  )}
                  {phones?.length ? (
                    <Tooltip
                      title={
                        <>
                          {phones.map((item) => {
                            return (
                              <Typography key={item.id}>
                                {item.value}
                                {item.main ? " *" : ""}
                              </Typography>
                            );
                          })}
                        </>
                      }
                    >
                      <TableCell>{phones[0].value}</TableCell>
                    </Tooltip>
                  ) : (
                    <TableCell>N/A</TableCell>
                  )}
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>-</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
