import { gql } from "@apollo/client";
export interface IRemark {
  id: string;
  title: string;
  message: string;
  main: boolean;
  seafarerId?: string | null;
  addressId?: string | null;
  communicationId?: string | null;
  agencyId?: string | null;
  created: string;
}

export interface IRemarksData {
  allRemarks: IRemark[];
}

export interface IRemarksVars {
  filter: {
    seafarerId?: number;
    addressId?: number;
    communicationId?: number;
    agencyId?: number;
  };
}

export const ALL_REMARKS = gql`
  query allRemarks($filter: RemarkFilterInput!) {
    allRemarks(filter: $filter) {
      id
      title
      message
      main
      seafarerId
      addressId
      communicationId
      agencyId
      created
    }
  }
`;

//--------- ONE REMARK
export interface IRemarkData {
  oneRemark: IRemark;
}

export interface IRemarkVars {
  id: number;
}

export const ONE_REMARK = gql`
  query oneRemark($id: ID!) {
    oneRemark(id: $id) {
      id
      title
      message
      main
    }
  }
`;
