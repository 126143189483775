import { FC } from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./NotFoundPage.styles";

export const NotFoundPage: FC = () => {
  const { classes, cx } = useStyles();

  return (
    <main className={cx(classes.root)}>
      <Typography variant="h1">404 - Page not found</Typography>
    </main>
  );
};
