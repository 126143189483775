import { ChangeEvent, FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useStyles } from "./UpsertRemarkDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertRemarkDialog.inputs";

import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../utils";
import { LoadingBackdrop } from "../../../LoadingBackdrop/LoadingBackdrop";
import {
  ALL_REMARKS,
  IRemark,
  IRemarkData,
  IRemarksData,
  IRemarkVars,
  ONE_REMARK,
} from "../../../../apollo/queries";
import {
  CREATE_REMARK,
  ICreateRemarkData,
  ICreateRemarkVars,
  ICreateRemarkVarsIdList,
  IUpdateRemarkData,
  IUpdateRemarkVars,
  UPDATE_REMARK,
} from "../../../../apollo/mutations";
import { DataHandlerComponent } from "../../../dataHandlerComponent/DataHandlerComponent";
import { useGlobalStyles } from "../../../../styles";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  remarkId: string | undefined;
  idList: ICreateRemarkVarsIdList;
  open: boolean;
};

type TProps = DialogProps & TDialogProps;

export const UpsertRemarkDialog: FC<TProps> = (props) => {
  const { onClose, remarkId, idList, open, ...rest } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    setInputField,
    handleDataToVar,
    resetFields,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputField("main", event.target.checked ? "True" : "");
  };

  const [callOneRemarksQuery, { loading, error, data }] = useLazyQuery<
    IRemarkData,
    IRemarkVars
  >(ONE_REMARK, {
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (open) {
      // callRemarksQuery();
      console.log("CALLED");
      if (remarkId) {
        console.log("CALLED REMARK ID");
        callOneRemarksQuery({
          variables: {
            id: +remarkId,
          },
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, remarkId]);

  const [createRemarkMutation, { loading: loadingCreateRemark }] = useMutation<
    ICreateRemarkData,
    ICreateRemarkVars
  >(CREATE_REMARK, {
    onCompleted: () => {
      enqueueSnackbar("Added!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log({ error });
    },

    //TODO: FIX when updating two caches at once
    update: (cache, { data }) => {
      if (data?.createRemark) {
        const newItem: IRemark = {
          ...data.createRemark,
        };

        const existingData: IRemarksData | null = cache.readQuery({
          query: ALL_REMARKS,
          variables: {
            filter: {
              ...idList,
            },
          },
        });

        cache.writeQuery({
          query: ALL_REMARKS,
          variables: {
            filter: {
              ...idList,
            },
          },

          data: {
            allRemarks: existingData?.allRemarks
              ? [newItem, ...existingData.allRemarks]
              : [newItem],
          },
        });
      }
    },
  });

  const [updateRemarkMutation, { loading: loadingUpdateRemark }] = useMutation<
    IUpdateRemarkData,
    IUpdateRemarkVars
  >(UPDATE_REMARK, {
    onCompleted: () => {
      enqueueSnackbar("Updated!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log({ error });
    },
  });

  const handleCreateRemark = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!remarkId) {
        createRemarkMutation({
          variables: {
            data: {
              title: inputFields.title.inputProps.value,
              message: inputFields.message.inputProps.value,
              main: handleDataToVar("main", "boolean", false),
              ...idList,
            },
          },
        });
      } else {
        updateRemarkMutation({
          variables: {
            id: +remarkId,
            data: {
              title: handleDataToVar("title", "string", false),
              message: handleDataToVar("message", "string", false),
              main: handleDataToVar("main", "boolean", false),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (open && remarkId && data?.oneRemark) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "title",
          toFormProperty: "title",
        },
        {
          fromDataProperty: "message",
          toFormProperty: "message",
        },
        {
          fromDataProperty: "main",
          toFormProperty: "main",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneRemark, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, remarkId, open]);

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={handleDialogCleanup}
      {...rest}
    >
      <DialogTitle>{remarkId ? "Edit remark" : "Add new remark"}</DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          hasData={remarkId ? Boolean(data?.oneRemark?.id) : true}
        >
          <TextField
            {...inputFields.title.inputProps}
            fullWidth
            margin="normal"
          />

          <TextField
            {...inputFields.message.inputProps}
            fullWidth
            multiline
            minRows={6}
            margin="normal"
          />

          <FormGroup className={globalClasses.fullWidth}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(inputFields.main.inputProps.value)}
                  onChange={handleCheckboxChange}
                />
              }
              label={inputFields.main.inputProps.label}
            />
          </FormGroup>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateRemark}
        >
          {remarkId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingCreateRemark || loadingUpdateRemark} />
    </Dialog>
  );
};
