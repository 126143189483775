import { gql } from "@apollo/client";
import { ICountry } from "../../other/country.query";

export interface IAgency {
  id: string;
  name: string;
  street: string;
  zipCode: string;
  city: string;
  country: ICountry;
}

export interface IAgenciesData {
  allAgencies?: IAgency[];
}

export const ALL_AGENCIES = gql`
  query allAgencies {
    allAgencies {
      id
      name
      street
      zipCode
      city
      country {
        id
        name
      }
    }
  }
`;

//_______________________________ ONE AGENCY _______________________________//

export interface IAgencyData {
  oneAgency?: IAgency;
}

export interface IAgencyVars {
  id: number;
}

export const ONE_AGENCY = gql`
  query oneAgency($id: ID!) {
    oneAgency(id: $id) {
      id
      name
      street
      zipCode
      city
      country {
        id
        name
      }
    }
  }
`;
