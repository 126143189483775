import { FC } from "react";
import { CircularProgress } from "@mui/material";
import { useStyles } from "./LoadingComponent.styles";

interface IProps {
  small?: boolean;
}

export const LoadingComponent: FC<IProps> = (props) => {
  const { small } = props;
  const { classes } = useStyles();
  return (
    <div className={small ? classes.rootSmall : classes.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};
