import { makeStyles } from "../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  tableRow: {
    "& > th": {
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
    },
    "& > td": {
      border: "none",
    },
  },
  tableBody: {
    "& > tr:nth-of-type(4n+1), & > tr:nth-of-type(4n+2)": {
      backgroundColor: theme.palette.secondary.lighter,
    },
    "& > tr": {
      transition: "all 0.2s ease-in-out",
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: theme.palette.primary.lighter,
      },
    },
  },
  justify: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  showMoreButton: {
    alignSelf: "center",
    marginTop: 32,
  },
  noDataFound: {
    marginTop: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  whiteSpace: { whiteSpace: "pre-line" },
}));
