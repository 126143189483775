import { gql } from "@apollo/client";

export interface ICurrentUser {
  id: string;

  name: string;
  email: string;
  currentAgent?: {
    id: string;
    organisationId: string;
    organisation: {
      id: string;
      name: string;
    };
  };
  allOrganisations: {
    id: string;
    name: string;
  }[];

  created: Date;
  updated: Date;
}

export interface ICurrentUserData {
  currentUser?: ICurrentUser;
}

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      id

      currentAgent {
        id
        organisationId
        organisation {
          id
          name
        }
      }

      name
      email
      allOrganisations {
        id
        name
      }
    }
  }
`;
