import { makeStyles } from "./theme";

export const useGlobalStyles = makeStyles()((theme) => ({
  inputHasChanged: {
    // color: lighten(theme.palette.success.light, 0.8),
    color: theme.palette.secondary.main,
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  disableDialogTitleMargin: {
    marginBottom: 0,
  },
  buttonDivRight: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "&>button": {
      marginLeft: theme.spacing(1),
    },
  },
  scrollableDiv: {
    overflow: "auto",
  },
  scrollablePaper: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  padding: {
    padding: theme.spacing(2),
  },
  flex45: {
    flexBasis: "45%",
  },
  flex30: {
    flexBasis: "30%",
  },

  tableRow: {
    "& > th": {
      fontWeight: 600,
      backgroundColor: theme.palette.background.paper,
      // border: "none",
    },
    "& > td": {
      border: "none",
      // borderTop: "solid 1px #aaa",
    },
  },
  tableBody: {
    "& > tr:nth-of-type(2n)": {
      backgroundColor: theme.palette.primary.lighter,
    },
    "& > tr": {
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        transition: "all 0.2s ease-in-out",
        backgroundColor: theme.palette.secondary.lighter,
      },
    },
  },
  passwordField: {
    fontFamily: "password",
  },
  fullWidth: {
    width: "100%",
  },
  chipMarginRight: {
    marginRight: theme.spacing(1),
  },
}));
