import { FC, useEffect, useState } from "react";
import { useStyles } from "./SeafarerProfileNavbar.styles";
import { AutocompleteSearchBar } from "../../..";
import { Outlet } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import {
  ISeafarerData,
  ISeafarerVars,
  ONE_SEAFARER,
} from "../../../../apollo/queries";
import { LoadingComponent } from "../../../LoadingComponent/LoadingComponent";
import { PageLayout } from "../../../PageLayout/PageLayout";
import { SeafarerProfileItem } from "../../../../routes/Private/SeafarerProfile/components";

export const SeafarerProfileNavbar: FC = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();

  const { id } = useParams();

  const [activeSearch, setActiveSearch] = useState("");

  const handleSetActiveSearch = (search: string) => {
    setActiveSearch(search);
  };

  const [callSeafarerQuery, { loading, data, error }] = useLazyQuery<
    ISeafarerData,
    ISeafarerVars
  >(ONE_SEAFARER);

  useEffect(() => {
    if (id) {
      callSeafarerQuery({
        variables: {
          id: +id,
        },
      });
    }
  }, [id, callSeafarerQuery]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (!data?.oneSeafarer) {
    return <p>Error</p>;
  }

  return (
    <>
      <div className={cx(classes.header)}>
        <AutocompleteSearchBar
          activeSearch={activeSearch}
          onSearch={handleSetActiveSearch}
          className={cx(classes.searchBar)}
        />

        <nav className={classes.navDiv}>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to={`/seafarers/${id}`}
            end
          >
            Summary
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            end
            to={`/seafarers/${id}/particulars`}
          >
            Particulars
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Travel Documents
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Certificates
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Fitness
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Training
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Performance
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Assignements
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Additional info
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              cx(isActive ? classes.linkActive : classes.link)
            }
            to="/"
            end
          >
            Contact History
          </NavLink>
        </nav>
      </div>
      <PageLayout flexDirectionRow marginTop="2x">
        <SeafarerProfileItem data={data.oneSeafarer} />
        <Outlet context={{ data: data.oneSeafarer }} />
      </PageLayout>
    </>
  );
};
