import { gql } from "@apollo/client";

export interface ICommunicationType {
  id: string;
  name: string;
}

export interface ICommunicationTypesData {
  allCommunicationTypes?: ICommunicationType[];
}

export const ALL_COMMUNICATION_TYPES = gql`
  query allCommunicationTypes {
    allCommunicationTypes {
      id
      name
    }
  }
`;
