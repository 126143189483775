import { FC } from "react";
import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./ForgotPasswordConfirmScreen.styles";
import { useSnackbar } from "notistack";
import { Lock as LockIcon } from "@mui/icons-material";
import { useForm, validateForm } from "../../../utils";
import { initialInputData } from "./ForgotPasswordConfirmScreen.inputs";
import { useNavigate, useParams } from "react-router";
import { useMutation } from "@apollo/client";
import {
  IUpdatePersonPasswordTokenData,
  IUpdatePersonPasswordTokenVars,
  UPDATE_PERSON_PASSWORD_TOKEN,
} from "../../../apollo/mutations";

export const ForgotPasswordConfirmScreen: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const navigation = useNavigate();
  const { token } = useParams();

  const { inputFields, setInputFields } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [sendPasswordResetTokenMutation] = useMutation<
    IUpdatePersonPasswordTokenData,
    IUpdatePersonPasswordTokenVars
  >(UPDATE_PERSON_PASSWORD_TOKEN, {
    onCompleted: (data) => {
      enqueueSnackbar(
        "Your password has been reset. Plase log in again with your new password.",
        {
          variant: "success",
        }
      );
      navigation("/login");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      // console.log("Error: ", { error });
    },
  });

  const handleSendPorgotPasswordEmail = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (
        inputFields.password.values.value !==
        inputFields.passwordRepeat.values.value
      ) {
        enqueueSnackbar("Your password does not match!", {
          variant: "error",
        });
        return null;
      }

      if (!token) {
        enqueueSnackbar("Token not found!", {
          variant: "error",
        });
        return null;
      }
      sendPasswordResetTokenMutation({
        variables: {
          data: {
            password: inputFields.password.values.value,
            token: token,
          },
        },
      });
    } else {
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  if (!token) {
    return (
      <main className={cx(classes.root)}>
        <Typography variant="h2">Failed to get token</Typography>
      </main>
    );
  }

  return (
    <main className={cx(classes.root)}>
      <Paper className={cx(classes.paper)}>
        <div>
          <Typography paragraph variant="h1">
            Reset password
          </Typography>
          <Typography>Please enter your new password</Typography>
        </div>
        <TextField
          {...inputFields.password.inputProps}
          autoFocus
          autoComplete="off"
          name="newPassword"
          margin="normal"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputFields.passwordRepeat.inputProps}
          margin="normal"
          autoComplete="off"
          name="newPasswordRepeat"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          fullWidth
          className={cx(classes.button)}
          onClick={handleSendPorgotPasswordEmail}
        >
          Send
        </Button>
      </Paper>
    </main>
  );
};
