import { Star as StarIcon } from "@mui/icons-material";
import { Avatar, Button, Divider, Paper, Typography } from "@mui/material";
import { differenceInYears } from "date-fns";
import { FC, useState } from "react";
import { useParams } from "react-router";
import { ISeafarer } from "../../../../../apollo/queries";
import { UpsertSeafarerDialog } from "../../../Seafarers/components";
import { UpdateSeafarerDialog } from "../UpdateSeafarerDialog/UpdateSeafarerDialog";
import { useStyles } from "./SeafarerProfileItem.styles";

interface IProps {
  data: ISeafarer;
}

export const SeafarerProfileItem: FC<IProps> = (props) => {
  const { name, surname, birthday, nationality, weight, height, phones, rank } =
    props.data;
  const { classes, cx } = useStyles();
  const [upsertSeafarerDialog, setUpsertSeafarerDialog] = useState(false);
  const { id } = useParams();

  const handleOpenUpsertSeafarerDialog = () => {
    setUpsertSeafarerDialog(true);
  };

  const handleCloseUpsertSeafarerDialog = () => {
    setUpsertSeafarerDialog(false);
  };

  if (!id) {
    return <Typography>No id found?</Typography>;
  }

  return (
    <Paper className={classes.profilePaper}>
      <div className={classes.avatarDiv}>
        <Avatar src="https://i.pravatar.cc/64" className={cx(classes.avatar)} />
        <div>
          <Typography className={cx(classes.avatarName)}>
            {name} {surname} (
            {differenceInYears(new Date(), new Date(birthday))})
          </Typography>
          <Typography>Voyage employee</Typography>
          <Typography>
            {rank?.name || "- No rank -"}, {nationality.name}
          </Typography>
        </div>
        <Paper className={cx(classes.avatarRating)}>
          <StarIcon className={cx(classes.star)} />
          <Typography variant="caption">4.5</Typography>
        </Paper>
      </div>

      <div className={cx(classes.assignmentDiv)}>
        <Typography className={cx(classes.assignmentStatus)}>
          Current
        </Typography>
        <div>
          <Typography className={cx(classes.bold)} variant="subtitle1">
            Independent Pursuit
          </Typography>
          <Typography variant="body2">09.06.2020. - 18.12.2021.</Typography>
          <Typography variant="body2">Second engineer</Typography>
        </div>
      </div>
      <div className={cx(classes.assignmentDiv)}>
        <Typography className={cx(classes.assignmentStatus)}>Next</Typography>
        <div>
          <Typography className={cx(classes.bold)} variant="subtitle1">
            Independent Pursuit
          </Typography>
          <Typography variant="body2">09.06.2020. - 18.12.2021.</Typography>
          <Typography variant="body2">Second engineer</Typography>
        </div>
      </div>
      <Divider />
      <Typography className={cx(classes.infoText)}>
        Phone:
        <span>
          <b>{phones.length ? phones[0].value : "N/A"}</b>
        </span>
      </Typography>
      <Typography className={cx(classes.infoText)}>
        Employed since:
        <span>
          <b>-</b>
        </span>
      </Typography>
      {/* <Typography>Promotions</Typography> */}
      <Typography className={cx(classes.infoText)}>
        Available from:
        <span>
          <b>-</b>
        </span>
      </Typography>
      <Typography className={cx(classes.infoText)}>
        Weight:
        <span>
          <b>{weight || "N/A"}</b>
        </span>
      </Typography>
      <Typography className={cx(classes.infoText)}>
        Height:
        <span>
          <b>{height || "N/A"}</b>
        </span>
      </Typography>
      <Typography className={cx(classes.infoText)}>
        Status:
        <span>
          <b>-</b>
        </span>
      </Typography>

      <Divider />
      <div className={cx(classes.buttonDiv)}>
        <Button onClick={handleOpenUpsertSeafarerDialog} variant="contained">
          Edit seafarer
        </Button>
        {/* <Button variant="contained">Delete seafarer</Button> */}
      </div>
      <UpsertSeafarerDialog
        seafarerId={id}
        open={upsertSeafarerDialog}
        maxWidth="md"
        fullWidth
        onClose={handleCloseUpsertSeafarerDialog}
      />
    </Paper>
  );
};
