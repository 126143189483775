import { createTypedDynamicFormInputs } from "../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    inputProps: {
      required: true,
      label: "Name",
    },
  },
  password: {
    inputProps: {
      required: true,
      label: "Password",
      type: "password",
    },
  },
});
