import { FC } from "react";
import { useStyles } from "./PageLayout.styles";

interface IProps {
  children?: any;
  marginTop?: "1x" | "2x";
  flexDirectionRow?: boolean;
}

export const PageLayout: FC<IProps> = (props) => {
  const { children, marginTop, flexDirectionRow } = props;
  const { classes, cx } = useStyles();

  return (
    <main
      className={cx(
        classes.root,
        marginTop === "1x"
          ? classes.marginTop1x
          : marginTop === "2x"
          ? classes.marginTop2x
          : classes.marginTop,
        flexDirectionRow ? classes.row : classes.column
      )}
    >
      {children}
    </main>
  );
};
