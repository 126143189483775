import { gql } from "@apollo/client";

export interface ICreatePerson {}

export interface ICreatePersonData {
  createPerson: ICreatePerson;
}

export interface ICreatePersonVars {
  data: {
    token: string;
    name: string;
    password: string;
  };
}

export const CREATE_PERSON = gql`
  mutation createPerson($data: PersonCreateInput!) {
    createPerson(data: $data) {
      id
    }
  }
`;
