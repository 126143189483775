import {
  MessageOutlined as MessageOutlinedIcon,
  NotificationsOutlined as NotificationsOutlinedIcon,
  PersonOutlined as PersonOutlinedIcon,
} from "@mui/icons-material";
import {
  Avatar,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "./Navbar.styles";
import logo from "../../assets/logo.svg";
import { ResetPasswordDialog } from "./components";
import { useMutation } from "@apollo/client";
import {
  ILogoutData,
  ISwitchOrganisationData,
  ISwitchOrganisationVars,
  LOGOUT,
  SWITCH_ORGANISATION,
} from "../../apollo/mutations";
import { useNavigate } from "react-router-dom";
import { ContextProvider, NAVBAR_DATA } from "../../utils";
import { LoadingBackdrop } from "..";
import { ICurrentUser } from "../../apollo/queries";

export const Navbar: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const { user, setUser } = useContext(ContextProvider);

  const [openProfile, setOpenProfile] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const navigation = useNavigate();
  // const { data: organisationsData } = useQuery<IOrganisationsData, null>(
  //   ALL_PERSON_ORGANISATIONS
  // );

  const [logoutMutation, { loading, error, data, client }] = useMutation<
    ILogoutData,
    null
  >(LOGOUT, {
    onCompleted: async () => {
      enqueueSnackbar("Logged out", { variant: "success" });

      await client.clearStore();
      // console.log(user);
      setUser(undefined);
    },
    onError: () => {
      enqueueSnackbar("Failed to log out", { variant: "error" });
    },
  });

  const [
    switchOrganisationMutation,
    { loading: loadingSwitchOrganisation, client: clientSwitchOrganisation },
  ] = useMutation<ISwitchOrganisationData, ISwitchOrganisationVars>(
    SWITCH_ORGANISATION,
    {
      onCompleted: async (res) => {
        enqueueSnackbar(
          `Switched to: ${res.switchOrganisation.organisation.name}`,
          { variant: "success" }
        );
        setUser((prevState) => {
          if (prevState) {
            return {
              ...prevState,
              currentAgent: {
                ...res.switchOrganisation,
              },
            } as ICurrentUser;
          }
        });
        await clientSwitchOrganisation.resetStore();
        navigation("/seafarers");
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      },
    }
  );

  const handleLogout = () => {
    logoutMutation();
  };

  const handleToggle = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handleCloseResetPasswordDialog = () => {
    setResetPasswordDialog(false);
  };

  const handleOpenResetPasswordDialog = () => {
    setOpenProfile(false);
    setResetPasswordDialog(true);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenProfile(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenProfile(false);
    } else if (event.key === "Escape") {
      setOpenProfile(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(openProfile);
  useEffect(() => {
    if (prevOpen.current === true && openProfile === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openProfile;
  }, [openProfile]);

  return (
    <>
      <div className={cx(classes.root)}>
        <div className={classes.logoDiv}>
          <img src={logo} className={cx(classes.logo)} alt="logo" />
          <Typography color="#F2F2F2" variant="h4" component="h1">
            {user?.currentAgent?.organisation?.name}
          </Typography>
        </div>
        <nav className={classes.navDiv}>
          {user?.allOrganisations.length ? (
            <>
              {NAVBAR_DATA.map((item) => (
                <div className={classes.navlinkDiv} key={item.name}>
                  <NavLink
                    className={({ isActive }) =>
                      cx(isActive ? classes.linkActive : classes.link)
                    }
                    to={item.to}
                  >
                    {item.name}
                  </NavLink>
                </div>
              ))}
            </>
          ) : null}

          <IconButton className={cx(classes.iconButton)}>
            <NotificationsOutlinedIcon />
          </IconButton>
          <IconButton className={cx(classes.iconButton)}>
            <MessageOutlinedIcon />
          </IconButton>
          <IconButton
            ref={anchorRef}
            onClick={handleToggle}
            className={cx(classes.iconButton)}
          >
            <PersonOutlinedIcon />
          </IconButton>
          <Popper
            open={openProfile}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="top-start"
            transition
            disablePortal
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "right bottom" : "right top",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={openProfile}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem divider onClick={handleClose}>
                        <ListItemIcon>
                          <Avatar
                            src="https://i.pravatar.cc/64"
                            className={cx(classes.avatarProfile)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          secondary={user ? user?.email : "undefined email"}
                        >
                          {user ? user?.name : "undefined user"}
                        </ListItemText>
                      </MenuItem>
                      {user?.allOrganisations?.length ? (
                        user?.allOrganisations.map((item) => {
                          const handleSwithOrganisation = () => {
                            if (item.id !== user.currentAgent?.organisationId) {
                              switchOrganisationMutation({
                                variables: {
                                  id: +item.id,
                                },
                              });
                            }
                          };
                          // console.log(
                          //   user.currentAgent?.organisationId,
                          //   item.id
                          // );

                          return (
                            <MenuItem
                              key={item.id}
                              onClick={handleSwithOrganisation}
                            >
                              <ListItemIcon>
                                <Avatar className={cx(classes.avatarSmall)}>
                                  {item.id}
                                </Avatar>
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{
                                  classes: {
                                    root: cx(
                                      user.currentAgent?.organisationId !==
                                        item.id
                                        ? classes.menuSmallText
                                        : classes.menuSmallTextActive
                                    ),
                                  },
                                }}
                              >
                                {item.name}
                              </ListItemText>
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem disabled>
                          <ListItemText
                            primaryTypographyProps={{
                              classes: {
                                root: classes.menuSmallText,
                              },
                            }}
                          >
                            You are not assigned to any organisation!
                          </ListItemText>
                        </MenuItem>
                      )}
                      <Divider />
                      <MenuItem onClick={handleOpenResetPasswordDialog}>
                        Change password
                      </MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ResetPasswordDialog
            open={resetPasswordDialog}
            onClose={handleCloseResetPasswordDialog}
          />

          <LoadingBackdrop
            //TODO: Maybe not needed
            loading={loading || loadingSwitchOrganisation}
          />
        </nav>
      </div>
    </>
  );
};
