import { makeStyles } from "../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  bigInput: {
    flexBasis: "45%",
  },
  smallInput: {
    flexBasis: "30%",
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));
