import { createTypedDynamicFormInputs } from "../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  street: {
    values: { value: "" },
    inputProps: {
      label: "Street",
      required: true,
    },
  },
  street2: {
    values: { value: "" },
    inputProps: {
      label: "Street 2",
    },
  },
  postalCode: {
    values: { value: "" },
    inputProps: {
      label: "Postal Code",
      required: true,
    },
  },
  city: {
    values: { value: "" },
    inputProps: {
      label: "City",
      required: true,
    },
  },
  region: {
    values: { value: "" },
    inputProps: {
      label: "Region",
    },
  },
  country: {
    values: { value: "" },
    inputProps: {
      label: "Country",
      required: true,
    },
  },
  nearestAirport: {
    values: { value: "" },
    inputProps: {
      label: "Nearest airport",
    },
  },
  main: {
    values: { value: "" },
    isCheckbox: true,
    inputProps: {
      label: "Main",
    },
  },
});
