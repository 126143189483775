import { FC } from "react";
import { useStyles } from "./NoDataComponent.styles";
import { DataArray as DataArrayIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";

interface IProps {
  noDataText?: string;
}

export const NoDataComponent: FC<IProps> = (props) => {
  const { noDataText } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <DataArrayIcon fontSize="large" />
      <Typography variant="caption">{noDataText || "No data found"}</Typography>
    </div>
  );
};
