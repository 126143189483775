import { gql } from "@apollo/client";

export interface IPersonInvitation {
  id: string;
  nickname: string;
  email: string;
  revoked: boolean;

  agency?: {
    id: string;
    name: string;
  };

  role: {
    id: string;
    name: string;
  };

  created: string;
  expired: string | null;
}

export interface IPersonInvitationsData {
  allPersonInvitations: IPersonInvitation[];
}

export interface IPersonInvitationsVars {
  search: string;
  showArchived: boolean;
}

export const ALL_PERSON_INVITATIONS = gql`
  query allPersonInvitations($search: String, $showArchived: Boolean) {
    allPersonInvitations(search: $search, showArchived: $showArchived) {
      id
      nickname
      email
      revoked
      agency {
        id
        name
      }

      role {
        id
        name
      }
      created
      expired
    }
  }
`;
