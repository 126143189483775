import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  //RankGroup
  rankGroupPaper: {
    flex: 1,
  },

  list: {
    overflow: "auto",
  },

  //Rank
  rankPaper: {
    marginLeft: theme.spacing(4),
    flex: 3,
  },
}));
