import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  content: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  selected: {
    backgroundColor: theme.palette.primary.lighter,
  },
}));
