import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
  compact: {
    backgroundColor: theme.palette.secondary.lighter,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    maxHeight: 300,
  },
}));
