import { gql } from "@apollo/client";
import { ICountry } from "../other/country.query";

export interface IAddress {
  id: string;

  street: string;
  street2?: string;
  postalCode: string;
  city: string;
  region?: string;
  country: ICountry;
  nearestAirport?: string;
  main: boolean;
  remarkCount: number | undefined;
}

export interface IAddressesData {
  allAddresses?: IAddress[];
}

export interface IAddressesVars {
  id: number;
}

export const ALL_ADDRESSES = gql`
  query allAddresses($id: ID!) {
    allAddresses(id: $id) {
      id

      street
      street2
      postalCode
      city
      region
      country {
        id
        name
      }
      nearestAirport
      main

      remarkCount
    }
  }
`;

//_______________________________ ONE ADDRESS _______________________________//

export interface IAddressData {
  oneAddress?: IAddress;
}

export interface IAddressVars {
  id: number;
}

export const ONE_ADDRESS = gql`
  query oneAddress($id: ID!) {
    oneAddress(id: $id) {
      id

      street
      street2
      postalCode
      city
      region
      country {
        id
        name
      }
      nearestAirport
      main
    }
  }
`;
