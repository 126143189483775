import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    // position: "fixed",
    backgroundColor: theme.palette.primary.main,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 32px 0 32px",
    zIndex: theme.zIndex.drawer + 2,

    top: 0,
    left: 0,
    right: 0,

    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.dark,
    borderBottomStyle: "solid",
  },
  logoDiv: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: 36,
    marginRight: 32,
  },
  link: {
    // backgroundColor: "red",
    marginLeft: 8,
    padding: "16px 8px 0 8px",
    // height: 60,
    color: theme.palette.grey[300],
    textDecoration: "none",
    display: "inline-block",
    transition: "color 250ms ease-in-out",

    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
    "&:after": {
      content: "''",
      display: "block",
      marginTop: 16,
      height: "2px",
      background: theme.palette.primary.contrastText,
      transform: "scaleX(0)",
      transition: "transform 250ms ease-in-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  },
  iconButton: {
    marginLeft: 4,
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    "& svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.grey[300],
    },
    "&:hover svg": {
      transition: "0.25s all ease-in-out",
      color: theme.palette.primary.contrastText,
    },
  },

  linkActive: {
    // backgroundColor: "blue",
    color: theme.palette.primary.contrastText,
    marginLeft: 8,
    padding: "16px 8px 0 8px",
    textDecoration: "none",
    display: "inline-block",
    "&:after": {
      content: "''",
      display: "block",
      marginTop: 16,
      height: "2px",
      background: theme.palette.primary.contrastText,
      transform: "scaleX(1)",
      transition: "transform 250ms ease-in-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
    },
  },

  navDiv: {
    // display: "flex",
    // alignItems: "center",
  },

  navlinkDiv: {
    // height: 60,
    display: "inline-block",
    textAlign: "center",
    // backgroundColor: "green",
  },

  //Profile
  popper: {
    zIndex: 1000,
  },
  avatarSmall: {
    width: 22,
    height: 22,
    fontSize: 10,
  },
  menuSmallText: {
    fontSize: 12,
  },
  menuSmallTextActive: {
    fontSize: 12,
    fontWeight: 600,
  },
  avatarProfile: {
    // width: 34,
    // height: 34,
    marginRight: 8,
  },
}));
