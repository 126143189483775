import { makeStyles } from "../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
}));
