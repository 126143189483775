import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.lighter,
    flexBasis: `calc(50% - ${theme.spacing(1)})`,
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
    borderRadius: 4,
  },
  small: {
    flexBasis: `calc(33% - ${theme.spacing(1)})`,
  },
  label: {
    color: theme.palette.primary.main,
  },
}));
