import { makeStyles } from "../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  //Divs
  summaryDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 32,
    marginRight: 32,
    flex: 2,
    minHeight: 0,
  },
  remarksAndActivitiesDiv: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: 0,
  },

  //Tabs
  tabPanel: {
    overflow: "auto",
    width: "100%",
    padding: theme.spacing(2),
  },

  tabPanelContent: {
    overflow: "auto",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  tabDiv: {
    marginBottom: theme.spacing(2),
    maxHeight: "inherit",
  },

  tabPanelRemarks: {
    overflow: "auto",
    width: "100%",
    padding: 0,
  },
}));
