import { Reference, useMutation, useQuery } from "@apollo/client";
import { Add as AddIcon } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  GlobalStyles,
  Grow,
  List,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import {
  ALL_RANKS,
  ALL_RANK_GROUPS,
  IRankGroupsData,
  IRanksData,
  IRanksVars,
} from "../../../../../apollo/queries";
import {
  DataHandlerComponent,
  DeleteDialog,
  LoadingBackdrop,
  PageLayout,
} from "../../../../../components";
import { RankGroupListItem, RankItem } from "../../components";

import { useStyles } from "./RanksScreen.styles";
import { UpsertRankGroupDialog, UpsertRankDialog } from "./components";
import {
  DELETE_RANK,
  DELETE_RANK_GROUP,
  IDeleteRankData,
  IDeleteRankGroupData,
  IDeleteRankGroupVars,
  IDeleteRankVars,
} from "../../../../../apollo/mutations";
import { useSnackbar } from "notistack";
import { useGlobalStyles } from "../../../../../styles";
import { joinClassNames } from "../../../../../utils";

interface IDialog {
  open: boolean;
  type: "rank" | "rankDelete" | "rankGroup" | "rankGroupDelete" | undefined;
  id: undefined | string;
}

interface IActionMenu {
  placementId: string | undefined;
  type: "rank" | "rankGroup" | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
}

export const RanksScreen: FC = () => {
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();
  const [selected, setSelected] = useState<undefined | string>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState<IDialog>({
    id: undefined,
    type: undefined,
    open: false,
  });

  const [actionMenu, setActionMenu] = useState<IActionMenu>({
    placementId: undefined,
    type: undefined,
    open: false,
    anchorEl: null,
  });

  //* ------ MUTATIONS ------------------
  const [deleteRankGroupMutation, { loading: loadingDeleteRankGroup }] =
    useMutation<IDeleteRankGroupData, IDeleteRankGroupVars>(DELETE_RANK_GROUP, {
      onCompleted: (res) => {
        enqueueSnackbar(`${res.deleteRankGroup.name} deleted!`, {
          variant: "success",
        });
        handleCloseDialog();
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            allRankGroups(existingRankGroups: Array<Reference>, { readField }) {
              if (data) {
                return existingRankGroups.filter(
                  (taskRef) =>
                    data.deleteRankGroup.id !== readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    });

  const [deleteRankMutation, { loading: loadingDeleteRank }] = useMutation<
    IDeleteRankData,
    IDeleteRankVars
  >(DELETE_RANK, {
    onCompleted: (res) => {
      enqueueSnackbar(`${res.deleteRank.name} deleted!`, {
        variant: "success",
      });
      handleCloseDialog();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          allRanks(existingRanks: Array<Reference>, { readField }) {
            if (data) {
              return existingRanks.filter(
                (taskRef) => data.deleteRank.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  //* ------ QUERIES ------------------
  const {
    loading: loadingRankGroups,
    error: errorRankGroups,
    data: dataRankGroups,
  } = useQuery<IRankGroupsData>(ALL_RANK_GROUPS);

  const {
    loading: loadingRanks,
    error: errorRanks,
    data: dataRanks,
  } = useQuery<IRanksData, IRanksVars>(ALL_RANKS, {
    variables: selected
      ? {
          groupId: +selected,
        }
      : undefined,
  });

  //* ------ HANDLERS ------------------
  const handleDeleteRankGroupMutation = () => {
    if (dialog.id) {
      deleteRankGroupMutation({
        variables: {
          id: +dialog.id,
        },
      });
    }
  };

  const handleDeleteRankMutation = () => {
    if (dialog.id) {
      deleteRankMutation({
        variables: {
          id: +dialog.id,
        },
      });
    }
  };

  const handleToggleActionMenu =
    (id: string, type: IActionMenu["type"]) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setActionMenu((prev) => {
        return {
          open: prev.type !== type || prev.placementId !== id || !prev.open,
          anchorEl: event.currentTarget,
          placementId: id,
          type: type,
        };
      });
    };

  //Rank
  const handleOpenRankDialog = () => {
    setDialog({ open: true, type: "rank", id: undefined });
  };

  const handleEditDialog = () => {
    if (actionMenu.type) {
      setDialog({
        open: true,
        type: actionMenu.type,
        id: actionMenu.placementId,
      });
    }
  };

  const handleOpenDeleteDialog = () => {
    if (actionMenu.type) {
      setDialog({
        open: true,
        type: `${actionMenu.type}Delete`,
        id: actionMenu.placementId,
      });
    }
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, type: undefined, id: undefined });
  };

  //RankGroup
  const handleOpenRankGroupDialog = () => {
    setDialog({ open: true, type: "rankGroup", id: undefined });
  };

  const handleCloseActionMenu = (event: Event | React.SyntheticEvent) => {
    setActionMenu((prev) => {
      return {
        ...prev,
        open: false,
        type: undefined,
        placementId: undefined,
      };
    });
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionMenu({
        open: false,
        type: undefined,
        placementId: undefined,
        anchorEl: null,
      });
    } else if (event.key === "Escape") {
      setActionMenu({
        open: false,
        type: undefined,
        placementId: undefined,
        anchorEl: null,
      });
    }
  }

  return (
    <>
      <PageLayout marginTop="2x" flexDirectionRow>
        <Paper
          className={joinClassNames([
            globalClasses.scrollablePaper,
            classes.rankGroupPaper,
          ])}
        >
          <div
            className={joinClassNames([
              globalClasses.padding,
              globalClasses.justifyBetween,
            ])}
          >
            <Typography variant="h6">Rank Group</Typography>
            <Button
              variant="outlined"
              onClick={handleOpenRankGroupDialog}
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </div>
          <List className={classes.list}>
            <DataHandlerComponent
              error={Boolean(errorRankGroups)}
              loading={loadingRankGroups}
              hasData={Boolean(dataRankGroups?.allRankGroups?.length)}
              skeletonNum={1}
            >
              {dataRankGroups?.allRankGroups?.length
                ? dataRankGroups.allRankGroups.map((item) => {
                    const handleSetSelected = () => {
                      if (selected === item.id) {
                        setSelected(undefined);
                      } else {
                        setSelected(item.id);
                      }
                    };

                    return (
                      <RankGroupListItem
                        key={item.id}
                        selected={selected}
                        onClick={handleSetSelected}
                        data={item}
                        handleToggleActionMenu={handleToggleActionMenu(
                          item.id,
                          "rankGroup"
                        )}
                      />
                    );
                  })
                : null}
            </DataHandlerComponent>
          </List>
        </Paper>

        <Paper
          className={joinClassNames([
            globalClasses.scrollablePaper,
            classes.rankPaper,
          ])}
        >
          <div
            className={joinClassNames([
              globalClasses.padding,
              globalClasses.justifyBetween,
            ])}
          >
            <Typography variant="h6">Ranks</Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleOpenRankDialog}
            >
              Add
            </Button>
          </div>
          {/* //TODO: Check Figma and Ranks
           */}

          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow className={globalClasses.tableRow}>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Abbreviation</TableCell>
                  <TableCell align="left">STCW regulation</TableCell>
                  <TableCell align="left">Rank Type</TableCell>
                  <TableCell align="left">Avg time on board</TableCell>
                  <TableCell align="left">Rank priority</TableCell>

                  <TableCell width={64} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={globalClasses.tableBody}>
                {dataRanks?.allRanks?.length
                  ? dataRanks.allRanks.map((item) => {
                      return (
                        <RankItem
                          key={item.id}
                          data={item}
                          handleToggleActionMenu={handleToggleActionMenu(
                            item.id,
                            "rank"
                          )}
                        />
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <DataHandlerComponent
            error={Boolean(errorRanks)}
            loading={loadingRanks}
            hasData={Boolean(dataRanks?.allRanks?.length)}
          />
          <UpsertRankDialog
            rankId={dialog.id}
            onClose={handleCloseDialog}
            open={dialog.open && dialog.type === "rank"}
            selectedGroupId={selected}
          />
        </Paper>
        <Popper
          style={{ zIndex: 100 }}
          open={actionMenu.open}
          anchorEl={actionMenu.anchorEl}
          role={undefined}
          placement="right-end"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start"
                    ? "right bottom"
                    : "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseActionMenu}>
                  <MenuList
                    autoFocusItem={actionMenu.open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleEditDialog}>Edit</MenuItem>
                    <MenuItem onClick={handleOpenDeleteDialog}>Delete</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LoadingBackdrop
          loading={loadingDeleteRankGroup || loadingDeleteRank}
        />

        <UpsertRankGroupDialog
          rankGroupId={dialog.id}
          onClose={handleCloseDialog}
          open={dialog.open && dialog.type === "rankGroup"}
        />
        <DeleteDialog
          title={`Delete ${actionMenu.type}?`}
          description="This is a permanent action"
          mutationCall={
            dialog.type === "rankGroupDelete"
              ? handleDeleteRankGroupMutation
              : handleDeleteRankMutation
          }
          onClose={handleCloseDialog}
          open={
            dialog.open &&
            (dialog.type === "rankGroupDelete" || dialog.type === "rankDelete")
          }
        />
      </PageLayout>
    </>
  );
};
