import { makeStyles } from "../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  profilePaper: {
    flexBasis: 400,
    padding: 16,
    alignSelf: "flex-start",
    minHeight: 0,
  },
  avatarDiv: {
    position: "relative",
    display: "flex",
    marginBottom: 16,
  },
  avatar: {
    width: 72,
    height: 72,
    marginRight: 16,
    borderRadius: "50%",
    border: "4px #fff solid",
    boxShadow: "0 0 1px 4px red",
  },
  avatarName: {
    fontWeight: 600,
    fontSize: 18,
  },
  avatarRating: {
    position: "absolute",
    top: -8,
    left: 30,
    padding: 2,
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
  },
  star: {
    fontSize: 16,
    color: theme.palette.secondary.main,
    marginRight: 4,
  },

  //Assignement
  assignmentDiv: {
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderRadius: 4,
    display: "flex",
    height: 72,
    overflow: "hidden",
    marginBottom: 8,
    marginTop: 8,
  },

  assignmentStatus: {
    height: 72,
    backgroundColor: theme.palette.secondary.main,
    writingMode: "vertical-rl",
    transform: "scale(-1)",
    padding: 4,
    textAlign: "center",
    color: "white",

    marginRight: 16,
  },
  bold: {
    fontWeight: "600",
  },

  //info text
  infoText: {
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      flexBasis: "30%",
    },
  },

  buttonDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
}));
