import { useState, FC, useMemo, useEffect, useCallback } from "react";
import {
  TextField,
  TextFieldProps,
  FilledTextFieldProps,
  Autocomplete,
  MenuItem,
  ListItemText,
  Avatar,
  ListItemIcon,
} from "@mui/material";
import { useStyles } from "./AutocompleteSearchBar.styles";
import {
  ALL_SEAFARERS,
  ISeafarersList,
  ISeafarersListData,
  ISeafarersListVars,
} from "../../apollo/queries";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { throttle } from "throttle-debounce";
import { useNavigate } from "react-router";

type TSearchBarProps = {
  onSearch?: (value: string) => void;
  activeSearch?: string;
};

type TProps = TSearchBarProps & TextFieldProps;

export const AutocompleteSearchBar: FC<TProps> = (props) => {
  const [value, setValue] = useState<ISeafarersList | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [seafarersList, setSeafarersList] = useState<readonly ISeafarersList[]>(
    []
  );
  const { classes, cx } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const navigation = useNavigate();

  const queryVariables = useMemo(() => {
    const vars: ISeafarersListVars = {
      cursor: undefined,
      filter: searchQuery
        ? {
            searchQuery: searchQuery,
          }
        : undefined,
    };

    return vars;
  }, [searchQuery]);

  const { loading, error, data, refetch } = useQuery<
    ISeafarersListData,
    ISeafarersListVars
  >(ALL_SEAFARERS, {
    variables: queryVariables,
    skip: !searchQuery,
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetch = useCallback(
    throttle(300, (variables) => {
      refetch(variables);
      // console.log("CALLED API");
      // Some API call
    }),
    []
  );

  useEffect(() => {
    if (searchQuery === "") {
      setSeafarersList(value ? [value] : []);
      return undefined;
    }

    fetch(queryVariables);
  }, [value, queryVariables, searchQuery, refetch]);

  useEffect(() => {
    if (data?.allSeafarers && Array.isArray(data.allSeafarers)) {
      setSeafarersList(data.allSeafarers);
    }
  }, [data]);

  const inputProps: FilledTextFieldProps["InputProps"] = {
    // startAdornment: (
    //   <InputAdornment position="start">
    //     <IconButton
    //       className={cx(classes.iconButton)}
    //       size="small"
    //       onClick={handleSearchQueryPress}
    //       title="Pretraži"
    //     >
    //       <SearchOutlinedIcon fontSize="small" />
    //     </IconButton>
    //   </InputAdornment>
    // ),
    // endAdornment:
    //   searchQuery || activeSearch ? (
    //     <InputAdornment position="end">
    //       <IconButton
    //         className={cx(classes.iconButton)}
    //         size="small"
    //         onClick={handleResetSearchQuery}
    //         title="Obriši tražilicu"
    //       >
    //         <CancelOutlinedIcon />
    //       </IconButton>
    //     </InputAdornment>
    //   ) : null,
    classes: {
      root: cx(classes.root),
      input: cx(classes.input),
      focused: cx(classes.focused),
      // notchedOutline: classes.notchedOutline,
    },
  };

  // const inputProps2: FilledTextFieldProps["inputProps"] = {
  //   classes: {
  //     root
  //     // root: cx(classes.root),
  //     // input: cx(classes.input),
  //     // focused: cx(classes.focused),
  //     // notchedOutline: classes.notchedOutline,
  //   },
  // };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={seafarersList}
      autoComplete
      loading={loading}
      className={classes.root}
      size="small"
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      value={value}
      onChange={(event: any, newValue: ISeafarersList | null) => {
        // setSeafarersList(
        //   newValue ? [newValue, ...seafarersList] : seafarersList
        // );
        setValue(newValue);
        // console.log("New value: ", newValue);
        if (newValue) {
          navigation(`/seafarers/${newValue.id}`);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setSearchQuery(newInputValue);
        // console.log("SEARCH QUERY: ", newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          label="Search seafarers"
          {...params}
          // InputProps={inputProps}
          error={Boolean(error)}
          InputLabelProps={{
            classes: {
              root: classes.inputRoot,
              focused: classes.inputFocused,
            },
          }}
        />
      )}
      groupBy={(option) => option.nationality?.name}
      renderOption={(props, option) => {
        return (
          <MenuItem {...props} disableGutters divider>
            <ListItemIcon>
              <Avatar
                src="https://i.pravatar.cc/64"
                className={classes.avatarProfile}
              />
            </ListItemIcon>
            <ListItemText secondary={option.rank?.name || "N/A"}>
              {option.name} {option.surname}
            </ListItemText>
          </MenuItem>
        );
      }}
    />
  );
};
