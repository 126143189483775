import { gql } from "@apollo/client";

export interface IDeleteFilterVars {
  id: number;
}

export interface IDeleteFilter {
  id: string;
  name: string;
}

export interface IDeleteFilterData {
  deleteFilter: IDeleteFilter;
}

export const DELETE_FILTER = gql`
  mutation deleteFilter($id: ID!) {
    deleteFilter(id: $id) {
      id
      name
    }
  }
`;
