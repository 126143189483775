import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { lighten } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";
import { createMakeAndWithStyles } from "tss-react";

declare module "@mui/material/styles" {
  interface PaletteColor {
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
}

const palette = createPalette({
  primary: {
    main: "#4467A0",
    lighter: lighten("#4467A0", 0.92),
    //  "#f2f6fc",
  },
  secondary: {
    main: "#72a8ad",
    lighter: lighten("#72a8ad", 0.92),
    contrastText: "#ffffff",
  },
  text: {
    primary: "#555555",
    // secondary: "#dddddd",
  },

  background: {
    default: "#F2F2F2",
    paper: "#ffffff",
  },
});

export const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography: {
      // fontFamily: ["Atkinson", "Roboto"].join(","),
      h1: {
        fontSize: 34,
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // paddingRight: 48,
            paddingLeft: 24,
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #dddddd",
            marginBottom: 8,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: palette.primary.lighter,
            },
            backgroundColor: palette.background.paper,
          },
        },
      },

      MuiCssBaseline: {
        styleOverrides: {
          body: {
            height: "100vh",
            overflow: "hidden",
          },
        },
      },
    },
  })
);

export const { makeStyles, withStyles } = createMakeAndWithStyles({
  useTheme: () => theme,
});
