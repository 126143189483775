import { gql } from "@apollo/client";

export interface IUpdatePersonPasswordVars {
  data: {
    password: string;
    passwordReset: string;
  };
}

export interface IUpdatePersonPassword {
  id: string;
}

export interface IUpdatePersonPasswordData {
  updatePersonPassword: IUpdatePersonPassword;
}

export const UPDATE_PERSON_PASSWORD = gql`
  mutation updatePersonPassword($data: PersonUpdatePasswordInput) {
    updatePersonPassword(data: $data) {
      id
    }
  }
`;

//TOKEN
export interface IUpdatePersonPasswordTokenVars {
  data: {
    password: string;
    token: string;
  };
}

export interface IUpdatePersonPasswordToken {
  id: string;
}

export interface IUpdatePersonPasswordTokenData {
  updatePersonPasswordToken: IUpdatePersonPasswordToken;
}

export const UPDATE_PERSON_PASSWORD_TOKEN = gql`
  mutation updatePersonPasswordToken($data: PersonUpdatePasswordTokenInput!) {
    updatePersonPasswordToken(data: $data)
  }
`;
