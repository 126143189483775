import { InputLabel, Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./InputShowcase.styles";

interface IProps {
  label: string;
  value: string | number | undefined;
  small?: boolean;
}

export const InputShowcase: FC<IProps> = (props) => {
  const { value, label, small } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, small ? classes.small : undefined)}>
      <Typography className={classes.label} variant="caption">
        {label}
      </Typography>
      <Typography>{value ?? "N/A"}</Typography>
    </div>
  );
};
