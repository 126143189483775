import { Reference, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FC } from "react";

import { DeleteDialog, LoadingBackdrop, TDeleteDialogProps } from "../../..";
import {
  DELETE_REMARK,
  IDeleteRemarkData,
  IDeleteRemarkVars,
} from "../../../../apollo/mutations";

type TDialogProps = {
  id: string | undefined;
};

type TProps = TDialogProps & Omit<TDeleteDialogProps, "mutationCall">;

export const DeleteRemarkDialog: FC<TProps> = (props) => {
  const { id, onClose, ...rest } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [deleteRemarkMutation, { loading: loadingDeleteRemark }] = useMutation<
    IDeleteRemarkData,
    IDeleteRemarkVars
  >(DELETE_REMARK, {
    variables: {
      id: id ? +id : 0,
    },
    onCompleted: (res) => {
      enqueueSnackbar(`${res.deleteRemark.title} deleted!`, {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    //TODO: FIX
    update(cache, { data }) {
      cache.modify({
        fields: {
          allRemarks(existingData: Array<Reference>, { readField }) {
            if (data) {
              return existingData.filter(
                (taskRef) => data.deleteRemark.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  return (
    <>
      <DeleteDialog
        mutationCall={deleteRemarkMutation}
        onClose={onClose}
        {...rest}
      />
      <LoadingBackdrop loading={loadingDeleteRemark} />
    </>
  );
};
