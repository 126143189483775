import { gql } from "@apollo/client";

export interface IDeleteAgencyVars {
  id: number;
}

export interface IDeleteAgency {
  id: string;
  name: string;
}

export interface IDeleteAgencyData {
  deleteAgency: IDeleteAgency;
}

export const DELETE_AGENCY = gql`
  mutation deleteAgency($id: ID!) {
    deleteAgency(id: $id) {
      id
      name
    }
  }
`;
