import { ChangeEvent, FC, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@mui/material";
import { useStyles } from "./UpsertRankDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { initialInputData } from "./UpsertRankDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../../../utils";
import {
  CREATE_RANK,
  ICreateRankData,
  ICreateRankVars,
  IUpdateRankData,
  IUpdateRankVars,
  UPDATE_RANK,
} from "../../../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  ErrorComponent,
  LoadingBackdrop,
  LoadingComponent,
} from "../../../../../../../components";

import {
  ALL_RANKS,
  ALL_RANK_GROUPS,
  IRank,
  IRankData,
  IRankGroupsData,
  IRankGroupsVars,
  IRanksData,
  IRankVars,
  ONE_RANK,
} from "../../../../../../../apollo/queries";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  rankId: string | undefined;
  selectedGroupId: string | undefined;
};

type TProps = DialogProps & TDialogProps;

export const UpsertRankDialog: FC<TProps> = (props) => {
  const { open, onClose, rankId, selectedGroupId, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    resetFields,
    setInputField,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [callOneRankQuery, { loading, error, data }] = useLazyQuery<
    IRankData,
    IRankVars
  >(ONE_RANK, {
    fetchPolicy: "network-only",
    onError: (err) => {
      console.log(err);
    },
  });

  const {
    loading: loadingRankGroups,
    error: errorRankGroups,
    data: dataRankGroups,
  } = useQuery<IRankGroupsData, IRankGroupsVars>(ALL_RANK_GROUPS, {
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (rankId && open) {
      callOneRankQuery({
        variables: {
          id: +rankId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankId, open]);

  const handleIsOfficerCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputField("isOfficer", event.target.checked ? "true" : "");
  };

  const handleIsWatchkeeperCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputField("isWatchkeeper", event.target.checked ? "true" : "");
  };

  const handleIsPromotableCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setInputField("isPromotable", event.target.checked ? "true" : "");
  };

  const [createRankMutation, { loading: loadingCreateRank }] = useMutation<
    ICreateRankData,
    ICreateRankVars
  >(CREATE_RANK, {
    onCompleted: () => {
      enqueueSnackbar("Added!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },

    update: (cache, { data }) => {
      const existingData: IRanksData | null = cache.readQuery({
        query: ALL_RANKS,
      });
      if (data?.createRank) {
        const newItem: IRank = {
          ...data.createRank,
        };

        console.log(inputFields.rankGroup.values.value);
        //Group only
        cache.writeQuery({
          query: ALL_RANKS,
          variables: { groupId: +inputFields.rankGroup.values.value },
          data: {
            allRanks: existingData?.allRanks
              ? [newItem, ...existingData.allRanks]
              : [newItem],
          },
        });
        //No grouos
        cache.writeQuery({
          query: ALL_RANKS,
          data: {
            allRanks: existingData?.allRanks
              ? [newItem, ...existingData.allRanks]
              : [newItem],
          },
        });
      }
    },
  });

  const [updateRankMutation, { loading: loadingUpdateRank }] = useMutation<
    IUpdateRankData,
    IUpdateRankVars
  >(UPDATE_RANK, {
    onCompleted: () => {
      enqueueSnackbar("Updated!", {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleCreateRank = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!rankId) {
        createRankMutation({
          variables: {
            data: {
              name: inputFields.name.values.value,
              abbreviation: inputFields.abbreviation.values.value,
              daysOnBoard: +inputFields.daysOnBoard.values.value,
              isOfficer: inputFields.isOfficer.values.value === "true",
              isWatchkeeper: inputFields.isWatchkeeper.values.value === "true",
              isPromotable: inputFields.isPromotable.values.value === "true",

              rankGroupId: +inputFields.rankGroup.values.value,
            },
          },
        });
      } else {
        updateRankMutation({
          variables: {
            id: +rankId,
            data: {
              name: handleDataToVar("name", "string", false),
              abbreviation: handleDataToVar("abbreviation", "string", false),
              daysOnBoard: handleDataToVar("daysOnBoard", "number", false),
              isOfficer: handleDataToVar("isOfficer", "boolean", false),
              isWatchkeeper: handleDataToVar("isWatchkeeper", "boolean", false),
              isPromotable: handleDataToVar("isPromotable", "boolean", false),
              rankGroupId: handleDataToVar("rankGroup", "number", false),
            },
          },
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (rankId && data?.oneRank) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "abbreviation",
          toFormProperty: "abbreviation",
        },
        {
          fromDataProperty: "daysOnBoard",
          toFormProperty: "daysOnBoard",
        },
        {
          fromDataProperty: "rankGroup.id",
          toFormProperty: "rankGroup",
        },
        {
          fromDataProperty: "isOfficer",
          toFormProperty: "isOfficer",
        },
        {
          fromDataProperty: "isPromotable",
          toFormProperty: "isPromotable",
        },
        {
          fromDataProperty: "isWatchkeeper",
          toFormProperty: "isWatchkeeper",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneRank, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rankId]);

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={handleDialogCleanup}
      {...rest}
    >
      <DialogTitle>{rankId ? "Edit rank" : "Add new rank"}</DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={loading}
          error={Boolean(error)}
          hasData={Boolean(data?.oneRank) || !Boolean(rankId)}
          skeletonNum={3}
        >
          <>
            <TextField
              {...inputFields.name.inputProps}
              className={classes.bigInput}
              autoFocus
              margin="normal"
            />
            <TextField
              {...inputFields.abbreviation.inputProps}
              className={classes.bigInput}
              margin="normal"
            />
            <TextField
              {...inputFields.daysOnBoard.inputProps}
              className={classes.bigInput}
              margin="normal"
            />
            <TextField
              {...inputFields.rankGroup.inputProps}
              select
              className={classes.bigInput}
              margin="normal"
            >
              {loadingRankGroups ? (
                <LoadingComponent small />
              ) : errorRankGroups ? (
                <ErrorComponent />
              ) : dataRankGroups?.allRankGroups?.length ? (
                dataRankGroups.allRankGroups.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value="">
                  No data found
                </MenuItem>
              )}
            </TextField>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(inputFields.isOfficer.values.value)}
                    onChange={handleIsOfficerCheckboxChange}
                    color={
                      inputFields.isOfficer.initialValues?.hasChanged
                        ? "secondary"
                        : "primary"
                    }
                  />
                }
                label={inputFields.isOfficer.inputProps.label}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(inputFields.isPromotable.values.value)}
                    onChange={handleIsPromotableCheckboxChange}
                  />
                }
                label={inputFields.isPromotable.inputProps.label}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(inputFields.isWatchkeeper.values.value)}
                    onChange={handleIsWatchkeeperCheckboxChange}
                  />
                }
                label={inputFields.isWatchkeeper.inputProps.label}
              />
            </div>
          </>
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCreateRank}>
          {rankId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingCreateRank || loadingUpdateRank} />
    </Dialog>
  );
};
