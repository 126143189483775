import { TextFieldProps } from "@mui/material";
import { IMedia } from "../apollo/queries";
import deepCopy from "deep-copy";

//*Initial InputFields
export interface IDynamicFormInitialInputData {
  [key: string]: IDynamicFormInitialInput;
}

export interface IDynamicFormInitialInput {
  //? Is this even needed? Should it be moved to IDynamicFormInput?
  values?: IValue;
  initialValues?: IValue & { hasChanged: boolean };
  isCheckbox?: boolean;

  readonly rule?: "email" | "media";
  errorText?: string;
  helperText?: string;
  inputProps: IInputProps;
}

export function createTypedDynamicFormInputs<
  T extends { [name: string]: IDynamicFormInitialInput }
>(input: T): Record<keyof T, IDynamicFormInitialInput> {
  return input;
}
//*Input fields type
export interface IDynamicFormInputData {
  [key: string]: IDynamicFormInput;
}

export interface IDynamicFormInput extends IDynamicFormInitialInput {
  values: IValue;
}

//*Input Field props
export interface IInputPropsData {
  inputProps: IInputProps;
}

export interface IInputProps {
  value?: any;
  label: string;
  name?: string; //? Required?
  type?: TextFieldProps["type"];
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  InputProps?: any;
}

//TODO: Fix below
export interface IValue {
  value: string;
  // rteValue?: Descendant[] | undefined;
  media?: IMedia[] | undefined;
}
export type TValueToFormOptions = {
  fromDataProperty: string;
  toFormProperty: string;
}[];

// type IData = {
//   [key: string]: any;
// };

// export const getFormValuesFromFetchedDataV2 = <
//   T extends string | number | symbol
// >(
//   data: IData,
//   valueToFormOptions: TValueToFormOptions,
//   inputFields: Record<T, IDynamicFormInput>
// ) => {
//   const iterateData = (data: any, from: string, to: string): any => {
//     for ( let k in data ) {
//       if ( typeof data[ k ] === 'object' && data[ k ] !== null ) {

//       }
//     }
//   };

// };

//TODO: NEEDS A REDO
export const getFormValuesFromFetchedData = <
  T extends string | number | symbol
>(
  data: { [key: string]: any },
  valueToFormOptions: TValueToFormOptions,
  inputFields: IDynamicFormInputData
) => {
  const inputFieldsCopy = deepCopy(inputFields);

  valueToFormOptions.forEach((item) => {
    item.fromDataProperty.split(".").reduce((obj, property, i) => {
      // console.log(
      //   "Get [",
      //   i,
      //   "] by property: -",
      //   property,
      //   "-. Current Object: ",
      //   obj
      // );
      const isMediaRule = inputFieldsCopy[item.toFormProperty].rule === "media";
      const returnValueProperty = isMediaRule ? "media" : "value";

      //ARRAYS
      if (Array.isArray(obj) && obj.length) {
        // console.log("-InArray object: ", obj);

        const arrayObject = obj.map((element: { [key: string]: any }) => {
          // console.log("-InArray object: ", element);
          // console.log(localeOrNot);
          // console.log("--InArray value element: ", element);

          if (element[property]) {
            // console.log("--InArray value: ", element[property]);

            inputFieldsCopy[item.toFormProperty].values = {
              // rteValue:
              //   inputFieldsCopy[item.toFormProperty].rules === "slateRte"
              //     ? parseRteValue(element[property])
              //     : undefined,
              value:
                inputFieldsCopy[item.toFormProperty].rule === "media" &&
                Array.isArray(element[property]) &&
                element[property].length
                  ? element[property].map((item: IMedia) => item.id).join(",")
                  : inputFieldsCopy[item.toFormProperty].isCheckbox
                  ? element[property]
                    ? element[property].toString()
                    : ""
                  : // : Array.isArray(element[property]) &&
                    //   !element[property].length
                    // ? ""
                    element[property],
              media:
                inputFieldsCopy[item.toFormProperty].rule === "media"
                  ? Array.isArray(element[property])
                    ? element[property].map((item: IMedia) => {
                        return {
                          extension: item.extension,
                          mimeType: item.mimeType,
                          fileName: item.fileName,
                          byteSize: item.byteSize,
                          id: item.id,
                          bucketPath: item.bucketPath,
                        };
                      })
                    : [
                        {
                          extension: element[property].extension,
                          mimeType: element[property].mimeType,
                          fileName: element[property].fileName,
                          byteSize: element[property].byteSize,
                          id: element[property].id,
                          bucketPath: element[property].bucketPath,
                        },
                      ]
                  : undefined,
            };
            inputFieldsCopy[item.toFormProperty].inputProps.value =
              inputFieldsCopy[item.toFormProperty].values[returnValueProperty];
            return element;
            // } else {
            //   console.log("NOT LOCALISED ", element[property]);
            //   inputFieldsCopy[item.toFormProperty].value = item.isRte
            //     ? parseRteValue((element as any)[property])
            //     : (element as any)[property];
            //   return (element as any)[property];
          }
          return element;
        });

        if (arrayObject.every((i: any) => typeof i === "string")) {
          // console.log("Merge array into one string!", arrayObject.toString());
          inputFieldsCopy[item.toFormProperty].values.value =
            arrayObject.toString();
          inputFieldsCopy[item.toFormProperty].inputProps.value =
            inputFieldsCopy[item.toFormProperty].values.value;
          return arrayObject.toString();
        } else return arrayObject;

        //OBJECTS
      } else {
        // console.log("-InObj Object: ", obj);
        console.log("--InObj value: ", obj?.[property], item.toFormProperty);
        inputFieldsCopy[item.toFormProperty].values = {
          // rteValue:
          //   inputFieldsCopy[item.toFormProperty].rules === "slateRte"
          //     ? obj?.[property]
          //       ? parseRteValue(obj[property])
          //       : undefined
          //     : undefined,
          value:
            obj?.[property] || obj?.[property] === 0
              ? obj[property] === typeof "number"
                ? obj[property].toString()
                : inputFieldsCopy[item.toFormProperty].rule === "media" &&
                  Array.isArray(obj[property]) &&
                  obj[property].length
                ? obj[property].map((item: IMedia) => item.id).join(",")
                : Array.isArray(obj[property])
                ? obj[property][0].id
                : typeof obj[property] === "object" && obj[property] !== null
                ? obj[property].id
                : inputFieldsCopy[item.toFormProperty].isCheckbox
                ? obj[property]
                  ? obj[property].toString()
                  : ""
                : obj[property]
              : "",
          media:
            inputFieldsCopy[item.toFormProperty].rule === "media" &&
            obj?.[property]
              ? Array.isArray(obj[property])
                ? obj[property].map((item: IMedia) => {
                    return {
                      id: item.id,
                      extension: item.extension,
                      mimeType: item.mimeType,
                      fileName: item.fileName,
                      byteSize: item.byteSize,
                      bucketPath: item.bucketPath,
                    } as IMedia;
                  })
                : ([
                    {
                      id: obj[property].id,
                      extension: obj[property].extension,
                      mimeType: obj[property].mimeType,
                      fileName: obj[property].fileName,
                      byteSize: obj[property].byteSize,
                      bucketPath: obj[property].bucketPath,
                    },
                  ] as IMedia[])
              : ([] as IMedia[]),
        };
        inputFieldsCopy[item.toFormProperty].inputProps.value =
          inputFieldsCopy[item.toFormProperty].values[returnValueProperty];
        // console.log("IFC: ", inputFieldsCopy);
        return obj?.[property];
      }

      // return obj as any;
    }, data);
    // console.log("DV: ", deepValue);

    inputFieldsCopy[item.toFormProperty].initialValues = {
      ...inputFieldsCopy[item.toFormProperty].values,
      hasChanged: false,
    };
  });
  return inputFieldsCopy as Record<T, IDynamicFormInput>;
};
//TODO: Try catch type?
export const validateForm = <T extends string | number | symbol>(
  fields: Array<string>,
  values: IDynamicFormInputData
) => {
  const outputData: IDynamicFormInputData = {};
  let formValid = true;

  if (fields?.length && values && Object.keys(values).length) {
    fields.forEach((field: string) => {
      let fieldInvalid = false;
      let errorText = "";
      // let hasChanged = 0

      // if ( values[ field ].initialValues?.hasChanged ) {
      //   hasChanged++
      // }

      const makeInvalid = (message?: string) => {
        // console.log("Required check failed");
        fieldInvalid = true;
        formValid = false;
        errorText = message || "";
      };

      const checkIfInvalid = (message?: string) => {
        if (values[field].inputProps.required && !values[field].values.value) {
          // console.log("Required check failed: ", values[field]);
          makeInvalid(message);
        }
      };

      checkIfInvalid("Required field is not set!");

      if (values[field].values.value) {
        if (values[field].rule === "email") {
          const pattern =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
          const checkPattern = pattern.test(values[field].values.value);
          if (!checkPattern) {
            makeInvalid("Invalid email format");
          }
        }
      }
      outputData[field] = {
        ...values[field],
        errorText: errorText ? errorText : values[field].errorText,
        inputProps: {
          ...values[field].inputProps,
          error: fieldInvalid,
          helperText: fieldInvalid ? errorText : values[field].helperText,
        },
      };
    });
    return { formValid, outputData } as {
      formValid: boolean;
      outputData: Record<T, IDynamicFormInput>;
    };
  }
  return { formValid: false, outputData } as {
    formValid: boolean;
    outputData: Record<T, IDynamicFormInput>;
  };
};
