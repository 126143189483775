import { gql } from "@apollo/client";

export interface ICreateRemark {
  id: string;

  title: string;
  message: string;
  main: boolean;

  seafarerId?: string | null;
  addressId?: string | null;
  communicationId?: string | null;
  agencyId?: string | null;

  created: string;
}

export interface ICreateRemarkData {
  createRemark: ICreateRemark;
}

export interface ICreateRemarkVarsIdList {
  seafarerId?: number | undefined;
  addressId?: number | undefined;
  communicationId?: number | undefined;
  agencyId?: number | undefined;
}

export interface ICreateRemarkVars {
  data: {
    title: string;
    message: string;
    main: boolean | undefined;
  } & ICreateRemarkVarsIdList;
}

export const CREATE_REMARK = gql`
  mutation createRemark($data: RemarkCreateInput!) {
    createRemark(data: $data) {
      id
      title
      main
      message
      seafarerId
      addressId
      communicationId
      agencyId
      created
    }
  }
`;
