import { gql } from "@apollo/client";

export interface IDeleteRankVars {
  id: number;
}

export interface IDeleteRank {
  id: string;
  name: string;
}

export interface IDeleteRankData {
  deleteRank: IDeleteRank;
}

export const DELETE_RANK = gql`
  mutation deleteRank($id: ID!) {
    deleteRank(id: $id) {
      id
      name
    }
  }
`;
