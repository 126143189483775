import { gql } from "@apollo/client";

export interface IUpdateRankGroupVars {
  id: number;
  data: {
    name: string | undefined;
  };
}

export interface IUpdateRankGroup {
  id: string;
  name: string;
}

export interface IUpdateRankGroupData {
  updateRankGroup: IUpdateRankGroup;
}

export const UPDATE_RANK_GROUP = gql`
  mutation updateRankGroup($id: ID!, $data: RankGroupUpdateInput!) {
    updateRankGroup(id: $id, data: $data) {
      id
      name
    }
  }
`;
