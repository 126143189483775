import { FC } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  Typography,
  DialogProps,
} from "@mui/material";

type TDialogProps = {
  title: string;
  buttonTitle?: string;
  description: string;
  mutationCall: () => void;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
};

export type TDeleteDialogProps = DialogProps & TDialogProps;

export const DeleteDialog: FC<TDeleteDialogProps> = (props) => {
  const { onClose, open, title, description, mutationCall, buttonTitle } =
    props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={mutationCall}>
          {buttonTitle ? buttonTitle : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
