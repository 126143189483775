import { createTypedDynamicFormInputs } from "../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  type: {
    values: { value: "" },
    inputProps: {
      label: "Type",
      required: true,
    },
  },
  value: {
    values: { value: "" },
    inputProps: {
      label: "Value",
      required: true,
    },
  },

  main: {
    values: { value: "" },
    isCheckbox: true,
    inputProps: {
      label: "Main",
    },
  },
});
