import { createTypedDynamicFormInputs } from "../../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  //Seafarers
  rankIds: {
    values: { value: "" },
    inputProps: {
      label: "Rank",
    },
  },
  // status: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "Status",
  //   },
  // },
  nationalityIds: {
    values: { value: "" },
    inputProps: {
      label: "Nationality",
    },
  },
  // availableFrom: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "Available from",
  //   },
  // },
  // certificate: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "Certificate",
  //   },
  // },
  agentIds: {
    values: { value: "" },
    inputProps: {
      label: "Agent",
    },
  },

  //Vessel
  // vesselType: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "Vessel type",
  //   },
  // },
  // mainEngine: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "Main engine",
  //   },
  // },
  // grtMin: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "GRT min",
  //     type: "number",
  //   },
  // },
  // grtMax: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "GRT max",
  //     type: "number",
  //   },
  // },
  // teuMin: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "TEU min",
  //     type: "number",
  //   },
  // },
  // teuMax: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "TEU max",
  //     type: "number",
  //   },
  // },
  // kwMin: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "KW min",
  //     type: "number",
  //   },
  // },
  // kwMax: {
  //   values: { value: "" },
  //   inputProps: {
  //     label: "KW max",
  //     type: "number",
  //   },
  // },

  //Save filter
  filterName: {
    values: { value: "" },
    inputProps: {
      required: true,
      label: "Filter name",
    },
  },
  forAgents: {
    values: { value: "" },
    helperText:
      "Add filter to specific admins. Editing filter on your end will edit it for others too. Leave empty for organisation level filter. Set to yourself for a private filter.",
    inputProps: {
      label: "Assign to",
    },
  },
});
