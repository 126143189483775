import { gql } from "@apollo/client";

export interface IDeleteRemark {
  id: string;
  title: string;
}

export interface IDeleteRemarkData {
  deleteRemark: IDeleteRemark;
}

export interface IDeleteRemarkVars {
  id: number;
}

export const DELETE_REMARK = gql`
  mutation deleteRemark($id: ID!) {
    deleteRemark(id: $id) {
      id
      title
    }
  }
`;
