import { gql } from "@apollo/client";

export interface ICountry {
  id: string;
  name: string;
}

export interface ICountriesData {
  allCountries?: ICountry[];
}

export const ALL_COUNTRIES = gql`
  query allCountries {
    allCountries {
      id
      name
    }
  }
`;
