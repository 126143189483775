import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { IPersonInvitation } from "../../../../../../../apollo/queries";
import { useStyles } from "./PersonInvitationItem.styles";
import { format } from "date-fns";

interface IProps {
  data: IPersonInvitation;
  handleToggleActionMenu: any;
}

export const PersonInvitationItem: FC<IProps> = (props) => {
  const { data, handleToggleActionMenu } = props;
  const { classes } = useStyles();

  const status = data.revoked
    ? "Revoked"
    : data.expired
    ? "Accepted"
    : "Pending";

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>{data.nickname}</TableCell>
      <TableCell>{data.email}</TableCell>
      <TableCell>{data.agency?.name || "N/A"}</TableCell>
      <TableCell>{data.role.name}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        {format(new Date(data.created), "dd.MM.yyyy. HH:mm")}
      </TableCell>

      <TableCell>
        <IconButton onClick={handleToggleActionMenu}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
