import { lighten } from "@mui/material";
import { makeStyles } from "../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  // searchBar: {
  //   marginRight: "16px",
  //   "& label.Mui-focused": {
  //     color: theme.palette.primary.contrastText,
  //   },
  // },

  iconButton: {
    // marginLeft: 4,
    // "& svg": {
    //   transition: "0.25s all ease-in-out",
    //   color: theme.palette.grey[300],
    // },
    // "&:hover svg": {
    //   transition: "0.25s all ease-in-out",
    //   color: theme.palette.primary.contrastText,
    // },
    // "&:hover": {
    //   transition: "0.25s border-color ease-in-out",
    // },
  },

  root: {
    width: 384,
    backgroundColor: theme.palette.primary.light,

    // color: theme.palette.primary.contrastText,
    // "&:hover": {
    //   backgroundColor: lighten(theme.palette.primary.light, 0.1),
    //   // Reset on touch devices, it doesn't add specificity
    //   "@media (hover: none)": {
    //     backgroundColor: lighten(theme.palette.primary.light, 0.1),
    //   },
    // },
  },
  focused: {
    borderBottomColor: "red",
  },

  input: {
    color: theme.palette.primary.contrastText,
  },

  inputRoot: {
    color: theme.palette.grey[300],
  },
  inputFocused: {
    "&:focused": {
      color: theme.palette.primary.contrastText,
    },
  },
  avatarProfile: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
}));
