import { makeStyles } from "../../../../../../styles/theme";

export const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    marginTop: 120,
    width: 256,
    height: "calc(100vh - 120px)",
  },
  popper: {
    zIndex: 1201,
  },
}));
