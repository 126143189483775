import { gql } from "@apollo/client";

export interface ILogoutData {
  logout: boolean;
}

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
