import { useQuery } from "@apollo/client";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Button,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { ALL_REMARKS, IRemarksData, IRemarksVars } from "../../apollo/queries";
import { useGlobalStyles } from "../../styles";
import {
  ContextProvider,
  DEFAULT_ACTION_MENU_CONTEXT,
  joinClassNames,
} from "../../utils";
import { DataHandlerComponent, PopperMenu } from "../";
import { RemarkItem } from "./components";
import { useStyles } from "./RemarkList.styles";
import { ICreateRemarkVarsIdList } from "../../apollo/mutations";

interface IProps {
  queryVariables: IRemarksVars["filter"];
  idList: ICreateRemarkVarsIdList;
  className?: string;
  compact?: boolean;
  skipQuery?: boolean;
}

export const RemarkList: FC<IProps> = (props) => {
  const { queryVariables, compact, idList, skipQuery } = props;
  const { setRemarkDialogContext } = useContext(ContextProvider);
  const { classes } = useStyles();
  const { classes: globalClasses } = useGlobalStyles();

  const [actionMenu, setActionMenu] = useState(DEFAULT_ACTION_MENU_CONTEXT);

  const { loading, error, data } = useQuery<IRemarksData, IRemarksVars>(
    ALL_REMARKS,
    { skip: skipQuery, variables: { filter: queryVariables } }
  );

  const handleToggleActionMenu =
    (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      console.log(actionMenu);
      setActionMenu((prev) => {
        return {
          open: prev.id !== id || !prev.open,
          anchorEl: event.currentTarget,
          id: id,
        };
      });
    };

  const handleOpenCreateDialog = () => {
    setRemarkDialogContext({
      id: undefined,
      idList: idList,
      open: true,
      type: "upsert",
    });
  };

  const handleOpenEditDialog = () => {
    setRemarkDialogContext({
      id: actionMenu.id,
      idList: idList,
      open: true,
      type: "upsert",
    });
    setActionMenu((prev) => ({ ...prev, open: false, anchorEl: null }));
  };

  const handleOpenDeleteDialog = () => {
    setRemarkDialogContext({
      id: actionMenu.id,
      idList: {},
      open: true,
      type: "delete",
    });
    setActionMenu((prev) => ({ ...prev, open: false, anchorEl: null }));
  };

  return (
    <div
      className={joinClassNames([
        compact ? classes.compact : classes.root,
        globalClasses.fullWidth,
        globalClasses.scrollablePaper,
      ])}
    >
      <div
        className={joinClassNames([
          globalClasses.padding,
          globalClasses.justifyBetween,
        ])}
      >
        <Typography variant="h6">Remarks</Typography>
        <Button
          onClick={handleOpenCreateDialog}
          startIcon={<AddIcon />}
          variant="outlined"
        >
          Add
        </Button>
      </div>
      <DataHandlerComponent
        loading={loading}
        error={Boolean(error)}
        hasData={Boolean(data?.allRemarks.length)}
      >
        <List className={globalClasses.scrollableDiv}>
          {data?.allRemarks.length
            ? data.allRemarks.map((item) => {
                // const handleOpenActionMenu = () => {
                //   console.log(item.id);
                //   handleToggleActionMenu(item.id);
                // };
                return (
                  <RemarkItem
                    key={item.id}
                    compact={compact}
                    item={item}
                    handleToggleActionMenu={handleToggleActionMenu(item.id)}
                  />
                );
              })
            : null}
        </List>
      </DataHandlerComponent>

      <PopperMenu actionMenu={actionMenu} setActionMenu={setActionMenu}>
        <MenuItem onClick={handleOpenEditDialog}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteDialog}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </PopperMenu>

      {/* <TextField disabled /> */}
    </div>
  );
};
