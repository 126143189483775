import { gql } from "@apollo/client";

export interface IUpdateRemark {
  id: string;

  title: string;
  message: string;
  main: boolean;
}

export interface IUpdateRemarkData {
  updateRemark: IUpdateRemark;
}

export interface IUpdateRemarkVars {
  id: number;
  data: {
    title: string | undefined;
    message: string | undefined;
    main: boolean | undefined;
  };
}

export const UPDATE_REMARK = gql`
  mutation updateRemark($id: ID!, $data: RemarkUpdateInput!) {
    updateRemark(id: $id, data: $data) {
      id
      title
      message
      main
    }
  }
`;
