import { createTypedDynamicFormInputs } from "../../../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  name: {
    values: { value: "" },
    inputProps: {
      label: "Name",
      required: true,
    },
  },
});
