import { gql } from "@apollo/client";

export interface ICreateFilterVars {
  data: {
    name: string;
    nationalityIds: string[] | undefined;
    agentIds: string[] | undefined;
    rankIds: string[] | undefined;
    forAgents: string[] | undefined;
  };
}

export interface ICreateFilter {
  id: string;

  name: string;
  nationalityIds: string[];
  agentIds: string[];
  rankIds: string[];
  forAgents: { id: string; nickname: string }[];
}

export interface ICreateFilterData {
  createFilter: ICreateFilter;
}

export const CREATE_FILTER = gql`
  mutation createFilter($data: FilterCreateInput!) {
    createFilter(data: $data) {
      id
      name
      nationalityIds
      agentIds
      rankIds

      forAgents {
        id
        nickname
      }
    }
  }
`;
