import { gql } from "@apollo/client";

export interface IRole {
  id: string;
  name: string;
}

export interface IRolesData {
  allRoles?: IRole[];
}

export const ALL_ROLES = gql`
  query allRoles {
    allRoles {
      id
      name
    }
  }
`;
