import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useStyles } from "./SeafarersNavbar.styles";
import {
  Bookmarks as BookmarksIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { SearchBar } from "../../..";
import { FilterDialog, FilterDrawer, TGetSavedFilter } from "./components";
import { Outlet } from "react-router";
import { IFilter } from "../../../../apollo/queries";
import { IDialogContext, IDynamicFormInput } from "../../../../utils";

export type TActiveFilter = Record<
  // | "status"
  "rankIds" | "nationalityIds" | "agentIds",
  // | "availableFrom"
  // | "certificate"
  // | "vesselType"
  // | "mainEngine"
  // | "grtMin"
  // | "grtMax"
  // | "teuMin"
  // | "teuMax"
  // | "kwMin"
  // | "kwMax",
  IDynamicFormInput
>;

export interface IOutletContext {
  activeSearch: string;
  setActiveSearch: Dispatch<SetStateAction<string>>;
  activeFilter: TActiveFilter | undefined;
  setActiveFilter: Dispatch<SetStateAction<TActiveFilter | undefined>>;
}

export const SeafarersNavbar: FC = () => {
  // const { enqueueSnackbar } = useSnackbar();
  const { classes, cx } = useStyles();
  const filterRef = useRef<TGetSavedFilter | null>(null);

  const [activeSearch, setActiveSearch] = useState("");
  const [activeFilter, setActiveFilter] = useState<any>(undefined);
  const [filtersDialog, setFilterDialog] = useState<IDialogContext>({
    id: undefined,
    open: false,
  });
  const [bookmarksSidePanel, setBookmarksSidePanel] = useState(false);

  const handleSetActiveSearch = (search: string) => {
    setActiveSearch(search);
  };

  const handleOpenFilterDialog = (_: any, id?: string) => {
    // console.log("ID:", id);
    setFilterDialog({ id: id, open: true });
  };

  const handleCloseFilterDialog = () => {
    setFilterDialog((prev) => ({ ...prev, open: false }));
  };

  const handleCleanupDialogState = () => {
    setFilterDialog((prev) => ({ ...prev, id: undefined }));
  };

  const handleOpenBookmarksSidePanel = () => {
    setBookmarksSidePanel(!bookmarksSidePanel);
  };

  const handleCloseBookmarksSidePanel = () => {
    setBookmarksSidePanel(false);
  };

  const handleSetSavedFilter = (filter: IFilter) => {
    if (filterRef.current) {
      filterRef.current.getSavedFilter(filter);
      handleCloseBookmarksSidePanel();
    }
  };

  return (
    <>
      <div className={cx(classes.header)}>
        <SearchBar
          activeSearch={activeSearch}
          onSearch={handleSetActiveSearch}
          className={cx(classes.searchBar)}
        />
        <Tooltip title="Filter">
          <IconButton
            onClick={handleOpenFilterDialog}
            className={
              activeFilter
                ? cx(classes.iconButton)
                : cx(classes.iconButtonInactive)
            }
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Saved filters">
          <IconButton
            onClick={handleOpenBookmarksSidePanel}
            className={classes.iconButtonInactive}
          >
            <BookmarksIcon />
          </IconButton>
        </Tooltip>
        <FilterDrawer
          handleSelectFilter={handleSetSavedFilter}
          onClose={handleCloseBookmarksSidePanel}
          handleOpenFilterDialog={handleOpenFilterDialog}
          open={bookmarksSidePanel}
        />
        <FilterDialog
          ref={filterRef}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          open={filtersDialog.open}
          maxWidth="md"
          fullWidth
          onClose={handleCloseFilterDialog}
          handleClearDialogState={handleCleanupDialogState}
          filterId={filtersDialog.id}
        />
      </div>
      <Outlet
        context={{
          activeSearch,
          setActiveSearch,
          activeFilter,
        }}
      />
    </>
  );
};
