import { gql } from "@apollo/client";
import { ICountry } from "../../../queries";

export interface ICreateAddressVars {
  data: {
    street: string;
    street2: string | undefined;
    postalCode: string;
    city: string;
    region: string | undefined;
    countryId: number;
    nearestAirport: string | undefined;
    main: boolean;

    seafarerId: number;
  };
}

export interface ICreateAddress {
  id: string;

  street: string;
  street2: string | undefined;
  postalCode: string;
  city: string;
  region: string | undefined;
  country: ICountry;
  nearestAirport: string | undefined;
  main: boolean;
  remark: string;
}

export interface ICreateAddressData {
  createAddress: ICreateAddress;
}

export const CREATE_ADDRESS = gql`
  mutation createAddress($data: AddressCreateInput!) {
    createAddress(data: $data) {
      id

      street
      street2
      postalCode
      city
      region
      country {
        id
        name
      }
      nearestAirport
      main
    }
  }
`;
