import { FC, SyntheticEvent, useEffect } from "react";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Dialog,
  DialogProps,
  TextField,
  Autocomplete,
  createFilterOptions,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import { useStyles } from "./UpsertAgencyDialog.styles";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { initialInputData } from "./UpsertAgencyDialog.inputs";
import {
  getFormValuesFromFetchedData,
  TValueToFormOptions,
  useForm,
  validateForm,
} from "../../../../../../../utils";
import {
  CREATE_AGENCY,
  ICreateAgencyData,
  ICreateAgencyVars,
  IUpdateAgencyData,
  IUpdateAgencyVars,
  UPDATE_AGENCY,
} from "../../../../../../../apollo/mutations";
import {
  DataHandlerComponent,
  LoadingBackdrop,
} from "../../../../../../../components";

import {
  ALL_AGENCIES,
  ALL_COUNTRIES,
  IAgenciesData,
  IAgency,
  IAgencyData,
  IAgencyVars,
  ICountriesData,
  ICountry,
  ONE_AGENCY,
} from "../../../../../../../apollo/queries";

type TDialogProps = {
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  agencyId: string | undefined;
};

type TProps = DialogProps & TDialogProps;

export const UpsertAgencyDialog: FC<TProps> = (props) => {
  const { open, onClose, agencyId, ...rest } = props;
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    inputFields,
    setInputFields,
    resetFields,
    setInputField,
    handleDataToVar,
  } = useForm<keyof typeof initialInputData>(initialInputData);

  const [
    callAllCountriesQuery,
    { loading: loadingCountries, error: errorCountries, data: dataCountries },
  ] = useLazyQuery<ICountriesData, null>(ALL_COUNTRIES);

  const [callOneAgencyQuery, { loading, error, data }] = useLazyQuery<
    IAgencyData,
    IAgencyVars
  >(ONE_AGENCY);

  useEffect(() => {
    if (open) {
      callAllCountriesQuery();
      if (agencyId) {
        callOneAgencyQuery({
          variables: {
            id: +agencyId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyId, open]);

  const [createAgencyMutation, { loading: loadingCreateAgency }] = useMutation<
    ICreateAgencyData,
    ICreateAgencyVars
  >(CREATE_AGENCY, {
    onCompleted: (res) => {
      enqueueSnackbar(`Added new agency: ${res.createAgency.name}`, {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log({ error });
    },

    update: (cache, { data }) => {
      const existingData: IAgenciesData | null = cache.readQuery({
        query: ALL_AGENCIES,
      });
      if (data?.createAgency) {
        const newItem: IAgency = {
          ...data.createAgency,
        };
        cache.writeQuery({
          query: ALL_AGENCIES,
          data: {
            allAgencies: existingData?.allAgencies
              ? [newItem, ...existingData.allAgencies]
              : [newItem],
          },
        });
      }
    },
  });

  const [updateAgencyMutation, { loading: loadingUpdateAgency }] = useMutation<
    IUpdateAgencyData,
    IUpdateAgencyVars
  >(UPDATE_AGENCY, {
    onCompleted: (res) => {
      enqueueSnackbar(`Updated agency: ${res.updateAgency.name}`, {
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  const handleUpsertAgency = () => {
    const fields = Object.keys(initialInputData);
    const result = validateForm(fields, inputFields);
    if (result.formValid) {
      if (!agencyId) {
        createAgencyMutation({
          variables: {
            data: {
              name: inputFields.name.inputProps.value,
              street: inputFields.street.inputProps.value,
              zipCode: inputFields.zipCode.inputProps.value,
              city: inputFields.city.inputProps.value,
              countryId: +inputFields.country.inputProps.value,
            },
          },
        });
      } else if (agencyId) {
        updateAgencyMutation({
          variables: {
            id: +agencyId,
            data: {
              name: handleDataToVar("name", "string", false),
              street: handleDataToVar("street", "string", false),
              zipCode: handleDataToVar("zipCode", "string", false),
              city: handleDataToVar("city", "string", false),
              countryId: handleDataToVar("country", "number", false),
            },
          },
        });
      } else {
        enqueueSnackbar("No agency id!", {
          variant: "error",
        });
      }
    } else {
      // console.log("Form is invalid: ", result);
      enqueueSnackbar("Not all required fields are set!", {
        variant: "error",
      });
      setInputFields(result.outputData);
    }
  };

  useEffect(() => {
    if (agencyId && data?.oneAgency) {
      const valueToFormOptions: TValueToFormOptions = [
        {
          fromDataProperty: "name",
          toFormProperty: "name",
        },
        {
          fromDataProperty: "street",
          toFormProperty: "street",
        },
        {
          fromDataProperty: "zipCode",
          toFormProperty: "zipCode",
        },
        {
          fromDataProperty: "city",
          toFormProperty: "city",
        },
        {
          fromDataProperty: "country.id",
          toFormProperty: "country",
        },
      ];
      const getUpdatedInputs = getFormValuesFromFetchedData<
        keyof typeof initialInputData
      >(data.oneAgency, valueToFormOptions, inputFields);

      setInputFields(getUpdatedInputs);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, agencyId]);

  const filterOptionsCountries = createFilterOptions<ICountry>({
    limit: 50,
  });

  const handleDialogCleanup = {
    onExited: () => {
      resetFields();
    },
  };
  const handleSetDateAndAutocompleteValue = (
    inputField: string,
    value: any
  ) => {
    setInputField(inputField as any, value ? value : "");
  };

  const onChangeCountry = (
    event: SyntheticEvent<Element, Event>,
    value: ICountry | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<ICountry> | undefined
  ) => {
    handleSetDateAndAutocompleteValue("country", value?.id || "");
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      TransitionProps={handleDialogCleanup}
      fullWidth
      {...rest}
    >
      <DialogTitle>{agencyId ? "Edit agency" : "Add new agency"}</DialogTitle>

      <DialogContent className={classes.content}>
        <DataHandlerComponent
          loading={agencyId ? loading || loadingCountries : loadingCountries}
          error={
            agencyId
              ? Boolean(error || errorCountries)
              : Boolean(errorCountries)
          }
          hasData={Boolean(
            agencyId
              ? data?.oneAgency && dataCountries?.allCountries?.length
              : dataCountries?.allCountries?.length
          )}
          skeletonNum={2}
        >
          <TextField
            {...inputFields.name.inputProps}
            className={classes.bigInput}
            autoFocus
            margin="normal"
          />

          <TextField
            {...inputFields.street.inputProps}
            className={classes.bigInput}
            margin="normal"
          />
          <TextField
            {...inputFields.zipCode.inputProps}
            className={classes.bigInput}
            margin="normal"
          />
          <TextField
            {...inputFields.city.inputProps}
            className={classes.bigInput}
            margin="normal"
          />
          <Autocomplete
            options={
              dataCountries?.allCountries?.length
                ? dataCountries.allCountries
                : []
            }
            loading={loadingCountries}
            autoComplete
            filterOptions={filterOptionsCountries}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={onChangeCountry}
            value={
              dataCountries?.allCountries?.find(
                (x) => x.id === inputFields.country.inputProps.value
              ) || null
            }
            className={classes.bigInput}
            renderInput={(params) => (
              <TextField
                {...params}
                label={inputFields.country.inputProps.label}
                required={inputFields.country.inputProps.required}
                margin="normal"
              />
            )}
          />
        </DataHandlerComponent>
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpsertAgency}
        >
          {agencyId ? "Update" : "Add"}
        </Button>
      </DialogActions>
      <LoadingBackdrop loading={loadingCreateAgency || loadingUpdateAgency} />
    </Dialog>
  );
};
