import { createTypedDynamicFormInputs } from "../../../../../utils";

export const initialInputData = createTypedDynamicFormInputs({
  //General
  name: {
    values: { value: "" },
    inputProps: {
      label: "Name",
      required: true,
    },
  },
  surname: {
    values: { value: "" },
    inputProps: {
      label: "Surname",
      required: true,
    },
  },
  rank: {
    values: { value: "" },
    inputProps: {
      label: "Rank",
      // required: true,
    },
  },
  birthday: {
    values: { value: "" },
    inputProps: {
      label: "Birthday",
      required: true,
    },
  },
  placeOfBirth: {
    values: { value: "" },
    inputProps: {
      label: "Place of birth",
    },
  },

  englishProficiency: {
    values: { value: "" },
    inputProps: {
      label: "English proficiency",
    },
  },
  sex: {
    values: { value: "" },
    inputProps: {
      label: "Sex",
    },
  },
  maritalStatus: {
    values: { value: "" },
    inputProps: {
      label: "Marital status",
    },
  },
  nationality: {
    values: { value: "" },
    inputProps: {
      label: "Nationality",
      required: true,
    },
  },
  //Physical
  eyeColor: {
    values: { value: "" },
    inputProps: {
      label: "Eye color",
    },
  },
  hairColor: {
    values: { value: "" },
    inputProps: {
      label: "Hair color",
    },
  },
  overallSize: {
    values: { value: "" },
    inputProps: {
      label: "Overall size",
    },
  },
  height: {
    values: { value: "" },
    inputProps: {
      label: "Height",
      type: "number",
    },
  },
  weight: {
    values: { value: "" },
    inputProps: {
      label: "Weight",
      type: "number",
    },
  },
  shoeSize: {
    values: { value: "" },
    inputProps: {
      label: "Shoe size",
      type: "number",
    },
  },
  //Education
  nameOfInstitution: {
    values: { value: "" },
    inputProps: {
      label: "Name of institution",
    },
  },
  cityOfCollege: {
    values: { value: "" },
    inputProps: {
      label: "City of college",
    },
  },
  countryOfCollege: {
    values: { value: "" },
    inputProps: {
      label: "Country of college",
    },
  },
  educationSpeciality: {
    values: { value: "" },
    inputProps: {
      label: "Education speciality",
    },
  },
  educationStartDate: {
    values: { value: "" },
    inputProps: {
      label: "Education start date",
    },
  },
  graduationDate: {
    values: { value: "" },
    inputProps: {
      label: "Graduation date",
    },
  },
  //Other
  availableFrom: {
    values: { value: "" },
    inputProps: {
      label: "Available from",
    },
  },
  status: {
    values: { value: "" },
    inputProps: {
      label: "Status",
    },
  },
  agency: {
    values: { value: "" },
    inputProps: {
      label: "Agency",
    },
  },
  agent: {
    values: { value: "" },
    inputProps: {
      label: "Agent",
    },
  },
  remarks: {
    values: { value: "" },
    inputProps: {
      label: "Remarks",
    },
  },
});
